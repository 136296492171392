import React from "react";
import "../pricing/Pricing.css";
import Header from "../../Components/Header/Header";
function Pricing() {
  return (
    <div>
      <Header />
      <section id="pricing" className="pricing-content section-padding mt-3">
        <div className="container">
          <div className="section-title text-center">
            <h2>Pricing Plans</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="row text-center">
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.1s"
              data-wow-offset="0"
              //   style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Starter</h2>
                    <h1>$19</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    {/* <li>
                      <b>15</b> website
                    </li> */}
                    <li>
                      <b>10GB</b> Disk Space
                    </li>
                    {/* <li>
                      <b>50</b> Email
                    </li> */}
                    {/* <li>
                      <b>50GB</b> Bandwidth
                    </li> */}
                    {/* <li>
                      <b>10</b> Subdomains
                    </li> */}
                    <li>
                      <b>Unlimited</b> Support
                    </li>
                  </ul>
                  <div className="pricing-price"></div>
                  <a href="#" className="price_btn">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
              //   style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeInUp;"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Personal</h2>
                    <h1 className="price">$29</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    {/* <li>
                      <b>15</b> website
                    </li> */}
                    <li>
                      <b>20GB</b> Disk Space
                    </li>
                    {/* <li>
                      <b>50</b> Email
                    </li> */}
                    {/* <li>
                      <b>50GB</b> Bandwidth
                    </li> */}
                    {/* <li>
                      <b>10</b> Subdomains
                    </li> */}
                    <li>
                      <b>Unlimited</b> Support
                    </li>
                  </ul>
                  <div className="pricing-price"></div>
                  <a href="#" className="price_btn">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
              //   style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Ultimate</h2>
                    <h1 className="price">$49</h1>
                    <span>/Monthly</span>
                  </div>
                  <ul>
                    {/* <li>
                      <b>15</b> website
                    </li> */}
                    <li>
                      <b>30GB</b> Disk Space
                    </li>
                    {/* <li>
                      <b>50</b> Email
                    </li> */}
                    {/* <li>
                      <b>50GB</b> Bandwidth
                    </li> */}
                    {/* <li>
                      <b>10</b> Subdomains
                    </li> */}
                    <li>
                      <b>Unlimited</b> Support
                    </li>
                  </ul>
                  <div className="pricing-price"></div>
                  <a href="#" className="price_btn">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
