import React, { useEffect, useState } from "react";
import "./CompProfile.css";
import Header from "../../Components/Header/Header";
import DummyProfile from "../../utils/images/Comany-img.jpg";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useNavigate } from "react-router-dom";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { Divider } from "@mui/material";
import { FaCamera } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { MdEdit } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { GlobalConstants } from "../../utils/globalconstant";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBucketData } from "../../redux/GetBucketdataSlice";
import { ToastContainer } from "react-toastify";
import { getAllUserData } from "../../redux/UserListSlice";
import { CgCloseO } from "react-icons/cg";
import { partial } from "filesize";
import ProgressBar from "@ramonak/react-progress-bar";
import { getCompanyData } from "../../redux/CompanyProfileSlice";
import {
  getAwsPublicPrivateData,
  // getFileCountAWS,
  getFileCountIPFS,
  // getFolderCountAWS,
  getFolderCountIPFS,
  // getStorageAWS,
  getStorageIPFS,
} from "../../redux/SelectBucketSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const CompProfile = () => {
  //variable
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const atomS3Data = useSelector((state) => state.getBucketData.AtomS3Data);
  const EmpCount = useSelector((state) => state.comapanyUserList.userCount);
  const compData = useSelector((state) => state.comapanypofileData);
  const size = partial({ base: 2, standard: "jedec" });
  const UserID = sessionStorage.getItem("userID");
  console.log(compData);
  const existingBucketData = atomS3Data.filter(
    (item) => item.users_existing_bucket === true
  );
  const createdBucketData = atomS3Data.filter(
    (item) => item.users_existing_bucket === false
  );
  const [selectedStorage, setSelectedStorage] = useState("");
  const [Comp_Bkt_Name, set_Comp_Bkt_Name] = useState("");
  const [Comp_Bkt_Region, set_Comp_Bkt_Region] = useState("us-east-2");
  const [Comp_Bkt_AccessKeyId, set_Comp_Bkt_AccessKeyId] = useState("");
  const [Comp_Bkt_SecretAccessKey, set_Comp_Bkt_SecretAccessKey] = useState("");
  const [New_Bkt_Name, set_New_Bkt_Name] = useState("");
  const [New_Bkt_Region, set_New_Bkt_Region] = useState("");
  const [credModal, setCredModal] = useState(false);
  const [newBktModal, setNewBktModal] = useState(false);
  const [checkAWS, setCheckAWS] = useState(false);
  const [allBucket, setAllBucket] = useState([]);
  const [checkIPFS, setCheckIPFS] = useState(false);
  const [awsBucketId, setAWSBucketId] = useState("");
  const [awsBucketName, setAwsBucketName] = useState("");
  const [selectRange, SetSelectRange] = useState("10 GB");
  const [selectRangeIPFS, SetSelectRangeIPFS] = useState("10 GB");
  const [effect, setEffect] = useState(false);
  const [errorPopup, setErrorPopup] = useState("");
  const [storageAWSsize, setStorageAWSsize] = useState("");
  const [totalStorageAWS, setTotalStorageAWS] = useState("");
  const [storageIPFSsize, setStorageIPFSsize] = useState("");
  const [storageIPFStotalsize, setStorageIPFSTotalSize] = useState("");
  const handleOpenCredModal = () => setCredModal(true);

  const handleCloseCredModal = () => {
    setCredModal(false);
    set_Comp_Bkt_AccessKeyId("");
    set_Comp_Bkt_SecretAccessKey("");
    set_Comp_Bkt_Name("");
    setErrCreatebucket("");
    setOwnBucketNameErr("");
    setSecretKeyErr("");
    setAccessKeyErr("");
    setPublicPrivate("private");
  };
  const handleOpennNewBktModal = () => setNewBktModal(true);
  const handleCloseNewBktModal = () => {
    setNewBktModal(false);
    set_New_Bkt_Name("");
    setErrCreatebucket("");
    setBucketNameErr("");
  };
  const [openProfileImagePopup, setOpenProfileImagePopup] = useState(false);
  const handleOpenProfileImagePopup = () => setOpenProfileImagePopup(true);
  const handleCloseProfileImagePopup = () => {
    setOpenProfileImagePopup(false);
    setUploadImgerr("");
    setProfileImg();
  };
  const [profileImg, setProfileImg] = useState();
  const [profileImgUrl, setProfileImgUrl] = useState("");
  // const [EmpCount, setEmpCount] = useState("");
  const [errCreatebucket, setErrCreatebucket] = useState("");
  const [isComNameEdit, setIsComNameEdit] = useState(false);
  const [ComNameEdit, setComNameEdit] = useState("");

  var CompanyId = sessionStorage.getItem("companyID");
  const [text_selectrange, setText_selectrange] = useState("Select Range");
  const ComapanyName = sessionStorage.getItem("companyName");
  const [atomS3, setAtomS3] = useState(false);
  const [myPrivateS3, setmyPrivateS3] = useState(false);
  // const [atomS3Data, setAtomS3Data] = useState([]);
  const [myPrivateData, setMyprivateData] = useState([]);
  const [bucketNameErr, setBucketNameErr] = useState("");
  const [bucketNameinstruction, setBucketNameInstruction] = useState("");
  const [secretKeyErr, setSecretKeyErr] = useState("");
  const [accessKeyErr, setAccessKeyErr] = useState("");
  const [ownBucketNameErr, setOwnBucketNameErr] = useState("");
  const [uploadImgErr, setUploadImgerr] = useState("");
  const [publicPrivate, setPublicPrivate] = useState("private");
  const [openPopup, setOpenPopup] = useState(false);
  // language starts here //
  const [text_bucketname, setText_bucketname] = useState("Bucket Name");
  const [text_awsregion, setText_awsregion] = useState("AWS Region");
  const [text_selawsregion, setText_selawsregion] =
    useState("Select AWS Region");
  const [text_addbutton, setText_addbutton] = useState("Add");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_create, setText_create] = useState("Create");
  const [text_createnewnbucket, setText_createnewbucket] = useState(
    "Create New Bucket On Atom s3"
  );
  const [text_err_storage, setText_err_storage] = useState(
    "Please Select Any one Storage"
  );
  const [text_asn, setText_asn] = useState("Atom Storage Network");
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_selstoragelocation, setText_selstoragelocation] = useState(
    "Select Storage Location"
  );
  const [text_atomstoragenetwork1, setText_atomstoragenetwork1] = useState(
    "Atom Storage Network"
  );
  const [text_selectbucket1, setText_selectbucket1] = useState("Select Bucket");
  const [text_selectbucket, setText_selectbucket] = useState(
    "Select Amazon S3 Bucket"
  );
  const [text_save, setText_save] = useState("Save");
  const [text_compprofile, setText_comprofile] = useState("Company Profile");
  const [text_users, setText_users] = useState("Users");
  const [text_user, setText_user] = useState("User");
  const [text_View_users, setText_View_users] = useState("View Users");
  const [text_View_user, setText_View_user] = useState("View User");
  const [text_Select_amazon_acc, setText_select_amazon_acc] = useState(
    "Select Amazon Account"
  );
  const [text_atom_storage, set_text_atom_storage] =
    useState("Atom's S3 Storage");
  const [text_my_private_bkt, setText_my_private_bkt] = useState(
    "My Private S3 Account"
  );
  const [text_done, setText_done] = useState("Done");
  const [text_create_amazon_bkt, setText_create_amaxon_bkt] = useState(
    "Create New Bucket On Atom’s Amazon S3"
  );
  const [text_enter_bkt_name, setText_enter_bkt_name] =
    useState("Enter Bucket Name");
  const [text_enter_cmp_name, setText_enter_cmp_name] =
    useState("Enter Company Name");
  const [text_your_access_key, setText_your_access_key] =
    useState("Your Access Key ID");
  const [text_enter_access_key_id, setText_enter_access_key_id] = useState(
    "Enter Access Key ID"
  );
  const [text_upload_img, setText_upload_img] = useState("Upload Image");
  const [text_err_bucket_name, setText_err_bucket_name] = useState(
    "Please Enter Bucket Name"
  );
  const [text_access_key_err, setText_access_key_err] = useState(
    "Please Enter Access Key Id"
  );
  const [text_secret_key_err, setText_secret_key_err] = useState(
    "Please Enter Secret Access Key Id"
  );
  const [text_upload_img_err, setText_upload_img_err] = useState(
    "Please Select Image First"
  );
  const [text_img_type_err, setText_img_type_err] = useState(
    "Please upload only JPEG or PNG files"
  );
  const [text_addexisitingbucket, setText_addexisitingbucket] = useState(
    "Add Existing Bucket"
  );
  const [text_atomroot, setText_atomroot] = useState("Atom Root");
  const [text_existingbucketons3, setText_existingbucketons3] = useState(
    "Create Existing Bucket On My Amazon S3"
  );
  const [text_bucketownership, setText_bucketownership] =
    useState("Bucket Ownership");
  const [text_private, setText_private] = useState("Private");
  const [text_public, setText_public] = useState("Public");
  const [text_secretaccesskey, setText_secretaccesskey] = useState(
    "Your Secret Access Key"
  );
  const [text_managestorage, setText_managestorage] =
    useState("Manage Storage");
  const [text_atomstoragebucket, setText_atomstoragebucket] = useState(
    "Atom Storage Bucket"
  );
  const [text_addexisitingbucket_2, setText_addexisitingbucket_2] = useState(
    "Add Private S3 Bucket"
  );
  const [text_createnewnbucket_2, setText_createnewbucket_2] = useState(
    "Create New Bucket On Atom S3"
  );
  // language end here //

  ////******* toggle for select amazon s3 and atom storage network */
  const handleStorageToggle = (storageType) => {
    if (selectedStorage === storageType) {
      // If the clicked storage type is already active, switch to the other one
      setSelectedStorage(storageType);
    } else {
      // If a different storage type is clicked, activate it
      setSelectedStorage(storageType);
    }
    // Based on the selected storage type, set AtomS3 and myPrivateS3 accordingly
    if (storageType === "AWS") {
      setAtomS3(true);
      setmyPrivateS3(true);
    } else if (storageType === "IPFS") {
      setAtomS3(false);
      setmyPrivateS3(false);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 680,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  ///****** cover image validation */
  const coverimguplpoadValidation = (e) => {
    setUploadImgerr("");
    const file = e.target.files[0];
    if (file !== "") {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setProfileImg(file);
        // You can perform further actions with the selected file here
      } else {
        setProfileImg("");
        setUploadImgerr(<>*{text_img_type_err}!</>);
      }
    }
  };
  ///****** Profile upload */
  const uploadProfile = (e) => {
    if (profileImg === null || profileImg === undefined || profileImg === "") {
      setUploadImgerr(<>*{text_upload_img_err}!</>);
    } else {
      let headerConfig = {
        headers: {
          "Content-Type": "form-data",
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var formData = new FormData();
      formData.append("att_file", profileImg);
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/upload-profile-image";

      axios
        .post(API_URL, formData, headerConfig)
        .then((res) => {
          console.log("get data ipfs res***", res);
          // successToast(res?.data?.message);
          handleUpdatecoverurl(res?.data?.data);
          handleCloseProfileImagePopup();
        })
        .catch((err) => {
          console.log("get data ipfs err***", err);
          setUploadImgerr(<>*{err.message}!</>);

          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
          // No change to profileImg state here
        })
        .then(function () {});
    }
  };
  ///****** update profile image  */
  const handleUpdatecoverurl = (url) => {
    var CompanyId = sessionStorage.getItem("companyID");
    var UserID = sessionStorage.getItem("userID");
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/add-profile-image";

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var data = {
      location: url.Location,
      bucketName: url.Bucket,
      key: url.Key,
      companyId: CompanyId,
      userId: UserID,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Url Update", response);
        if (response?.status === 200) {
          // getProfielInfo();
          dispatch(getCompanyData());
          successToast(response?.data?.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          errorToast(error?.response?.data?.message);
        }
      })
      .then(function () {});
  };
  ///****** get Profile image */
  const getProfielInfo = () => {
    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + `/company/get-profile-image?companyId=${UserID}`;

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setProfileImgUrl(data?.profileImage);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          });
        }
        errorToast(error?.response?.data?.message);
      })
      .then(function () {});
  };
  useEffect(() => {
    // getProfielInfo();
    if (EmpCount === 0) {
      // getEmployeeData(EmpCount-1);
      dispatch(getAllUserData());
    }

    // getBucketcreatedData();
    // getBucketexistingData();
    handleStorageToggle("AWS");
  }, [EmpCount]);

  useEffect(() => {
    if (compData?.length === 0) {
      dispatch(getCompanyData());
    }
  }, [compData?.length]);

  useEffect(() => {
    if (atomS3Data?.length === 0) {
      dispatch(getBucketData());
    }
  }, [atomS3Data?.length]);
  const handleUpdateCompany = () => {
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/edit-company-data";

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var data = {
      userId: UserID,
      firstName: sessionStorage.getItem("fName"),
      lastName: sessionStorage.getItem("lName"),
      country: "India",
      companyName: ComNameEdit,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Company Update", response);
        if (response?.status === 200) {
          setIsComNameEdit(false);
          successToast(response?.data?.message);
          sessionStorage.setItem(
            "companyName",
            response?.data?.data?.companyName
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error.data.data.message);
        if (error?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          errorToast(error?.response?.data?.message);
        }
      })
      .then(function () {});
  };
  ///****** get company data */
  // const getCompanyData = () => {
  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE +
  //     `/company/company-data?c_id=${CompanyId}&u_id=${UserID}`;

  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then(function (response) {
  //       var data = response?.data?.data;
  //       console.log("profile data", data);
  //       setProfileImgUrl(data?.profileImage);
  //       setComNameEdit(data?.companyName);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // errorToast(err);
  //       if (error.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         setTimeout(() => {
  //           navigate("/");
  //         });
  //       }
  //       errorToast(error.response.data.message);
  //     })
  //     .then(function () {});
  // };
  ///****** bucket craete for atom s3 storage */
  const NewbucketHandle = () => {
    if (New_Bkt_Name === "") {
      setBucketNameErr(<>*{text_err_bucket_name}!</>);
    } else {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/create-bucket";

      var data = {
        bucketName: New_Bkt_Name,
        companyId: CompanyId,
        userId: UserID,
        isOrg: true,
        bucketACLOption: publicPrivate,
      };

      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("bucket created***", res);
          if (res?.status === 201) {
            setTimeout(() => {
              handleCloseNewBktModal();
            }, [1000]);
            // getBucketcreatedData();
            dispatch(getBucketData());
            successToast(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log("bucket created err***", err);
          setBucketNameErr(err?.response?.data?.message);

          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  ///****** bucket create for my private account validation */
  const ownBucketValidation = () => {
    let BucketValid = true;
    if (Comp_Bkt_Name === "") {
      BucketValid = false;
      setOwnBucketNameErr(<>*{text_err_bucket_name}!</>);
    }
    if (Comp_Bkt_AccessKeyId === "") {
      BucketValid = false;
      setAccessKeyErr(<>*{text_access_key_err}!</>);
    }
    if (Comp_Bkt_SecretAccessKey === "") {
      BucketValid = false;
      setSecretKeyErr(<>*{text_secret_key_err}!</>);
    }
    return BucketValid;
  };
  ///****** bucket create for my private account  */
  const OwnyourbucketHandle = () => {
    if (ownBucketValidation()) {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/add-company-bucket";

      var data = {
        region: Comp_Bkt_Region,
        accessKeyId: Comp_Bkt_AccessKeyId,
        secretAccessKey: Comp_Bkt_SecretAccessKey,
        bucketName: Comp_Bkt_Name,
        companyId: CompanyId,
        userId: UserID,
        isOrg: true,
        bucketACLOption: publicPrivate,
      };

      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("own bucket created***", res);
          if (res?.status === 201) {
            successToast(res?.data?.message);
            setTimeout(() => {
              handleCloseCredModal();
            }, [1000]);
            // getBucketexistingData();
            dispatch(getBucketData());
          }
        })
        .catch((err) => {
          console.log("own bucket created err***", err);
          setErrCreatebucket(err?.response?.data?.message);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  ///******get already created bucket data [atom-storage public and private]  */
  // const getBucketData = () => {
  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/company/get-buckets?userId=${UserID}`;

  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then(function (response) {
  //       var data = response.data.data;
  //       setBucketAll(data);
  //       setAtomS3Data(data);
  //       console.log("get bucket data", data);
  //       setEmpCount(data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // errorToast(err);
  //       if (error.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         setTimeout(() => {
  //           navigate("/");
  //         });
  //       }
  //       errorToast(error.response.data.message);
  //     })
  //     .then(function () {});
  // };

  // const getBucketcreatedData = () => {
  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/company/get-created-buckets?userId=${UserID}`;

  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then(function (response) {
  //       var data = response.data.data;
  //       console.log("get bucket created data", data);

  //     })
  //     .catch(function (error) {
  //       console.log(error);

  //       if (error.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         setTimeout(() => {
  //           navigate("/");
  //         });
  //       }
  //       errorToast(error.response.data.message);
  //     })
  //     .then(function () {});
  // };
  ///****** get user craeted bucket data for my private s3 storage  */
  // const getBucketexistingData = () => {
  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/company/get-existing-buckets?userId=${UserID}`;

  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then(function (response) {
  //       var data = response.data.data;
  //       console.log("get bucket created data", data);

  //       setMyprivateData(data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // errorToast(err);
  //       if (error.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         setTimeout(() => {
  //           navigate("/");
  //         });
  //       }
  //       errorToast(error.response.data.message);
  //     })
  //     .then(function () {});
  // };
  useEffect(() => {
    setBucketNameInstruction(
      "The bucket name must be unique within its partition, be between 3 and 63 characters long, consist only of lowercase letters, numbers, dots (.), and hyphens (-), and must begin and end with a letter or number. Please adjust your bucket name accordingly."
    );
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_selectbucket1(
        xpath.select1("/resources/string[@name='text_selectbucket1']", doc)
          .firstChild.data
      );
      setText_selectbucket(
        xpath.select1("/resources/string[@name='text_selectbucket']", doc)
          .firstChild.data
      );
      setText_asn(
        xpath.select1("/resources/string[@name='text_asn']", doc).firstChild
          .data
      );
      setText_comprofile(
        xpath.select1("/resources/string[@name='text_compprofile']", doc)
          .firstChild.data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_err_storage(
        xpath.select1("/resources/string[@name='text_err_storage']", doc)
          .firstChild.data
      );
      setText_selectrange(
        xpath.select1("/resources/string[@name='text_selectrange']", doc)
          .firstChild.data
      );
      setText_managestorage(
        xpath.select1("/resources/string[@name='text_managestorage']", doc)
          .firstChild.data
      );
      setText_selstoragelocation(
        xpath.select1("/resources/string[@name='text_selstoragelocation']", doc)
          .firstChild.data
      );
      setText_atomstoragenetwork1(
        xpath.select1(
          "/resources/string[@name='text_atomstoragenetwork1']",
          doc
        ).firstChild.data
      );
      setText_createnewbucket(
        xpath.select1("/resources/string[@name='text_createnewnbucket']", doc)
          .firstChild.data
      );
      setText_bucketname(
        xpath.select1("/resources/string[@name='text_bucketname']", doc)
          .firstChild.data
      );
      setText_awsregion(
        xpath.select1("/resources/string[@name='text_awsregion']", doc)
          .firstChild.data
      );
      setText_selawsregion(
        xpath.select1("/resources/string[@name='text_selawsregion']", doc)
          .firstChild.data
      );
      setText_create(
        xpath.select1("/resources/string[@name='text_create']", doc).firstChild
          .data
      );
      setText_addbutton(
        xpath.select1("/resources/string[@name='text_addbutton']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_users(
        xpath.select1("/resources/string[@name='text_users']", doc).firstChild
          .data
      );
      setText_user(
        xpath.select1("/resources/string[@name='text_user']", doc).firstChild
          .data
      );
      setText_View_users(
        xpath.select1("/resources/string[@name='text_View_users']", doc)
          .firstChild.data
      );
      setText_View_user(
        xpath.select1("/resources/string[@name='text_View_user']", doc)
          .firstChild.data
      );
      setText_select_amazon_acc(
        xpath.select1("/resources/string[@name='text_Select_amazon_acc']", doc)
          .firstChild.data
      );
      set_text_atom_storage(
        xpath.select1("/resources/string[@name='text_atom_storage']", doc)
          .firstChild.data
      );
      setText_my_private_bkt(
        xpath.select1("/resources/string[@name='text_my_private_bkt']", doc)
          .firstChild.data
      );
      setText_done(
        xpath.select1("/resources/string[@name='text_done']", doc).firstChild
          .data
      );
      setText_create_amaxon_bkt(
        xpath.select1("/resources/string[@name='text_create_amazon_bkt']", doc)
          .firstChild.data
      );
      setText_enter_bkt_name(
        xpath.select1("/resources/string[@name='text_enter_bkt_name']", doc)
          .firstChild.data
      );
      setText_enter_cmp_name(
        xpath.select1("/resources/string[@name='text_enter_cmp_name']", doc)
          .firstChild.data
      );

      setText_your_access_key(
        xpath.select1("/resources/string[@name='text_your_access_key']", doc)
          .firstChild.data
      );

      setText_enter_access_key_id(
        xpath.select1(
          "/resources/string[@name='text_enter_access_key_id']",
          doc
        ).firstChild.data
      );
      setText_upload_img(
        xpath.select1("/resources/string[@name='text_upload_img']", doc)
          .firstChild.data
      );
      setText_err_bucket_name(
        xpath.select1("/resources/string[@name='text_err_bucket_name']", doc)
          .firstChild.data
      );
      setText_access_key_err(
        xpath.select1("/resources/string[@name='text_access_key_err']", doc)
          .firstChild.data
      );
      setText_secret_key_err(
        xpath.select1("/resources/string[@name='text_secret_key_err']", doc)
          .firstChild.data
      );
      setText_upload_img_err(
        xpath.select1("/resources/string[@name='text_upload_img_err']", doc)
          .firstChild.data
      );
      setText_img_type_err(
        xpath.select1("/resources/string[@name='text_img_type_err']", doc)
          .firstChild.data
      );
      setText_addexisitingbucket(
        xpath.select1("/resources/string[@name='text_addexisitingbucket']", doc)
          .firstChild.data
      );

      setText_addexisitingbucket_2(
        xpath.select1(
          "/resources/string[@name='text_addexisitingbucket_2']",
          doc
        ).firstChild.data
      );
      setText_createnewbucket_2(
        xpath.select1("/resources/string[@name='text_createnewnbucket_2']", doc)
          .firstChild.data
      );

      setText_atomroot(
        xpath.select1("/resources/string[@name='text_atomroot']", doc)
          .firstChild.data
      );
      setText_existingbucketons3(
        xpath.select1("/resources/string[@name='text_existingbucketons3']", doc)
          .firstChild.data
      );
      setText_bucketownership(
        xpath.select1("/resources/string[@name='text_bucketownership']", doc)
          .firstChild.data
      );
      setText_private(
        xpath.select1("/resources/string[@name='text_private']", doc).firstChild
          .data
      );
      setText_public(
        xpath.select1("/resources/string[@name='text_public']", doc).firstChild
          .data
      );
      setText_secretaccesskey(
        xpath.select1("/resources/string[@name='text_secretaccesskey']", doc)
          .firstChild.data
      );
      setText_atomstoragebucket(
        xpath.select1("/resources/string[@name='text_atomstoragebucket']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  const closePopup = () => {
    setOpenPopup(false);
    setCheckAWS(false);
    setCheckIPFS(false);
    setAllBucket([]);
    setAWSBucketId("");
    setAwsBucketName("");
    SetSelectRangeIPFS("10 GB");
    SetSelectRange("10 GB");
    setErrorPopup("");
  };
  useEffect(() => {
    if (atomS3Data?.length > 0 && effect === true) {
      getBucket();
    }
  }, [atomS3Data?.length]);
  const getBucket = () => {
    var Val = atomS3Data;
    if (Val.length > 0) {
      setAllBucket(Val);

      var userID = sessionStorage.getItem("userID");
      var data = {
        userId: userID,
        bucketName: Val[0].bucketName,
        bucketId: Val[0].bucketId,
        users_existing_bucket: Val[0].users_existing_bucket,
        atom_bucket: Val[0].atom_bucket,
      };
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-aws`;
      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("read file res***", res?.data);

          var Data = res?.data?.sizeInBytes;
          if (Data !== "") {
            var TotalusedStorage = Data;

            setStorageAWSsize(TotalusedStorage);
          }
          if (Val[0].users_existing_bucket === true) {
            var TotalStorage =
              res?.data?.total_storage_size?.aws_package_size_own;
            setTotalStorageAWS(TotalStorage);
          }
          if (Val[0].users_existing_bucket === false) {
            var TotalStorage =
              res?.data?.total_storage_size?.aws_package_size_created;
            setTotalStorageAWS(TotalStorage);
          }
          if (Val[0].atom_bucket === true) {
            console.log(size(res?.data?.total_size[0]?.pack_size));
            if (Val[0].bucketName === "atom-storage-private") {
              setTotalStorageAWS(res?.data?.total_size[1]?.pack_size);
            } else {
              setTotalStorageAWS(res?.data?.total_size[0]?.pack_size);
            }
          }
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  const handleSelectBucket = (val) => {
    console.log(val.target.value);
    setAWSBucketId(val.target.value);
    var BucketFinalName = allBucket.filter(
      (e, i) => e.bucketId === val.target.value
    );
    console.log(BucketFinalName);
    if (BucketFinalName.length > 0) {
      setAwsBucketName(BucketFinalName[0].bucketName);
    }
    var userID = sessionStorage.getItem("userID");
    var data = {
      userId: userID,
      bucketName: BucketFinalName[0].bucketName,
      bucketId: BucketFinalName[0].bucketId,
      users_existing_bucket: BucketFinalName[0].users_existing_bucket,
      atom_bucket: BucketFinalName[0].atom_bucket,
    };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-aws`;
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("read file res***", res?.data);

        var Data = res?.data?.sizeInBytes;
        if (Data !== "") {
          var TotalusedStorage = Data;

          setStorageAWSsize(TotalusedStorage);
        }
        if (BucketFinalName[0].users_existing_bucket === true) {
          var TotalStorage =
            res?.data?.total_storage_size?.aws_package_size_own;
          setTotalStorageAWS(TotalStorage);
        }
        if (BucketFinalName[0].users_existing_bucket === false) {
          var TotalStorage =
            res?.data?.total_storage_size?.aws_package_size_created;
          setTotalStorageAWS(TotalStorage);
        }
        if (BucketFinalName[0].atom_bucket === true) {
          console.log(size(res?.data?.total_size[0]?.pack_size));
          if (BucketFinalName[0].bucketName === "atom-storage-private") {
            setTotalStorageAWS(res?.data?.total_size[1]?.pack_size);
          } else {
            setTotalStorageAWS(res?.data?.total_size[0]?.pack_size);
          }
        }
      })
      .catch((err) => {
        console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  const getStorageLimitIPFS = (bucketname) => {
    var userID = sessionStorage.getItem("userID");
    console.log("awsBucketName in api call***", bucketname);
    var data = { userId: userID, bucketName: bucketname };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-ipfs`;

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("read file res***", res?.data?.sizeInBytes);
        var Value = res?.data?.sizeInBytes;
        if (Value.length === 0) {
          setStorageIPFSTotalSize(res?.data?.total_size);
        } else {
          var Data = res?.data?.sizeInBytes[0]?.totalSize;
          setStorageIPFSsize(Data);
          setStorageIPFSTotalSize(res?.data?.total_size);
        }
      })
      .catch((err) => {
        console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  const handleIncreaseSizeS3 = () => {
    console.log(allBucket.filter((e) => e.bucketId === awsBucketId));
    var FindBucket = allBucket.filter((e) => e.bucketId === awsBucketId);
    if (FindBucket.length > 0) {
      if (FindBucket[0]?.atom_bucket === false) {
        if (FindBucket[0]?.users_existing_bucket === false) {
          handleincsizeCraete(
            FindBucket[0]?.bucketName,
            FindBucket[0]?.bucketId
          );
        } else {
          handleincsizeOwn(FindBucket[0]?.bucketName, FindBucket[0]?.bucketId);
        }
      }
      if (FindBucket[0]?.atom_bucket === true) {
        handleincreaseSize(FindBucket[0]?.bucketName, FindBucket[0]?.bucketId);
      }
    }
  };
  const handleincreaseSize = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userid: UserID,
      bucketname: bucketName,
      bucketId: bucketId,
      newStorageSizeAtom: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-atom";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  const handleincsizeCraete = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";

    var data = {
      userId: UserID,
      bucketName: bucketName,
      bucketId: bucketId,
      newAwsStorageSize: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-created";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  //*** Increase AWS OWN Size*/
  const handleincsizeOwn = (bucketName, bucketId) => {
    var sizeinbyte =
      selectRange === "10 GB"
        ? 10737418240
        : selectRange === "20 GB"
        ? 21474836480
        : selectRange === "30 GB"
        ? 32212254720
        : selectRange === "40 GB"
        ? 42949672960
        : selectRange === "50 GB"
        ? 53687091200
        : selectRange === "60 GB"
        ? 64424509440
        : selectRange === "70 GB"
        ? 75161927680
        : selectRange === "80 GB"
        ? 85899345920
        : selectRange === "90 GB"
        ? 96636764160
        : selectRange === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userId: UserID,
      bucketName: bucketName,
      bucketId: bucketId,
      newAwsStorageSize: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-aws-own";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          // dispatch(getStorageAWS());
          dispatch(getAwsPublicPrivateData());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  //*** Increase IPFS Size*/
  const handleincreaseSizeIPFS = () => {
    var sizeinbyte =
      selectRangeIPFS === "10 GB"
        ? 10737418240
        : selectRangeIPFS === "20 GB"
        ? 21474836480
        : selectRangeIPFS === "30 GB"
        ? 32212254720
        : selectRangeIPFS === "40 GB"
        ? 42949672960
        : selectRangeIPFS === "50 GB"
        ? 53687091200
        : selectRangeIPFS === "60 GB"
        ? 64424509440
        : selectRangeIPFS === "70 GB"
        ? 75161927680
        : selectRangeIPFS === "80 GB"
        ? 85899345920
        : selectRangeIPFS === "90 GB"
        ? 96636764160
        : selectRangeIPFS === "100 GB"
        ? 107374182400
        : "";
    var data = {
      userid: UserID,
      ipfs_package_size: sizeinbyte,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/increase-storage-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Increase size res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          dispatch(getStorageIPFS());
          closePopup();
        }
      })
      .catch((err) => {
        console.log("Increase size err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  return (
    <div className="comp-m">
      <Header />
      <div className="comp-mm">
        <div className="comp-main">
          <h3 className=" text-center HeadingText">{text_compprofile}</h3>

          <div className="container containerBox mt-3 p-2">
            <div className="row main-profile-container">
              <div className="col-md-2 user-avatar-img p-5">
                <img
                  src={
                    compData.profileImage === "" ||
                    compData.profileImage === null
                      ? DummyProfile
                      : compData.profileImage
                  }
                  alt=""
                  className=""
                />
                <div
                  className="avatar-img-btn"
                  onClick={() => handleOpenProfileImagePopup()}
                >
                  <input type="file" />
                  <label for="avatar-img">
                    <FaCamera className="camera-icon" />
                  </label>
                </div>
              </div>

              {/* <div className="col-md-4 p-3 mt-4" id="profiledetail">
                {isComNameEdit === true ? (
                  <>
                    <div className="d-flex gap-3 mt-4">
                      <div className="inputForm uf-inputs mt-1 vf-input-inner">
                        <input
                          type="text"
                          className="input login-input vf-input-i"
                          placeholder={text_enter_cmp_name}
                          defaultValue={
                            ComNameEdit === "" ? ComapanyName : ComNameEdit
                          }
                          onChange={(e) => setComNameEdit(e.target.value)}
                        />
                      </div>
                      <div className="d-flex gap-4">
                        <button
                          className="editbtn-com cross-iconstyle-com"
                          onClick={() => [
                            setIsComNameEdit(false),
                            setComNameEdit(""),
                          ]}
                        >
                          <MdCancel />
                        </button>
                        <button
                          className="editbtn-com check-iconstyle-com"
                          onClick={handleUpdateCompany}
                        >
                          <FaCheckCircle />{" "}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-4">
                      <div className=" com-text ">
                        {ComNameEdit === "" ? ComapanyName : ComNameEdit}
                      </div>
                      <button
                        className="editbtn-com"
                        onClick={() => setIsComNameEdit(!isComNameEdit)}
                      >
                        <MdEdit className=" mx-3  com-text" />
                      </button>
                    </div>
                  </>
                )}

               
                <div className="d-flex gap-3 mt-2">
                  <div className="comp-222">
                    {EmpCount > 1
                      ? `${EmpCount} ${text_users}`
                      : `${EmpCount} ${text_user}`}{" "}
                  </div>

                  <button
                    className="yf-download-btn view-user mt-1"
                    onClick={() => navigate("/userlist")}
                  >
                    {EmpCount > 1 ? text_View_users : text_View_user}
                  </button>
                </div>
              
              </div> */}
              <div className="col-md-4 p-3 mt-4" id="profiledetail">
                {isComNameEdit === true ? (
                  <>
                    <div className="d-flex gap-3 mt-4">
                      <div className="inputForm uf-inputs mt-1 vf-input-inner">
                        <input
                          type="text"
                          className="input login-input vf-input-i"
                          placeholder={text_enter_cmp_name}
                          defaultValue={
                            ComNameEdit === "" ? ComapanyName : ComNameEdit
                          }
                          onChange={(e) => setComNameEdit(e.target.value)}
                        />
                      </div>
                      <div className="d-flex gap-4">
                        <button
                          className="editbtn-com cross-iconstyle-com"
                          onClick={() => [
                            setIsComNameEdit(false),
                            setComNameEdit(""),
                          ]}
                        >
                          <MdCancel />
                        </button>
                        <button
                          className="editbtn-com check-iconstyle-com"
                          onClick={handleUpdateCompany}
                        >
                          <FaCheckCircle />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex mt-4">
                      <div className="com-text">
                        {ComNameEdit === "" ? ComapanyName : ComNameEdit}
                      </div>
                      <button
                        className="editbtn-com"
                        onClick={() => setIsComNameEdit(!isComNameEdit)}
                      >
                        <MdEdit className="mx-3 com-text" />
                      </button>
                    </div>
                  </>
                )}

                <div className="d-flex gap-3 mt-2">
                  <div className="comp-222">
                    {EmpCount > 1
                      ? `${EmpCount} ${text_users}`
                      : `${EmpCount} ${text_user}`}
                  </div>
                  <button
                    className="yf-download-btn view-user mt-1"
                    onClick={() => navigate("/userlist")}
                  >
                    {EmpCount > 1 ? text_View_users : text_View_user}
                  </button>
                </div>

                <div className="btn-manage  mt-3 mb-2">
                  <button
                    className="manage-btn"
                    onClick={() => setOpenPopup(!openPopup)}
                  >
                    {text_managestorage}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="p-4 com-divider">
                <Divider className="mb-1" />
                {/* <div className="comp-3 text-center mt-2">
                  <h3 className="com-text">{text_selstoragelocation}</h3>

                  <div className="container">
                    <div className="row">
                      <div className="col d-flex  w-100">
                        <div
                          className="form-check w-50"
                          id="Com-responsive-bucket"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              selectedStorage === "AWS"
                                ? "transparent"
                                : "transparent"
                            } d-block d-sm-block d-md-block`}
                            id="com-amazon-btn"
                            onClick={() => handleStorageToggle("AWS")}
                            style={{
                              marginRight: "10px",
                              backgroundColor: "transparent",
                              color: "black",
                              backgroundColor:
                                selectedStorage === "AWS"
                                  ? "#FFFFFF"
                                  : "transparent",
                              border:
                                selectedStorage === "AWS"
                                  ? "2px solid #009087"
                                  : "none",
                              outline: "none",
                              boxShadow: "none",
                            }}
                          >
                            {text_amazons3}
                          </button>
                        </div>

                        <div
                          className="form-check w-50 "
                          id="Com-responsive-bucket"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              selectedStorage === "IPFS" ? "#00afa3" : "#00afa3"
                            } d-block d-sm-block d-md-block`}
                            id="com-amazon-btn"
                            onClick={() => handleStorageToggle("IPFS")}
                            style={{
                              marginRight: "10px",
                              backgroundColor: "transparent",
                              color: "black",
                              border: "none",
                              outline: "none",
                              boxShadow: "none",
                              backgroundColor:
                                selectedStorage === "IPFS"
                                  ? "#FFFFFF"
                                  : "transparent",
                              border:
                                selectedStorage === "IPFS"
                                  ? "2px solid #009087"
                                  : "none",
                              overflow: "hidden",
                              wordBreak: "break-word",
                            }}
                            title={selectedStorage === "IPFS" ? text_asn : ""}
                          >
                            {text_asn}
                          </button>
                        </div>
                      </div>
                      {selectedStorage === "AWS" && (
                        <div className="mt-3">
                          <h5 className="">{text_Select_amazon_acc}</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-check d-flex justify-content-center">
                                <label
                                  className="form-check-label"
                                  id="Com-responsive-bucket"
                                  htmlFor="amazon-s3"
                                  style={{
                                    borderBottom: atomS3
                                      ? "2px solid #009087"
                                      : "none",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {text_atom_storage}
                                </label>
                              </div>
                              {atomS3 && (
                                <div className="ml-4 mt-3">
                                  <div
                                    className="form-check"
                                    id="Com-responsive-bucket"
                                  >
                                    <input
                                      type="checkbox"
                                      name="amazon-s3-bucket"
                                      id="amazon-s3-bucket"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        handleOpennNewBktModal(e.target.checked)
                                      }
                                      checked={newBktModal}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      className="form-check-label create-new-bucket"
                                      htmlFor="amazon-s3-bucket"
                                      style={{
                                        backgroundColor: " #009087",
                                        width: "40%",
                                        height: "max-content",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: "#ffff",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {text_createnewnbucket}
                                    </label>
                                  </div>
                                  <div className="list-group mt-3">
                                    {createdBucketData.map((e) => {
                                      return (
                                        <>
                                          <ul
                                            className="list-group-item"
                                            key={e.bucketName}
                                            style={{ listStyleType: "none" }}
                                          >
                                            <li>
                                              <div className="d-flex justify-content-between">
                                                <p className="text-start">
                                                  {e.bucketName ===
                                                  "atom-storage"
                                                    ? `${sessionStorage.getItem(
                                                        "fName"
                                                      )}'s Bucket`
                                                    : e.bucketName ===
                                                      "atom-storage-private"
                                                    ? `${sessionStorage.getItem(
                                                        "fName"
                                                      )}'s Bucket`
                                                    : e.bucketName}
                                                </p>
                                                <p className="text-end bucket-public-private">
                                                  {e.bucketACLOption}
                                                </p>
                                              </div>
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="form-check d-flex justify-content-center">
                                <label
                                  className="form-check-label"
                                  id="Com-responsive-bucket"
                                  htmlFor="amazon-s3-my-s3"
                                  style={{
                                    borderBottom: myPrivateS3
                                      ? "2px solid #009087"
                                      : "none",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {text_my_private_bkt}
                                </label>
                              </div>

                              {myPrivateS3 && (
                                <div className="ml-4 mt-3">
                                  <div
                                    className="form-check"
                                    id="Com-responsive-bucket"
                                  >
                                    <input
                                      type="checkbox"
                                      id="credModal"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        handleOpenCredModal(e.target.checked)
                                      }
                                      checked={credModal}
                                      style={{ display: "none" }}
                                    />
                                    <label
                                      className="form-check-label create-new-bucket"
                                      htmlFor="credModal"
                                      style={{
                                        backgroundColor: " #009087",
                                        width: "40%",
                                        height: "max-content",
                                        textAlign: "center",
                                        color: "#ffff",
                                        fontWeight: "bold",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {text_addexisitingbucket}
                                    </label>
                                  </div>
                                  <div className="list-group mt-3">
                                    {existingBucketData.map((e) => {
                                      return (
                                        <>
                                          <ul
                                            className="list-group-item"
                                            key={e.bucketName}
                                            style={{ listStyleType: "none" }}
                                          >
                                            <li>
                                              <div className="d-flex justify-content-between">
                                                <p className="text-start">
                                                  {e.bucketName}
                                                </p>
                                                <p className="text-end bucket-public-private">
                                                  {e.bucketACLOption}
                                                </p>
                                              </div>
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedStorage === "IPFS" && (
                        <ul className="list-group mt-3">
                          <li className="list-group-item">{text_atomroot}</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="comp-3 text-center mt-4">
                  <h3 className="com-text">{text_selstoragelocation}</h3>
                  <div className="container">
                    <ul
                      className="nav nav-tabs justify-content-center mt-4"
                      id="storageTab"
                      role="tablist"
                    >
                      <div
                        className="row w-100 mx-2 justify-content-space"
                        style={{ borderBottom: "2px solid #009087" }}
                      >
                        <div
                          className="col-6 d-flex justify-content-center"
                          style={{ paddingRight: "0%", paddingLeft: "0%" }}
                        >
                          <li
                            className="nav-item w-100"
                            role="presentation"
                            // style={{
                            //   backgroundColor: "#F0F0F0",
                            // }}
                          >
                            <button
                              className={`nav-link ${
                                selectedStorage === "AWS" ? "active" : ""
                              }   `}
                              id="aws-tab"
                              data-bs-toggle="tab"
                              role="tab"
                              aria-controls="aws"
                              aria-selected={selectedStorage === "AWS"}
                              onClick={() => handleStorageToggle("AWS")}
                              style={{
                                // border:
                                //   selectedStorage === "AWS"
                                //     ? "2px solid #009087"
                                //     : "2px solid #d3d3d3",
                                width: "98%",
                              }}
                            >
                              {text_amazons3}
                            </button>
                          </li>
                        </div>
                        <div
                          className="col-6 d-flex justify-content-center"
                          style={{ paddingRight: "0%", paddingLeft: "0%" }}
                        >
                          <li
                            className="nav-item w-100"
                            role="presentation"
                            // style={{ backgroundColor: "#F0F0F0" }}
                          >
                            <button
                              className={`nav-link ${
                                selectedStorage === "IPFS" ? "active" : ""
                              } `}
                              id="ipfs-tab"
                              data-bs-toggle="tab"
                              role="tab"
                              aria-controls="ipfs"
                              aria-selected={selectedStorage === "IPFS"}
                              onClick={() => handleStorageToggle("IPFS")}
                              style={{
                                // border:
                                //   selectedStorage === "IPFS"
                                //     ? "2px solid #009087"
                                //     : "2px solid #d3d3d3",
                                width: "98%",
                              }}
                            >
                              {text_asn}
                            </button>
                          </li>
                        </div>
                      </div>
                    </ul>
                    <div className="tab-content" id="storageTabContent">
                      <div
                        className={`tab-pane fade ${
                          selectedStorage === "AWS" ? "show active" : ""
                        }`}
                        id="aws"
                        role="tabpanel"
                        aria-labelledby="aws-tab"
                      >
                        {selectedStorage === "AWS" && (
                          <div className="mt-4">
                            <h5>{text_Select_amazon_acc}</h5>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-check d-flex justify-content-center">
                                  <label
                                    className={`form-check-label ${
                                      atomS3 ? "border-bottom" : ""
                                    }`}
                                    id="Com-responsive-bucket"
                                    htmlFor="amazon-s3"
                                  >
                                    {text_atom_storage}
                                  </label>
                                </div>
                                {atomS3 && (
                                  <div className="ml-4 mt-3">
                                    <div
                                      className="form-check"
                                      id="Com-responsive-bucket"
                                    >
                                      <input
                                        type="checkbox"
                                        name="amazon-s3-bucket"
                                        id="amazon-s3-bucket"
                                        className="form-check-input"
                                        onChange={(e) =>
                                          handleOpennNewBktModal(
                                            e.target.checked
                                          )
                                        }
                                        checked={newBktModal}
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        className="form-check-label create-new-bucket"
                                        htmlFor="amazon-s3-bucket"
                                        style={{
                                          backgroundColor: " #009087",
                                          width: "50%",
                                          height: "max-content",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          color: "#ffff",
                                          borderRadius: "3px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {text_createnewnbucket_2}
                                      </label>
                                    </div>
                                    <div className="list-group mt-3">
                                      {createdBucketData.map((e) => (
                                        <ul
                                          className="list-group-item"
                                          key={e.bucketName}
                                          style={{ listStyleType: "none" }}
                                        >
                                          <li>
                                            <div className="d-flex justify-content-between">
                                              <p className="text-start">
                                                {e.bucketName ===
                                                  "atom-storage" ||
                                                e.bucketName ===
                                                  "atom-storage-private"
                                                  ? `${sessionStorage.getItem(
                                                      "fName"
                                                    )}'s Bucket`
                                                  : e.bucketName}
                                              </p>
                                              <p className="text-center bucket-public-private">
                                                {e.bucketACLOption}
                                              </p>
                                            </div>
                                          </li>
                                        </ul>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-check d-flex justify-content-center">
                                  <label
                                    className={`form-check-label ${
                                      myPrivateS3 ? "border-bottom" : ""
                                    }`}
                                    id="Com-responsive-bucket"
                                    htmlFor="amazon-s3-my-s3"
                                  >
                                    {text_my_private_bkt}
                                  </label>
                                </div>
                                {myPrivateS3 && (
                                  <div className="ml-4 mt-3">
                                    <div
                                      className="form-check"
                                      id="Com-responsive-bucket"
                                    >
                                      <input
                                        type="checkbox"
                                        id="credModal"
                                        className="form-check-input"
                                        onChange={(e) =>
                                          handleOpenCredModal(e.target.checked)
                                        }
                                        checked={credModal}
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        className="form-check-label create-new-bucket"
                                        htmlFor="credModal"
                                        style={{
                                          backgroundColor: " #009087",
                                          width: "50%",
                                          height: "max-content",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          color: "#ffff",
                                          borderRadius: "3px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {text_addexisitingbucket_2}
                                      </label>
                                    </div>
                                    <div className="list-group mt-3">
                                      {existingBucketData.map((e) => (
                                        <ul
                                          className="list-group-item"
                                          key={e.bucketName}
                                          style={{ listStyleType: "none" }}
                                        >
                                          <li>
                                            <div className="d-flex justify-content-between">
                                              <p className="text-start">
                                                {e.bucketName}
                                              </p>
                                              <p className="text-center bucket-public-private">
                                                {e.bucketACLOption}
                                              </p>
                                            </div>
                                          </li>
                                        </ul>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={`tab-pane fade ${
                          selectedStorage === "IPFS" ? "show active" : ""
                        }`}
                        id="ipfs"
                        role="tabpanel"
                        aria-labelledby="ipfs-tab"
                      >
                        {selectedStorage === "IPFS" && (
                          <ul className="list-group mt-3">
                            <li className="list-group-item">{text_atomroot}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="comp-5 gap-3">
                <button
                  className="box-btn-save-com"
                  onClick={() => navigate("/selectbucket")}
                >
                  {text_done}
                </button>
              </div>
            </div>
          </div>
          {credModal && (
            <div>
              <Modal
                open={credModal}
                onClose={handleCloseCredModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="uf-mui-box">
                  <HighlightOffOutlinedIcon
                    className="uf-modal-close"
                    onClick={handleCloseCredModal}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="uf-modal-1 uf-modal-popup"
                  >
                    {/* {text_create_bkt_my_storage} */}
                    {text_existingbucketons3}
                  </Typography>
                  <hr />
                  <div className="d-flex">
                    <div className="">
                      <div className="uf-inputs-main ">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-column">
                              <label className="login-labels com-popup-text">
                                {text_bucketname}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inputForm uf-inputs add-existing-bucket mt-1">
                              <input
                                type="text"
                                className="input login-input"
                                placeholder={text_enter_bkt_name}
                                onChange={(e) => [
                                  set_Comp_Bkt_Name(e.target.value),
                                  setOwnBucketNameErr(""),
                                ]}
                                value={Comp_Bkt_Name}
                              />
                            </div>
                            <p className="errMsg  bucket-err">
                              {ownBucketNameErr}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="uf-inputs-main mt-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-column">
                              <label className="login-labels com-popup-text">
                                {text_bucketownership}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <select
                              onChange={(e) => setPublicPrivate(e.target.value)}
                              className="inputForm add-existing-bucket ownership-select uf-inputs mt-1"
                              value={publicPrivate}
                            >
                              <option selected value="private">
                                {text_private}
                              </option>
                              <option selected value="public">
                                {text_public}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="uf-inputs-main mt-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-column">
                              <label className="login-labels com-popup-text">
                                {text_awsregion}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <select
                              onChange={(e) =>
                                set_Comp_Bkt_Region(e.target.value)
                              }
                              className="inputForm add-existing-bucket uf-inputs mt-1"
                              value={Comp_Bkt_Region}
                            >
                              <option selected disabled>
                                {text_selawsregion}
                              </option>
                              <option value="us-east-2">us-east-2</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="uf-inputs-main mt-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-column">
                              <label className="login-labels com-popup-text">
                                {text_your_access_key}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inputForm add-existing-bucket uf-inputs mt-1">
                              <input
                                type="text"
                                className="input login-input"
                                placeholder={text_enter_access_key_id}
                                onChange={(e) => [
                                  set_Comp_Bkt_AccessKeyId(e.target.value),
                                  setAccessKeyErr(""),
                                ]}
                                value={Comp_Bkt_AccessKeyId}
                              />
                            </div>
                            <p className="errMsg bucket-err">{accessKeyErr}</p>
                          </div>
                        </div>
                      </div>

                      <div className="uf-inputs-main mt-3">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-column">
                              <label className="login-labels com-popup-text">
                                {/* {text_access_key_id} */}
                                {text_secretaccesskey}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inputForm add-existing-bucket uf-inputs mt-1">
                              <input
                                type="text"
                                className="input login-input"
                                placeholder={text_secretaccesskey}
                                onChange={(e) => [
                                  set_Comp_Bkt_SecretAccessKey(e.target.value),
                                  setSecretKeyErr(""),
                                ]}
                                value={Comp_Bkt_SecretAccessKey}
                              />
                            </div>
                            <p className="errMsg bucket-err">{secretKeyErr}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <p className="errMsg text-center">{errCreatebucket}</p>
                  <div className="box-btn-div">
                    <button className="box-btn" onClick={handleCloseCredModal}>
                      {text_cancel}
                    </button>
                    <button
                      className="box-btn-save"
                      onClick={OwnyourbucketHandle}
                    >
                      {text_addbutton}
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          )}
          {newBktModal && (
            <div>
              <Modal
                open={newBktModal}
                onClose={handleCloseNewBktModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="uf-mui-box">
                  <HighlightOffOutlinedIcon
                    className="uf-modal-close"
                    onClick={handleCloseNewBktModal}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="uf-modal-1 uf-modal-popup"
                  >
                    {text_create_amazon_bkt}
                  </Typography>
                  <hr />
                  <div className="uf-inputs-main  d-flex  flex-wrap gap-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="flex-column">
                          <label className="login-labels com-popup-text">
                            {text_bucketname}
                          </label>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="bucket-name-tooltip"
                                className="custom-tooltip mt-3"
                              >
                                {bucketNameinstruction}
                              </Tooltip>
                            }
                          >
                            <button className="info-button mx-1">
                              <FaCircleInfo className="mb-3 info-button" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputForm uf-inputs mt-1">
                          <input
                            type="text"
                            className="input login-input"
                            placeholder={text_enter_bkt_name}
                            onChange={(e) => [
                              set_New_Bkt_Name(e.target.value),
                              setBucketNameErr(""),
                            ]}
                            value={New_Bkt_Name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uf-inputs-main mt-3 mb-2 d-flex flex-wrap gap-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="flex-column">
                          <label className="login-labels com-popup-text ">
                            {text_bucketownership}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <select
                          onChange={(e) => setPublicPrivate(e.target.value)}
                          className="uf-inputs uploadselectdrop-select-comp mt-1"
                          style={{ height: "40px" }}
                          value={publicPrivate}
                        >
                          <option selected value="private">
                            {text_private}
                          </option>
                          <option selected value="public">
                            {text_public}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="uf-inputs-main mt-3 d-flex flex-wrap  gap-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="flex-column ">
                          <label className="login-labels com-popup-text">
                            {text_awsregion}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <select
                          onChange={(e) => set_New_Bkt_Region(e.target.value)}
                          className="uf-inputs uploadselectdrop-select-comp aws-region-select mt-1"
                          value={New_Bkt_Region}
                        >
                          <option selected disabled>
                            {text_selawsregion}
                          </option>
                          <option value="us-east-2">us-east-2</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <p className="errMsg text-center">{bucketNameErr}</p>
                  <div className="box-btn-div">
                    <button
                      className="box-btn"
                      onClick={handleCloseNewBktModal}
                    >
                      {text_cancel}
                    </button>
                    <button className="box-btn-save" onClick={NewbucketHandle}>
                      {text_create}
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          )}
          <Modal
            open={openProfileImagePopup}
            onClose={handleCloseProfileImagePopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="Upload-image-Modal"
          >
            <Box
              sx={styleTwo}
              id="header-registermodal"
              className="Upload-image-event"
            >
              <div class>
                <div className="d-flex justify-content-end">
                  <IoIosCloseCircleOutline
                    className="closeIcon-venue"
                    onClick={() => [handleCloseProfileImagePopup()]}
                  />
                </div>
                <h3>{text_upload_img}</h3>
                <Divider />

                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control h_50 uploadinput"
                      onChange={coverimguplpoadValidation}
                    />
                  </div>
                </div>
                <p className="errMsg mx-2">{uploadImgErr}</p>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseProfileImagePopup()}
                    className="box-btn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button className="box-btn-save" onClick={uploadProfile}>
                    {" "}
                    {text_save}
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {/****** Manage storage popup **********/}
      {openPopup === true && (
        <>
          <div className="popupaccess bucket-popup popup-content">
            <div className="p-4">
              <div className="d-flex justify-content-between">
                <h5 className="mt-1">{text_managestorage}</h5>
                <h3 className="">
                  <CgCloseO onClick={closePopup} />
                </h3>
              </div>
              <Divider />
              <h5 className="text-center mt-3">{text_selstoragelocation}</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="gap-2 d-flex responsive-amazonbucket">
                    <input
                      type="checkbox"
                      name="amazon-s3"
                      id="amazon-s3"
                      className="mt-1 checkboxSty"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setCheckAWS(e.target.checked);
                          setErrorPopup("");
                          if (atomS3Data?.length === 0) {
                            setEffect(true);
                            dispatch(getBucketData());
                          } else {
                            getBucket();
                          }
                        } else {
                          setCheckAWS(false);
                        }
                      }}
                      checked={checkAWS}
                    />
                    <label for="amazon-s3" className="com-aws-text">
                      {text_amazons3}
                    </label>
                  </div>
                  {checkAWS ? (
                    <>
                      <h6 className="text-center responsive-popup-space"></h6>
                      <div className="d-flex gap-2 flex-wrap">
                        <p className="mt-2 bucket-font">{text_selectbucket1}</p>
                        <div className="select-bucket-com justify-content-center mt-1">
                          <select
                            className="uf-inputs w-100 uploadselectdrop-select"
                            onChange={(e) => [handleSelectBucket(e)]}
                            value={awsBucketId}
                          >
                            {allBucket.length > 0 ? (
                              <>
                                <option disabled>{text_selectbucket}</option>
                                {allBucket.map((v, i) => {
                                  return (
                                    <>
                                      <option value={v.bucketId}>
                                        {v.bucketName === "atom-storage"
                                          ? `${sessionStorage.getItem(
                                              "fName"
                                            )}'s Bucket`
                                          : v.bucketName ===
                                            "atom-storage-private"
                                          ? `${sessionStorage.getItem(
                                              "fName"
                                            )}'s Bucket`
                                          : v.bucketName}
                                        ({v.bucketACLOption})
                                      </option>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <option disabled selected>
                                  {text_selectbucket}
                                </option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="mx-4">
                        <ProgressBar
                          className="mt-3 mb-3"
                          completed={storageAWSsize}
                          bgColor="#008A82"
                          height="10px"
                          width="77%"
                          isLabelVisible={false}
                          labelColor="#1a91ec"
                          maxCompleted={totalStorageAWS}
                        />
                        <p style={{ marginTop: "-11px" }}>
                          {size(storageAWSsize)} of {size(totalStorageAWS)} used
                        </p>
                      </div>

                      <div className="d-flex gap-2 flex-wrap">
                        <p className="mt-2 bucket-font">
                          {text_selectrange}&nbsp;
                        </p>
                        <br />
                        <div className="select-bucket-com justify-content-center mt-1">
                          <select
                            className="uf-inputs uploadselectdrop-select"
                            onChange={(e) => SetSelectRange(e.target.value)}
                            value={selectRange}
                          >
                            <option disabled>{text_selectrange}</option>
                            <option value="10 GB">10 GB</option>
                            <option value="20 GB">20 GB</option>
                            <option value="30 GB">30 GB</option>
                            <option value="40 GB">40 GB</option>
                            <option value="50 GB">50 GB</option>
                            <option value="60 GB">60 GB</option>
                            <option value="70 GB">70 GB</option>
                            <option value="80 GB">80 GB</option>
                            <option value="90 GB">90 GB</option>
                            <option value="100 GB">100 GB</option>
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <div className="gap-2 d-flex responsive-amazonbucket">
                    <input
                      type="checkbox"
                      name="amazon-s3"
                      id="amazon-s3"
                      className="mt-1 checkboxSty"
                      checked={checkIPFS}
                      onChange={(e) => {
                        const isChecked = e.target.checked;

                        if (isChecked === true) {
                          setCheckIPFS(isChecked);
                          setErrorPopup("");
                          getStorageLimitIPFS("atom-storage");
                        } else {
                          setCheckIPFS(false);
                        }
                      }}
                    />
                    <label for="amazon-s3" className="com-aws-text">
                      {text_atomstoragenetwork1}
                    </label>
                  </div>
                  {checkIPFS === true ? (
                    <>
                      <h6 className="text-center mt-2">
                        {text_atomstoragebucket}
                      </h6>
                      <>
                        <div className="mx-4">
                          <ProgressBar
                            className="mt-3 mb-3"
                            completed={storageIPFSsize}
                            bgColor="#008A82"
                            height="10px"
                            width="72%"
                            isLabelVisible={false}
                            labelColor="#1a91ec"
                            maxCompleted={storageIPFStotalsize}
                          />
                          <p style={{ marginTop: "-11px" }}>
                            {size(storageIPFSsize)} of{" "}
                            {size(storageIPFStotalsize)} used
                          </p>
                        </div>
                      </>
                      <div className="d-flex gap-1 flex-wrap">
                        <p className="mt-4 bucket-font">
                          {text_selectrange}&nbsp;
                        </p>
                        <div className="select-bucket-com justify-content-center mt-3">
                          <select
                            className="uf-inputs uploadselectdrop-select"
                            onChange={(e) => [
                              SetSelectRangeIPFS(e.target.value),
                            ]}
                            value={selectRangeIPFS}
                          >
                            <option disabled>{text_selectrange}</option>
                            <option value="10 GB">10 GB</option>
                            <option value="20 GB">20 GB</option>
                            <option value="30 GB">30 GB</option>
                            <option value="40 GB">40 GB</option>
                            <option value="50 GB">50 GB</option>
                            <option value="60 GB">60 GB</option>
                            <option value="70 GB">70 GB</option>
                            <option value="80 GB">80 GB</option>
                            <option value="90 GB">90 GB</option>
                            <option value="100 GB">100 GB</option>
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="errMsg mt-2 text-center">{errorPopup}</p>
              <Divider className="mt-4" />
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2 mb-4">
              <button className="box-btn" onClick={closePopup}>
                {text_cancel}
              </button>
              {console.log(size(10737418240))}
              <button
                className="box-btn-save"
                onClick={() => [
                  checkAWS === true && checkIPFS === true
                    ? [handleIncreaseSizeS3(), handleincreaseSizeIPFS()]
                    : checkAWS === true
                    ? handleIncreaseSizeS3()
                    : checkIPFS === true
                    ? handleincreaseSizeIPFS()
                    : setErrorPopup(<>*{text_err_storage}!</>),
                ]}
              >
                {text_save}
              </button>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default CompProfile;
