import React, { useEffect } from "react";
import "../HomePage/HomePage.css";
import SubHeader from "./SubHeader";
import IpfsStorage from "../../utils/images/IPFSstorage.png";
import AccessControl from "../../utils/images/AccessControl.png";
import ApiKey from "../../utils/images/API-key.png";
import HomeMain from "../../utils/images/home-img.png";
import { Link, useNavigate } from "react-router-dom";
import box1 from "../../utils/images/Box-1.png";
import box2 from "../../utils/images/Box-2.png";
import { useDispatch } from "react-redux";


import Cookie from "js-cookie";
import { resetSelectBucketData } from "../../redux/SelectBucketSlice";
import { resetShareWithMeData } from "../../redux/sharewithmeslice";
import { resetUserListData } from "../../redux/UserListSlice";
import { resetIPFSrootfileData } from "../../redux/IPFSRootFolderSlice";
import { resetAwsRootFolderData } from "../../redux/AwsRootFolderSlice";
import { resetGetBucketData } from "../../redux/GetBucketdataSlice";
import { resetBothfileData } from "../../redux/BothFilesSlice";
import { resetgetCompanyData } from "../../redux/CompanyProfileSlice";
import { resetgetRegisterdUser } from "../../redux/RegisteredUserSlice";
import { resetEditFileData } from "../../redux/EditFileDataSlice";
import { resetSharedByMeData } from "../../redux/SharedByMeSlice";

function HomePage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSelectBucketData());
    dispatch(resetShareWithMeData());
    dispatch(resetUserListData());
    dispatch(resetIPFSrootfileData());
    dispatch(resetAwsRootFolderData());
    dispatch(resetGetBucketData());
    dispatch(resetBothfileData());
    dispatch(resetgetCompanyData());
    dispatch(resetgetRegisterdUser());
    dispatch(resetEditFileData());
    dispatch(resetSharedByMeData());
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);

  return (
    <>
      <div className="home-main">
        <SubHeader />
        <div className="p-5 w-100  container-content">
          <div className="row mt-3 p-5 w-100 main-container-content">
            <div className="col-md-1"></div>
            <div className="col-md-5 ">
              < >
                <div className="home-text ">
                  <h2 className="home-main-text w-100">
                    Secure, Scalable, and Immutable Global Storage for Large
                    Files
                  </h2>
                  <h4 className="mt-4 w-75 home-text-description">
                    Atom Storage provides a secure and efficient platform for
                    storing and accessing large files globally. Leveraging the
                    power of IPFS technology, Atom ensures robust,
                    decentralized, and immutable file management,
                    revolutionizing the way you handle large data assets.
                  </h4>
                  <div className="header-sub" id="getBtn">
                    <Link to="/signup">
                      <button className="home-getstarted mt-4">
                        Get Started
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            </div>
            <div className="col-md-6">
              <center>
                <img src={HomeMain} alt="" className="home-img" />
              </center>
            </div>
          </div>
          <div className="row p-2 justify-content-center mb-4 gap-5 w-100">
            <div className="homecardOne mx-2">
              <>
                <div className="d-flex gap-3 mt-4 justify-content-center">
                  <img src={IpfsStorage} alt="" className="home-icon" />
                  <h2 className="mt-3">Atom Storage</h2>
                </div>
                <div className="d-flex  gap-4 ">
                  <div className="d-flex flex-column mt-3 ">
                    <img src={box2} alt="" className="atomBox2ImgSty" />
                    <p className="box-title-heading">
                      {" "}
                      <b>Atom Standard</b>
                    </p>
                    <img src={box1} alt="" className="atomBox1ImgSty" />
                    <p className="box-title-heading">
                      {" "}
                      <b>Atom Mini</b>
                    </p>
                  </div>
                  <div className="align-content-center w-75">
                    <h6 className=" ">
                      Atom Storage is a decentralized platform that stores and
                      distributes large files across a network of nodes. Files
                      are identified by unique cryptographic hashes, ensuring
                      data integrity and redundancy. This content-addressable
                      system enables efficient global access and sharing of
                      information without central intermediaries, providing
                      resilience, censorship resistance, and optimized bandwidth
                      utilization.{" "}
                    </h6>
                  </div>
                </div>{" "}
              </>
            </div>
          </div>
          <div
            className="row p-2 justify-content-center  w-100"
            style={{ gap: "80px" }}
          >
            <div className="homecard mx-2">
              <>
                <div className="d-flex gap-3 mt-4">
                  <img src={AccessControl} alt="" className="home-icon" />
                  <h2 className="mt-3">Access Control</h2>
                </div>
                <h6 className="mt-3 ">
                  Atom Storage employs API keys as secure access tokens for
                  interacting with the decentralized file storage network. These
                  cryptographic keys authenticate and authorize users, granting
                  programmatic access while maintaining robust control and
                  monitoring capabilities. API keys fortify the security of your
                  data assets, ensuring only authorized entities can store,
                  retrieve, or manage files within the decentralized ecosystem.{" "}
                </h6>
              </>
            </div>
            <div className="homecard mx-2">
              <>
                <div className="d-flex gap-3 mt-4">
                  <img src={ApiKey} alt="" className="home-icon" />
                  <h2 className="mt-3">API Keys</h2>
                </div>
                <h6 className="mt-3 ">
                  API keys act as secure access credentials to the IPFS API,
                  granting authorized programmatic interaction with the network.
                  These cryptographic keys authenticate users and control access
                  to critical operations like file storage and retrieval. API
                  keys enhance security by preventing unauthorized usageresistance,
                  fortifying the robustness of the distributed architecture.{" "}
                </h6>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
