import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";

const sharewithmeslice = createSlice({
  name: "ShareWithMe",
  initialState: {
    shareWithMeData: [],
    FolderData: [],
    OwnerData: [],
    sharewithmeLoader: false,
  },
  reducers: {
    setShareWithMeData(state, action) {
      console.log("**********", action.payload);
      return { ...state, shareWithMeData: action.payload };
    },
    setShareWithMeFolderData(state, action) {
      //   return (state = action.payload);
      return { ...state, FolderData: action.payload };
    },
    setShareWithMeOwnerData(state, action) {
      //   return (state = action.payload);
      return { ...state, OwnerData: action.payload };
    },
    ShareWithMeLoaderData(state, action) {
      //   return (state = action.payload);
      return { ...state, sharewithmeLoader: action.payload };
    },
  },
});
const {
  setShareWithMeData,
  setShareWithMeFolderData,
  setShareWithMeOwnerData,
  ShareWithMeLoaderData,
} = sharewithmeslice.actions;
export default sharewithmeslice.reducer;

export function getsharewithmedata() {
  return async function getsharewithmedataThunk(dispatch, getstate) {
    try {
      dispatch(ShareWithMeLoaderData(true));
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/shared-files?&user_id=${sessionStorage.getItem(
          "userID"
        )}`;

      console.log("3", sessionStorage.getItem("userToken"));

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log(
            "shared_with_me_files_func aws***",
            response?.data?.fileData
          );
          // const sortedData = response?.data?.fileData.sort((a, b) => {
          //   // Assuming you want to sort by a property called "createdAt"
          //   return new Date(b.createdAt) - new Date(a.createdAt);
          // });
          const sortedData = response?.data?.fileData
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex((t) => t.file_unique_id === item.file_unique_id)
            );

          dispatch(setShareWithMeData(sortedData));
          dispatch(setShareWithMeFolderData(response?.data?.data));
          dispatch(setShareWithMeOwnerData(response?.data?.ownerData));
          // if (response?.data !== null) {
          //   let fileFinalData = [];
          //   for (let i = 0; i < (response?.data?.fileData).length; i++) {
          //     if (response?.data?.fileData[i] === null) {
          //       console.log(response?.data?.fileData[i]);
          //     } else {
          //       fileFinalData.push(response?.data?.fileData[i]);
          //     }
          //     console.log(fileFinalData);
          //   }
          //   dispatch(setShareWithMeData(fileFinalData));
          //   dispatch(setShareWithMeFolderData(response?.data?.data));
          //   // setData(fileFinalData);
          //   // setFolderData(response?.data?.data);
          //   let ownerFinalData = [];
          //   for (let i = 0; i < (response?.data?.ownerData).length; i++) {
          //     if (response?.data?.ownerData[i] === null) {
          //       console.log(response?.data?.ownerData[i]);
          //     } else {
          //       ownerFinalData.push(response?.data?.ownerData[i]);
          //     }
          //   }
          //   dispatch(setShareWithMeOwnerData(ownerFinalData));

          //   // setLoaderData(false);
          dispatch(ShareWithMeLoaderData(false));
          // }
        })
        .catch((err) => {
          dispatch(ShareWithMeLoaderData(false));
          console.log("shared_with_me_files_func err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
export function resetShareWithMeData() {
  return async function resetShareWithMeDataThunk(dispatch, getState) {
    try {
      dispatch(setShareWithMeData([]));
      dispatch(setShareWithMeFolderData([]));
      dispatch(setShareWithMeOwnerData([]));
      dispatch(ShareWithMeLoaderData(false));
    } catch (err) {
      console.log(err);
    }
  };
}
