import React, { useState, useEffect } from "react";
import "./TearmsAndPolicy.css";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "../../Components/GoToTop/GoToTop";
import { BsArrowLeft } from "react-icons/bs";

function Privacypolicy() {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mt-2 p-4">
        <button className="backbtn-service" onClick={() => navigate("/signup")}>
          <BsArrowLeft />
          Back
        </button>
      </div>
      <div className="container termsbox ">
        <div className="termsheadbox p-3">
          <h3 className="text-center mt-4">PRIVACY POLICY</h3>
          {/* <h5 className="">Effective Date: 08/24/2023</h5> */}
        </div>
        <p className="mt-4">
          This Privacy Policy governs the manner in which Atom Ventures Inc.
          Collects, uses, maintains and discloses information collected from
          users (each, a "User") of the
          https://www.atomcenter.io website ("Site"). This
          privacy policy applies to the Site and all products and services
          offered by Atom Ventures Inc.
        </p>
        <h5 className="mt-4">1. PERSONAL IDENTIFICATION INFORMATION</h5>
        <p>
          We may collect personal identification information from users in a
          variety of ways, including, but not limited to, when users visit our
          Site, registers on the Site, and in connection with other activities,
          services, features or resources we make available on our Site. Users
          may be asked for, as appropriate, name, email address, mailing
          address, phone number, date of birth or incorporation etc. Users may,
          however, visit our Site anonymously. We will collect personal
          identification information from Users only if they voluntarily submit
          such information to us. Users can always refuse to supply personally
          identification information, except that it may prevent them from
          engaging in certain Site related activities.
        </p>
        <h5>2. NON-PERSONAL IDENTIFICATION INFORMATION</h5>
        <p>
          We may collect NPII in various ways like users whenever they interact
          with our Site, including but not limited to:
        </p>
        <p>
          Non-personal identification information may include the browser name,
          the type of computer and technical information about Users means of
          connection to our Site, such as the operating system and the Internet
          service providers' utilized and other similar information.
        </p>
        <h5>3. WEB BROWSER COOKIES</h5>
        <p>
          Our Site may use "cookies" to enhance User experience. User's web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. User may choose to set
          their web browser to refuse cookies, or to alert you when cookies are
          being sent. If they do so, note that some parts of the Site may not
          function properly.
        </p>
        <h5>4. HOW WE USE COLLECTED INFORMATIO</h5>
        <p>
          Atom Ventures Inc may collect and use personal identification
          information ("PII") for the following purposes:
        </p>
        <div className="mt-3 px-4">
          <ol>
            <li className="privacyliststyle">
              To personalize user experience - We may use information in the
              aggregate to understand how our Users as a group use the services
              and resources provided on our Site.
            </li>
            <li className="privacyliststyle">
              To improve our Site - We may use feedback provided by user to
              improve our products and services.
            </li>
            <li className="privacyliststyle">
              To send periodic emails - We may use the email address to send
              user information and updates.
            </li>
            <li className="privacyliststyle">
              Customer Service: To respond to your customer service requests and
              support needs more efficiently.
            </li>
            <li className="privacyliststyle">
              Communication: To send you information and updates, respond to
              inquiries, and/or other requests or questions.
            </li>
            <li className="privacyliststyle">
              Improvement of Services: To improve our products and services
              based on your feedback and usage patterns.
            </li>
          </ol>
        </div>
        <h5>5. HOW WE PROTECT YOUR INFORMATION</h5>
        <p>
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information and data stored on our
          Site.
        </p>
        <p>
          Sensitive and private data exchange between the Site and its users
          happens over a SSL secured communication channel and is encrypted and
          protected with digital signatures. Our Site is also in compliance with
          PCI vulnerability standards in order to create as secure of an
          environment as possible for Users.
        </p>
        <h5>6. SHARING YOUR PERSONAL INFORMATION</h5>
        <p>
          Our website may be hosted by a third-party hosting provider that has
          access to your personal information for the purpose of server
          maintenance and troubleshooting.
        </p>
        <p>
          We may use third-party analytics services to collect and analyse
          information about the usage of our website.
        </p>
        <p>
          We may use third party service providers to help us operate our
          business and the Site or administer activities on our behalf. We may
          share your information with these third parties for those limited
          purposes provided you have given us your consent.
        </p>
        <p>
          You acknowledge, agree and authorise us to share your personal
          information with third party service providers who help us operate our
          or administer activities on our behalf. We may share your information
          with these third parties for those limited purposes.
        </p>
        <p>
          You acknowledge, authorise and agree to collect, store, process and
          transfer your information to our concern entities for the purpose of
          providing service through this / their platform. You further allow
          them to communicate with you in any manner including by way of emails.
          If you choose to use services provided by our sister concern, your
          information may be additionally governed by the privacy policies of
          such concern entity and we will not be responsible for the privacy
          practices of such concern entity.
        </p>
        <h5>7. MODIFICATION TO THE PRIVACY POLIC</h5>
        <p>
          Atom Ventures Inc reserve the right to update, modify, or change this
          Privacy Policy at any time. Any changes will be effective immediately
          upon posting the revised Privacy Policy on this page. We encourage
          users to frequently check this page for any changes to stay informed
          about how we are handling and protecting the personal information we
          collect. You acknowledge and agree that it is your responsibility to
          review this Privacy Policy periodically and become aware of
          modifications.
        </p>
        <h5>8. Data Retention</h5>
        <p>
          We will retain your personal information for as long as necessary to
          fulfil the purposes outlined in this Privacy Policy unless a longer
          retention period is required or permitted by law. Once your personal
          information is no longer necessary for the purposes for which it was
          collected, we will securely dispose of it in accordance with our data
          retention policies and applicable laws and regulations. If you have
          any questions about our data retention practices, please contact us at
          [Contact Information].
        </p>
        <h5>9. CONTACT US </h5>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <p>Atom Ventures Inc Delaware, USA</p>
        <p>
          Contact Info:{" "}
          <Link to="mailto:support@atomcenter.io ">support@atomcenter.io </Link>{" "}
        </p>
      </div>
      <br />
      <br />
      <GoToTop />
    </>
  );
}

export default Privacypolicy;
