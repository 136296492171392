import React, { useEffect, useState } from "react";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import F1Img from "../../utils/images/file.png";
import { GlobalConstants } from "../../utils/globalconstant";
import FolImg from "../../utils/images/folder (1).png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import FileImg from "../../utils/images/file_1.png";
import Loader3 from "../../utils/Files/data";
import { InfoToast } from "../../utils/Files/toasterMsgs";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function UploadMultipleBothStorage({
  props,
  folderIDIPFS,
  folderNameIPFS,
  bucketName,
  bucketId,
  folderIDaws,
  folderNameaws,
  bucketNameaws,
  bucketIdaws,
  FileData,
  FolderData,
  bucketACLOption,
  urlforIPFS,
  urlforaws,
  folderList,
}) {
  console.log(bucketACLOption);
  const navigate = useNavigate();
  const userID = sessionStorage.getItem("userID");

  //variable start//
  const [showCreateFolderInput, setShowCreateFolderInput] = useState(false);
  const [showCreateFolderInputaws, setShowCreateFolderInputaws] =
    useState(false);
  const [sfvErr, setSfvErr] = useState("");
  const [sfvErrIPFS, setSfvErrIPFS] = useState("");
  const [folderNameInputIPFS, setFolderNameInputIPFS] = useState("");
  const [allFolderIPFS, setAllFolderIPFS] = useState([]);
  const [existingFoldersIPFS, setExistingFoldersIPFS] = useState([]);
  const [ipfsBucketName, setIpfsBucketName] = useState("atom-storage");
  const [reqUrlCreate, setReqUrlCreate] = useState("");
  const [reqUrlCreateAWS, setReqUrlCreateAWS] = useState("");
  const [viewBackBtn, setViewBackBtn] = useState(false);
  const [viewBackBtnIPFS, setViewBackBtnIPFS] = useState(false);
  const [urlToShow, setUrlToShow] = useState("");
  const [urlToShowAWS, setUrlToShowAWS] = useState("");
  const [folderApiState, setFolderApiState] = useState([]);
  const [awsBucketName, setAwsBucketName] = useState("atom-storage");
  const [folderNameInputaws, setFolderNameInputaws] = useState("");
  const [fileFolderCommon, setFileFolderCommon] = useState();
  const [fileFolderCommonAWS, setFileFolderCommonAWS] = useState();
  const [d_rootFiles, set_d_rootFiles] = useState(FileData);
  const [d_rootFilesAWS, set_d_rootFilesAWS] = useState(FileData);
  const [loaderIPFS, setLoaderIPFS] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);
  const [loaderaws, setLoaderAWS] = useState(false);
  const [showFolderFileCommon, setShowfolderfilecommon] = useState(true);
  const [showbackforFile, setShowBackForFile] = useState(false);
  const [uploadFolder, setUploadFolder] = useState([]);
  const [showFolderFileCommonAWS, setShowfolderfilecommonAWS] = useState(true);
  const [showbackforFileAWS, setShowBackForFileAWS] = useState(false);
  const [uploadFolderAWS, setUploadFolderAWS] = useState([]);
  //variable end//

  //language variable
  const [text_currentlocation, setText_currentlocation] =
    useState("Current Location :");
  const [text_back, setText_back] = useState("Back");
  const [text_create, setText_create] = useState("Create");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_createnewfolder, setText_createnewfolder] =
    useState("Create New Folder");
  const [text_enterfoldername, setText_enterfoldername] =
    useState("Enter Folder Name");
  const [text_nofolder, setText_nofolder] = useState("No Folders Here");
  const [text_err_folder_name, setText_err_folder_name] = useState(
    "Folder name can not be empty"
  );
  const [text_validation_folder, set_validation_folder] = useState(
    "A folder name can't contain any of the following characters:"
  );
  //end language

  function truncate(string, n) {
    console.log(string.slice(string.length - 3));
    return string?.length > n
      ? string.substr(0, n - 1) + ". . . " + string.slice(string.length - 3)
      : string;
  }

  useEffect(() => {
    setFileFolderCommon(FolderData);
    setFileFolderCommonAWS(FolderData);
  }, [FolderData]);

  useEffect(() => {
    set_d_rootFiles(FileData);
    set_d_rootFilesAWS(FileData);
  }, [FileData]);

  useEffect(() => {
    if (folderList.length !== 0) {
      console.log(folderList);

      const dragdropFolderName = allFolderIPFS.map((v, i) => {
        const parts = v.folderName.split("/");
        return parts[parts.length - 2];
      });
      console.log(dragdropFolderName);
      const commonElements = folderList.filter((element) =>
        dragdropFolderName.includes(element)
      );
      console.log(commonElements);
      if (commonElements.length !== 0) {
        InfoToast("Folder Already Exists In Atom Storage");
      }
    }
  }, [folderList, allFolderIPFS]);
  //validation craete IPFS folder
  const createSubFolderValidationIPFS = () => {
    var sfv = true;
    const disallowedChars = /[#\\/:*?"<>|]/; // Regular expression to match disallowed characters
    if (folderNameInputIPFS === "") {
      sfv = false;
      setSfvErrIPFS(<>*{text_err_folder_name}!</>);
    } else if (disallowedChars.test(folderNameInputIPFS)) {
      // Check if folderNameInputIPFS contains disallowed characters
      sfv = false;
      setSfvErrIPFS(<>*{text_validation_folder + '#\\ / : * ? " < > |'} </>);
    } else {
      // checking whether a folder already exists or not from folderApiState
      for (let i = 0; i < allFolderIPFS?.length; i++) {
        existingFoldersIPFS.push(
          (allFolderIPFS[i]?.folderName).slice(
            allFolderIPFS[i]?.folderName.lastIndexOf(
              "/",
              allFolderIPFS[i]?.folderName.lastIndexOf("/") - 1
            ) + 1,
            allFolderIPFS[i]?.folderName.length - 1
          )
        );
      }
      for (let j = 0; j < existingFoldersIPFS?.length; j++) {
        if (
          existingFoldersIPFS[j]?.toLowerCase() ===
          folderNameInputIPFS.toLowerCase()
        ) {
          sfv = false;
          setSfvErrIPFS("Folder already exists!");
          break;
        }
      }
    }
    return sfv;
  };
  //Get IPFS Root folder//
  const getIPFSFolders = (Folder_name) => {
    setIpfsBucketName(Folder_name);
    // setLoaderIPFS(true);
    const Folder_final =
      Folder_name === "" || Folder_name === undefined || Folder_name === null
        ? "atom-storage/"
        : Folder_name + "/" + userID + "/";
    console.log(Folder_name);
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: Folder_final,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/all-child-folders-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_IPFS res***", res);
        setAllFolderIPFS(res?.data?.data);
        folderNameIPFS(Folder_final);
        setLoaderIPFS(false);
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  //validation craete AWS folder
  const createSubFolderValidation = () => {
    var sfv = true;
    const disallowedChars = /[#\\/:*?"<>|]/; // Regular expression to match disallowed characters
    if (folderNameInputaws === "") {
      sfv = false;
      setSfvErr(<>*{text_err_folder_name}!</>);
    } else if (disallowedChars.test(folderNameInputaws)) {
      // Check if folderNameInputaws contains disallowed characters
      sfv = false;
      setSfvErr(<>*{text_validation_folder + '#\\ / : * ? " < > |'} </>);
    } else {
      // checking whether a folder already exists or not from folderApiState
      for (let i = 0; i < folderApiState?.length; i++) {
        const str_aws = folderApiState[i]?.folderName;
        const ind_aws = str_aws.lastIndexOf("/");
        const new_str_aws = str_aws.slice(0, ind_aws);
        const ind_aws_data = new_str_aws.lastIndexOf("/");
        const new_str_new = new_str_aws.slice(ind_aws_data + 1);
        existingFolders.push(new_str_new);
      }
      for (let j = 0; j < existingFolders?.length; j++) {
        if (
          existingFolders[j]?.toLowerCase() === folderNameInputaws.toLowerCase()
        ) {
          sfv = false;
          setSfvErr("Folder already exists!");
          break;
        }
      }
    }
    return sfv;
  };
  //Get AWS Root folder
  const getAllSubDir = (Folder_name) => {
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName:
        Folder_name === "" || Folder_name === undefined || Folder_name === null
          ? "atom-storage/"
          : Folder_name,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/all-child-folders-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_IPFS res***", res);
        setAllFolderIPFS(res?.data?.data);
        if (Folder_name === `atom-storage/${userID}/`) {
          setViewBackBtnIPFS(false);
        } else {
          setViewBackBtnIPFS(true);
        }
        if (res?.data?.data.length > 0) {
          setReqUrlCreate(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? "atom-storage/"
              : Folder_name
          );
          addSpaceInUrlIPFS(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? "atom-storage"
              : Folder_name
          );
        } else {
          setReqUrlCreate(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? "atom-storage/"
              : Folder_name
          );
          addSpaceInUrlIPFS(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? "atom-storage/"
              : Folder_name
          );
        }
        addSpaceInUrlIPFS(
          Folder_name === "" ||
            Folder_name === undefined ||
            Folder_name === null
            ? "atom-storage/"
            : Folder_name
        );
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  //Craete IPFS Child folder//
  const handleCreateSubFolderIPFS = (folderPath) => {
    var b_name = "";
    reqUrlCreate === ""
      ? (b_name = "atom-storage" + "/" + userID + "/" + folderPath)
      : (b_name = folderPath);
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: b_name,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/add-folder-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_IPFS res***", res);
        getAllSubDir(
          b_name.slice(
            0,
            b_name.lastIndexOf("/", b_name.lastIndexOf("/") - 1) + 1
          )
        );
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  //Craete AWS Child folder//

  const handleCreateSubFolderaws = () => {
    let Folder_name = folderNameInputaws.trim();
    const ind_aws = reqUrlCreateAWS.indexOf("/");
    const New_key = reqUrlCreateAWS.slice(ind_aws + 1);
    if (createSubFolderValidation()) {
      setFolderNameInputaws("");
      setShowCreateFolderInputaws(false);

      getAllSubDir_new(
        reqUrlCreateAWS === "" || reqUrlCreateAWS === null
          ? `${awsBucketName}/${userID}/`
          : reqUrlCreateAWS
      );
      const folderPath = New_key + Folder_name + "/";
      add_folder_id_func(folderPath);
    }
  };
  const add_folder_id_func = (folderPath) => {
    console.log(
      "folderPath*********************************************",
      folderPath
    );

    var folderName = "";

    reqUrlCreateAWS === ""
      ? (folderName = awsBucketName + "/" + userID + "/" + folderPath)
      : (folderName = awsBucketName + "/" + folderPath);

    var data = {
      companyId: sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: folderName,
      bucketId: bucketIdaws,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/add-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_id_func res***", res);
        setFolderNameInputaws("");
        getAllSubDir_new(
          reqUrlCreateAWS === "" || reqUrlCreateAWS === null
            ? `${awsBucketName}/${userID}/`
            : reqUrlCreateAWS
        );
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  console.log(reqUrlCreate);
  //Craete IPFS folder//
  const handleCreateFolderIPFS = () => {
    let Folder_nameIPFS = folderNameInputIPFS.trim();
    if (createSubFolderValidationIPFS()) {
      setFolderNameInputIPFS("");
      setShowCreateFolderInput(false);

      const folderPath = reqUrlCreate + Folder_nameIPFS + "/";
      handleCreateSubFolderIPFS(folderPath);
    }
  };
  console.log(reqUrlCreate);

  console.log(folderApiState);
  console.log(allFolderIPFS);
  //Craete AWS root folder//

  const getAllDir_new = (bucketID, bucketname) => {
    // setLoaderAWS(true);
    setAwsBucketName(bucketname);
    console.log(reqUrlCreateAWS);
    const str_aws = reqUrlCreate;
    const ind_aws = str_aws.indexOf("/");
    const new_str_aws = str_aws.slice(ind_aws + 1);
    console.log(new_str_aws);

    var folder_name = `${bucketname}/${userID}/`;
    var data = {
      companyId: sessionStorage.getItem("companyID"),
      userId: userID,
      folderName: folder_name,
      bucketId: bucketID,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_id_func res***", res);
        var Data = res?.data?.data;
        if (res?.status === 200) {
          setLoaderAWS(false);
          setFolderApiState(Data);
        }
      })
      .catch((err) => {
        setLoaderAWS(false);
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  const getAllSubDir_new = (folderName) => {
    console.log(folderName);
    const str_aws = folderName;
    const ind_aws = str_aws.indexOf("/");
    const new_str_aws = str_aws.slice(ind_aws + 1);

    var data = {
      companyId: sessionStorage.getItem("companyID"),
      userId: userID,
      folderName: folderName,
      bucketId: bucketIdaws,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_id_func res***", res);
        var Data = res?.data?.data;
        if (res?.status === 200) {
          setFolderApiState(Data);
          setReqUrlCreateAWS(folderName);
          addSpaceInUrlaws(new_str_aws);
        }
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ///root folder get from aws
  const handleRootFolder = (bucket_id, bucket_name) => {
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: bucket_name + "/" + userID + "/",
      bucketId: bucket_id,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        var DataFinal = res?.data?.data;
        console.log("res***", res?.data);
        if (res?.data?.root_folder_added === true) {
          getIPFSFolders(bucket_name);
          folderIDIPFS(DataFinal.folderId);
          folderNameIPFS(DataFinal.folderName);
          bucketName("atom-storage");
          bucketId("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getIPFSFolders(bucket_name); // atom-storage/123456/
          folderIDIPFS(DataFinal.folderId);
          bucketName("atom-storage");
          folderNameIPFS(DataFinal.folderName);
          bucketId("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  useEffect(() => {
    handleRootFolder("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55", ipfsBucketName);
  }, [ipfsBucketName]);
  ///root folder get from IPFS

  const handleRootFolderaws = (bucket_id, bucket_name) => {
    console.log("Root folder");
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: bucket_name + "/" + userID + "/",
      bucketId: bucket_id,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        var DataFinal = res?.data?.data;
        console.log("res***", res);
        if (res?.data?.root_folder_added === true) {
          getAllDir_new(bucket_id, bucket_name);
          folderIDaws(DataFinal.folderId);
        } else {
          console.log("new");
          getAllDir_new(bucket_id, bucket_name);
          folderIDaws(DataFinal.folderId);
        }
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  useEffect(() => {
    if (bucketIdaws !== "") {
      handleRootFolderaws(bucketIdaws, bucketNameaws);
    }
  }, [bucketNameaws]);
  //currunt location for aws
  const addSpaceInUrlaws = (url) => {
    var newUrl = url.split("/");
    console.log(url);
    const urlWithImages = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "}{" "}
            <img src={F1Img} alt="" className="folder-img" /> {folder}
            {console.log(folder)}
          </>
        )}
      </span>
    ));
    const url_aws = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "} {folder}
            {console.log(folder)}
          </>
        )}
      </span>
    ));
    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
      url_aws.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShowAWS(urlWithImages);
    urlforaws(url_aws);
    console.log(newUrl);
  };
  //currunt location for ipfs

  const addSpaceInUrlIPFS = (url) => {
    var newUrl = url.split("/");
    console.log(newUrl.length > 2);
    const urlWithImages = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "}{" "}
            <img src={F1Img} alt="" style={{ height: "22px", width: "22px" }} />{" "}
            {folder}
          </>
        )}
      </span>
    ));
    const url_ipfs = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "} {folder}
          </>
        )}
      </span>
    ));
    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
      url_ipfs.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShow(urlWithImages);
    urlforIPFS(url_ipfs);
  };
  //back for IPFS
  const handleBackIPFS = () => {
    setSfvErrIPFS("");
    setShowCreateFolderInput(false);
    if (
      reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) !== -1
    ) {
      if (
        reqUrlCreate.slice(
          0,
          reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
        ) === `atom-storage/${userID}/`
      ) {
        setViewBackBtnIPFS(false);
        getAllSubDir(`atom-storage/${userID}/`);
        folderNameIPFS(`atom-storage/${userID}/`);
      } else {
        getAllSubDir(
          reqUrlCreate.slice(
            0,
            reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
          )
        );
      }
      console.log(
        reqUrlCreate.slice(
          0,
          reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
        )
      );
    } else {
      getAllSubDir("");
      setViewBackBtnIPFS(false);
    }
  };
  //buket button for  AWS///
  const handleBack = () => {
    let handleBackArrAWS = reqUrlCreateAWS.split("/");
    const str_aws = reqUrlCreateAWS;
    const ind_aws = str_aws.lastIndexOf("/");
    const new_str_aws = str_aws.slice(0, ind_aws);
    const str_data = new_str_aws.lastIndexOf("/");
    const back_path = new_str_aws.slice(0, str_data + 1);
    console.log("handleBackArrAWS***", handleBackArrAWS);
    setSfvErr("");
    setShowCreateFolderInputaws(false);
    if (handleBackArrAWS.length > 4) {
      getAllSubDir_new(back_path);
    } else {
      getAllSubDir_new(`${awsBucketName}/${userID}/`);
      setViewBackBtn(false);
    }
  };
  useEffect(() => {
    folderNameaws(reqUrlCreateAWS);
  }, [reqUrlCreateAWS]);

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_currentlocation(
        xpath.select1("/resources/string[@name='text_currentlocation']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_create(
        xpath.select1("/resources/string[@name='text_create']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_createnewfolder(
        xpath.select1("/resources/string[@name='text_createnewfolder']", doc)
          .firstChild.data
      );
      setText_nofolder(
        xpath.select1("/resources/string[@name='text_nofolder']", doc)
          .firstChild.data
      );
      setText_enterfoldername(
        xpath.select1("/resources/string[@name='text_enterfoldername']", doc)
          .firstChild.data
      );
      setText_err_folder_name(
        xpath.select1("/resources/string[@name='text_err_folder_name']", doc)
          .firstChild.data
      );
      set_validation_folder(
        xpath.select1("/resources/string[@name='text_validation_folder']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  useEffect(() => {
    setLoaderAWS(true);
    setLoaderIPFS(true);
  }, []);
  return (
    <>
      <div className="d-flex gap-5 both-storage-container">
        <div className="uf-folders-main both-storage multiple-storage-container">
          {reqUrlCreateAWS !== "" ? (
            <div className="uf-url-both ">
              <span className="uf-url-s1">
                <b>{text_currentlocation}</b>
              </span>
              <span className="uf-url-s2">
                <img src={F1Img} alt="" className="folder-img" />
                {awsBucketName === "atom-storage"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Public) >`
                  : awsBucketName === "atom-storage-private"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Private)`
                  : awsBucketName + " " + "(" + bucketACLOption + ")" + ">"}
                {urlToShowAWS}
              </span>
            </div>
          ) : (
            <div className="uf-url-both">
              <span className="uf-url-s1 ">
                <b>{text_currentlocation}</b>
              </span>
              <span className="uf-url-s2">
                <img src={F1Img} alt="" className="folder-img" />
                {awsBucketName === "atom-storage"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Public) >`
                  : awsBucketName === "atom-storage-private"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Private)`
                  : awsBucketName + " " + "(" + bucketACLOption + ")" + ">"}
                {urlToShowAWS}
              </span>
            </div>
          )}
          <div
            className={
              viewBackBtn === true || showbackforFileAWS === true
                ? "uf-folders-back-create"
                : "uf-folders-notback-create"
            }
          >
            {viewBackBtn === true && (
              <div className="uf-folders-back">
                <button
                  className="back-btn btn-both-storage back-btn-both-storage"
                  onClick={handleBack}
                >
                  <span>
                    <MdOutlineKeyboardBackspace className="back-btn-icon" />
                  </span>
                  <span>{text_back}</span>
                </button>
              </div>
            )}

            <div className="uf-folders-create">
              {showCreateFolderInputaws === true ? (
                <div className="uf-folders-in f-input btn-both-storage">
                  {/* -----------  */}
                  <div className="uf-inputs-main ">
                    <div className="inputForm uf-inputs mt-1">
                      <img src={FolImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input folder-name-input"
                        onChange={(e) => [
                          setFolderNameInputaws(e.target.value),
                          setSfvErr(""),
                        ]}
                        placeholder={text_enterfoldername}
                        value={folderNameInputaws}
                      />
                    </div>
                  </div>
                  {/* ---------- */}
                  <button
                    className="box-btn-save "
                    onClick={handleCreateSubFolderaws}
                  >
                    {text_create}
                  </button>
                  <button
                    onClick={() => [
                      setShowCreateFolderInputaws(false),
                      setSfvErr(""),
                      setFolderNameInputaws(""),
                    ]}
                    className="box-btn "
                  >
                    {text_cancel}
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateFolderInputaws(true)}
                  className="create-btn btn-both-storage"
                >
                  {text_createnewfolder}
                </button>
              )}
              <p className="sfv-err-both">{sfvErr}</p>
            </div>
          </div>
          <div className="uf-folders-outer both-upload-outer">
            <div className="uf-folders">
              {uploadFolderAWS.length === 0 &&
                folderApiState?.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className=" text-center uf-folder-both"
                      onClick={() => [
                        getAllSubDir_new(v?.folderName),
                        folderIDaws(v?.folderId),
                        setSfvErr(""),
                        setShowCreateFolderInputaws(false),
                        setTimeout(() => {
                          setViewBackBtn(true);
                        }, 1000),
                      ]}
                      title={v?.folderName.slice(
                        v?.folderName.lastIndexOf(
                          "/",
                          v?.folderName.lastIndexOf("/") - 1
                        ) + 1,
                        v?.folderName?.length - 1
                      )}
                    >
                      <img src={F1Img} alt="" className="uf-folder-img" />
                      <p className="uf-folder-name">
                        {v?.folderName.slice(
                          v?.folderName.lastIndexOf(
                            "/",
                            v?.folderName.lastIndexOf("/") - 1
                          ) + 1,
                          v?.folderName?.length - 1
                        )}
                      </p>
                    </div>
                  );
                })}
              {folderApiState?.length === 0 &&
              fileFolderCommonAWS?.length === 0 &&
              d_rootFilesAWS.length === 0 &&
              uploadFolderAWS.length === 0 ? (
                <>
                  {loaderaws === true ? (
                    <div className="uf-emptyfolder">
                      <Loader3 />
                    </div>
                  ) : (
                    <div className="uf-emptyfolder">
                      <h3 className="as">{text_nofolder}</h3>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {showFolderFileCommonAWS === true ? (
                <>
                  {fileFolderCommonAWS?.map((v, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="text-center folder-sty"
                          onClick={() => [
                            setUploadFolderAWS(v?.files),
                            setShowfolderfilecommonAWS(false),
                            setViewBackBtn(false),
                            setShowBackForFileAWS(true),
                          ]}
                          title={v?.folderName}
                        >
                          <img src={F1Img} alt="" className="uf-folder-img" />
                          <p className="uf-folder-name">
                            {truncate(v?.folderName, 4)}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {uploadFolderAWS.length > 0 &&
                    uploadFolderAWS.map((e, i) => {
                      return (
                        <div key={i} className="text-center folder-sty">
                          <img src={FileImg} alt="" className="uf-folder-img" />
                          <p className="uf-folder-name">{e}</p>
                        </div>
                      );
                    })}
                </>
              )}
              {d_rootFilesAWS.length > 0 &&
                d_rootFilesAWS.map((e, i) => {
                  return (
                    <div key={i} className="text-center folder-sty" title={e}>
                      <img src={FileImg} alt="" className="uf-folder-img" />
                      <p className="uf-folder-name"> {truncate(e, 5)} </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <hr className="hr-to-vr" />
        <div className="uf-folders-main both-storage multiple-storage-container">
          {reqUrlCreate === "" && reqUrlCreate !== "atom-storage" ? (
            <div className="uf-url-both">
              <span className="uf-url-s1">
                <b>{text_currentlocation}</b>
              </span>
              <span className="uf-url-s2">
                <img src={F1Img} alt="" className="folder-img" />
                {"atom-storage > "}
                {urlToShow}
              </span>
            </div>
          ) : (
            <div className="uf-url-both">
              <span className="uf-url-s1">
                <b>{text_currentlocation}</b>
              </span>
              <span className="uf-url-s2">{urlToShow}</span>
            </div>
          )}
          <div
            className={
              viewBackBtnIPFS === true || showbackforFile === true
                ? "uf-folders-back-create"
                : "uf-folders-notback-create"
            }
          >
            {viewBackBtnIPFS === true && (
              <div className="uf-folders-back ">
                <button
                  className="back-btn btn-both-storage back-btn-both-storage"
                  onClick={handleBackIPFS}
                >
                  <span>
                    <MdOutlineKeyboardBackspace className="back-btn-icon" />
                  </span>
                  <span>{text_back}</span>
                </button>
              </div>
            )}
            {/***create folder****/}
            <div className="uf-folders-create">
              {showCreateFolderInput === true ? (
                <div className="uf-folders-in f-input btn-both-storage">
                  {/* -----------  */}
                  <div className="uf-inputs-main">
                    <div className="inputForm uf-inputs mt-1">
                      <img src={FolImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input folder-name-input"
                        onChange={(e) => [
                          setFolderNameInputIPFS(e.target.value),
                          setSfvErrIPFS(""),
                        ]}
                        placeholder={text_enterfoldername}
                        value={folderNameInputIPFS}
                      />
                    </div>
                  </div>
                  {/* ---------- */}
                  <button
                    className="box-btn-save"
                    onClick={handleCreateFolderIPFS}
                  >
                    {text_create}
                  </button>
                  <button
                    onClick={() => [
                      setShowCreateFolderInput(false),
                      setSfvErrIPFS(""),
                      setFolderNameInputIPFS(""),
                    ]}
                    className="box-btn"
                  >
                    {text_cancel}
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateFolderInput(true)}
                  className="create-btn btn-both-storage"
                >
                  {text_createnewfolder}
                </button>
              )}
              <p className="sfv-err-both">{sfvErrIPFS}</p>
            </div>
          </div>
          {/* ------------------------------------- */}
          <div className="uf-folders-outer both-upload-outer">
            {/*** Folder Show *****/}
            <div className="uf-folders">
              {uploadFolder.length === 0 &&
                allFolderIPFS?.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className="text-center uf-folder-both"
                      onClick={() => [
                        getAllSubDir(v?.folderName),
                        setTimeout(() => {
                          setViewBackBtnIPFS(true);
                        }, 1000),
                        setSfvErrIPFS(""),
                        setShowCreateFolderInput(false),
                        folderIDIPFS(v?.folderId),
                        folderNameIPFS(v?.folderName),
                        bucketName("atom-storage"),
                      ]}
                      title={v?.folderName.slice(
                        v?.folderName.lastIndexOf(
                          "/",
                          v?.folderName.lastIndexOf("/") - 1
                        ) + 1,
                        v?.folderName?.length - 1
                      )}
                    >
                      <img src={F1Img} alt="" className="uf-folder-img" />
                      <p className="uf-folder-name">
                        {v?.folderName.slice(
                          v?.folderName.lastIndexOf(
                            "/",
                            v?.folderName.lastIndexOf("/") - 1
                          ) + 1,
                          v?.folderName?.length - 1
                        )}
                      </p>
                    </div>
                  );
                })}
              {allFolderIPFS?.length === 0 &&
              fileFolderCommon?.length === 0 &&
              uploadFolder.length === 0 &&
              d_rootFiles.length === 0 ? (
                <>
                  {loaderIPFS === true ? (
                    <div className="uf-emptyfolder">
                      <Loader3 />
                    </div>
                  ) : (
                    <div className="uf-emptyfolder">
                      <h3 className="as">{text_nofolder}</h3>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* ------------------------------------- */}
        </div>
      </div>
    </>
  );
}

export default UploadMultipleBothStorage;
