import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ProgressBar } from "react-bootstrap";
import FileImg from "../../utils/images/file_1.png";
import { IoReload } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import Loader2 from "../Loader2/Loader2";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import {
  fetchSharedByMeFiles,
  fetchSharedByMeFolders,
} from "../../redux/SharedByMeSlice";
import { useNavigate } from "react-router-dom";
import {
  cancel_single_upload_chunks,
  cancleMultipleUpload,
  cancleSingleUpload,
  resetUploadData,
  resetUploadInfo,
} from "../../redux/UploadSlice";
import {
  resetUploadMetaData,
  cancel_multi_upload_metadata,
  cancel_multi_not_started,
  cancel_single_upload_metadata,
  hideProgressBox,
} from "../../redux/MetaDataSlice";
import {
  foderwiseAwsFiless,
  getAwsRootData,
} from "../../redux/AwsRootFolderSlice";
import {
  getIPFSFileData,
  getIPFSRootData,
} from "../../redux/IPFSRootFolderSlice";
import { getBothFileData } from "../../redux/BothFilesSlice";
import { cancel_multi_upload_chunks } from "../../redux/UploadSlice";
import { GlobalConstants } from "../../utils/globalconstant";
import { resetCancelStateFunc } from "../../redux/CancelSlice";
import { v4 as uuidv4 } from "uuid";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ProgressBoxWIthRedux() {
  // const navigate=useNavigate()
  const dispatch = useDispatch();

  const showpopup = useSelector((state) => state.singleUploadData.AccessPopup);
  const cancelState = useSelector((state) => state.cancel); // [ind, status, filenamewithuuid, abortcontroller, isAborted]
  const singleMetaData = useSelector((state) => state.metadata.data);
  // const uploadFrom = useSelector((state) => state.metaData.uploadFrom);

  const multiMetadata = useSelector((state) => state.metadata.multiMetadata);
  const metaDataIndex = useSelector((state) => state.metadata.metaDataIndex);

  console.log("multiMetadata:", multiMetadata);
  const multi_pro = useSelector((state) => state.metadata.multi_pro);
  const newFolderData = useSelector((state) => state.metadata.newFolderData);

  const uploadLocation = useSelector((state) => state.metadata.uploadLocation);
  const abortController = useSelector(
    (state) => state.metadata.abortController
  );
  const multipleabortController = useSelector(
    (state) => state.singleUploadData.MultipleAbortControllers
  );
  const other_data_2 = useSelector((state) => state.metadata.other_data_2);
  const other_data_2_ipfs = useSelector(
    (state) => state.metadata.other_data_2_ipfs
  );
  const other_data_invite = useSelector(
    (state) => state.metadata.other_data_invite
  );
  const other_data_invite_ipfs = useSelector(
    (state) => state.metadata.other_data_invite_ipfs
  );

  const folderArr_ipfs = useSelector((state) => state.metadata.folderArr_ipfs);
  const folderArr = useSelector((state) => state.metadata.folderArr);
  const MultiFileandFolderIDAWS = useSelector(
    (state) => state.metadata.MultiFileandFolderIDAWS
  );
  const MultiFileandFolderID = useSelector(
    (state) => state.metadata.MultiFileandFolderID
  );
  const allFileDataMulti = useSelector(
    (state) => state.metadata.allFileDataMulti
  );
  const FileUploadCancel = useSelector(
    (state) => state.metadata.FileUploadCancel
  );
  const allFileData = useSelector((state) => state.metadata.allFileData);
  const unique_fileName = useSelector(
    (state) => state.singleUploadData.unique_fileName
  );

  const progressBox = useSelector((state) => state.metadata.ProgressBox);
  const file_unique_id = useSelector(
    (state) => state.singleUploadData.file_unique_id
  );
  const signleFileData = useSelector((state) => state.metadata.singleFiledata);
  const FileUploadCancelAtom = useSelector(
    (state) => state.singleUploadData.FileUploadCancelAtom
  );
  const uploadstatus = useSelector(
    (state) => state.singleUploadData.singleFileData
  );
  const retryIcon = useSelector((state) => state.singleUploadData.retryIcon);
  const retryFile = useSelector((state) => state.singleUploadData.retryFile);

  const retryError = useSelector((state) => state.singleUploadData.retryError);

  const uploadProgress = useSelector(
    (state) => state.singleUploadData.uploadprogress
  );
  const uploadFrom = useSelector((state) => state.metadata.uploadFrom);
  const closeProgressBox = useSelector(
    (state) => state.singleUploadData.closeProgressBox
  );
  const File_id_multi = useSelector(
    (state) => state.singleUploadData.File_id_multi
  );
  const multipledata = useSelector((state) => state.metadata.multipledata);
  const FolderIdSingleIpfs = useSelector(
    (state) => state.singleUploadData.FolderIdSingleIpfs
  );
  const FolderIdSingleAws = useSelector(
    (state) => state.singleUploadData.FolderIdSingleAws
  );
  const cancelfilescount = useSelector(
    (state) => state.cancel.overallData?.abortedInd
  );

  console.log(uploadProgress, multipledata, cancelfilescount);
  const [accesspopup, setAccesspopup] = useState(false);
  // const [retryIcon, setRetryIcon] = useState(false);

  // const [closeProgressBox, setCloseProgressBox] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelIndex, setCancelIndex] = useState([]);
  const [showProgressBox, setshowProgressbox] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [emailexits, setEmailexits] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [shareFileLoader, setShareFileLoader] = useState(false);
  const [userData, setUserData] = useState([]);
  const [readAccess, setReadAccess] = useState(true);
  const [writeAccess, setWriteAccess] = useState(true);
  const [downloadAccess, setDownloadAccess] = useState(true);
  const [uploadLocAws, setUploadLocAws] = useState(false);
  const [uploadLocIpfs, setUploadLocIpfs] = useState(false);
  const [clickedInd, setClickedInd] = useState([]);
  const [cancelIndexNumber, setCancelIndexNumber] = useState(null);
  // const [uploadFolder, setUploadFolder] = useState(false);
  // const [uploadFile, setUploadFile] = useState(false);
  const uploadFolder = useSelector(
    (state) => state.singleUploadData.uploadFolder
  );
  const uploadFile = useSelector((state) => state.singleUploadData.uploadFile);

  const userID = sessionStorage.getItem("userID");
  const CompanyID = sessionStorage.getItem("companyID");
  const useremail = sessionStorage.getItem("email");
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  var UserF_Name = sessionStorage.getItem("fName");
  var UserL_name = sessionStorage.getItem("lName");

  //language start
  const [text_selectaccesstype, setText_selectaccesstype] =
    useState("Select Access Type");
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_download, setText_download] = useState("Download");
  const [text_enteruseremail, setText_enteruseremail] =
    useState("Enter User Email");
  const [text_skip, setText_skip] = useState("Skip");
  const [text_share, setText_share] = useState("Share");
  const [text_valemail, setText_valemail] = useState("*Enter valid email Id!");
  const [text_alemail, setText_alemail] = useState(
    "*Email has already been added!"
  );
  const [text_noticeemail, settext_noticeemail] = useState(
    "*You cannot give access to more than 50 emails at once!"
  );
  const [text_Own_email_err, setText_Own_email_err] = useState(
    "You can not give access to your own email"
  );
  const [text_validationemail, setText_validationemail] = useState(
    "Please Enter User Email!"
  );
  const [text_Uploding, setText_Uploding] = useState("Uploding");
  const [text_item, setText_item] = useState("Item");
  const [text_uploadCancel, setText_uploadCancel] = useState("Upload canceled");
  const [text_err_cancel, setText_err_cancel] = useState(
    "Error in canceling upload"
  );

  useEffect(() => {
    if (progressBox) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        setTimeout(() => {
          if (uploadFrom === "single") {
            checkUploadStatus_single(0);
          } else {
            checkUploadStatus(metaDataIndex);
          }
        }, 1000);
        return "";
      };
      console.log("Adding beforeunload event listener");
      window.addEventListener("beforeunload", unloadCallback);

      return () => {
        console.log("Removing beforeunload event listener");
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
  }, [progressBox, metaDataIndex]);

  useEffect(() => {
    console.log(uploadLocation);
    if (uploadLocation === "aws" || uploadLocation === "both") {
      setUploadLocAws(true);
    } else if (uploadLocation === "ipfs" || uploadLocation === "both") {
      setUploadLocIpfs(true);
    }
  }, [uploadLocation]);

  useEffect(() => {
    setAccesspopup(showpopup);
    setshowProgressbox(progressBox);
  }, [progressBox]);
  console.log(showProgressBox);
  const closeAccessPopup = () => {
    setAccesspopup(false);
    setUploadLocAws(false);
    setUploadLocIpfs(false);
    if (uploadLocation === "aws" || uploadLocation === "both") {
      sessionStorage.setItem("uploadLocation", uploadLocation);
    }
    if (uploadFrom === "multiple") {
      console.log(multipledata);
      if (uploadLocation === "aws") {
        dispatch(foderwiseAwsFiless(multipledata?.bucketId));
        dispatch(
          getAwsRootData(multipledata?.bucketId, multipledata?.awsBucketName)
        );
      } else if (uploadLocation === "ipfs") {
        dispatch(
          getIPFSFileData(
            `atom-storage/${userID}/`,
            "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          )
        );
        dispatch(getIPFSRootData(`atom-storage/${userID}/`));
      } else if (uploadLocation === "both") {
        dispatch(foderwiseAwsFiless(multipledata?.bucketId));
        dispatch(
          getAwsRootData(multipledata?.bucketId, multipledata?.awsBucketName)
        );
        dispatch(
          getIPFSFileData(
            `atom-storage/${userID}/`,
            "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          )
        );
        dispatch(getIPFSRootData(`atom-storage/${userID}/`));
        dispatch(getBothFileData());
      }
    } else {
      if (uploadLocation === "both") {
        dispatch(getBothFileData());
      }
    }
    setTimeout(function () {
      window.history.pushState(null, "", "/my-files");
      const popStateEvent = new PopStateEvent("popstate", { state: null });
      dispatchEvent(popStateEvent);
      dispatch(resetUploadMetaData());
      dispatch(resetUploadData());
      dispatch(resetUploadInfo());
      dispatch(resetCancelStateFunc());
    }, 1000);

    setEmailexits("");
    setEmailData([]);
    setDownloadAccess(true);
    setWriteAccess(true);
    setDisplaySuggestions(false);
    setReadAccess(true);
    setErrEmail("");
    setFilteredSuggestions([]);
  };
  const closeBoxOnCancel = () => {
    window.history.pushState(null, "", "/my-files");
    const popStateEvent = new PopStateEvent("popstate", { state: null });
    dispatchEvent(popStateEvent);
    dispatch(resetUploadMetaData());
    dispatch(resetUploadData());
    dispatch(resetUploadInfo());
    dispatch(hideProgressBox());
    dispatch(resetCancelStateFunc());
  };
  //****accesspopup remove email from array list */
  const handleDelete = (participants1) => {
    setEmailData(emailData.filter((item, i) => i !== participants1));
  };
  //****accesspopup validation */
  function isInList(value) {
    return emailData.includes(value);
  }
  function isValid() {
    if (!EMAIL_REGEX.test(emailexits)) {
      setErrEmail(<>{text_valemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (isInList(emailexits)) {
      setErrEmail(<>{text_alemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailData.length > 51) {
      setErrEmail(<>{text_noticeemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailexits == useremail) {
      setErrEmail(<>*{text_Own_email_err}!</>);
      return false;
    } else {
      setErrEmail("");
    }

    return true;
  }
  //****push enter email in array*/
  const handleKeyDown = (e) => {
    console.log(e);
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);

        setEmailexits("");
      }
    }
  };
  //Access popup email handler
  const EmailHandler = async (event) => {
    const value = event.target.value;
    console.log(value);
    setEmailexits(value.toLowerCase());
    setErrEmail("");
    if (value == useremail) {
      isValid();
    }
    if (value === "") {
      setFilteredSuggestions([]);
      setDisplaySuggestions(false);
      return;
    }
    const filteredSuggestions = userData.filter(
      (suggestion, i) =>
        suggestion.userEmail.toLowerCase().includes(value.toLowerCase()) &&
        suggestion.userEmail.toLowerCase() !== useremail
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };
  //****accesspopup Suggestion email push on email array  */
  const onSelectSuggestionKey = (e, index) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      console.log(index);
    }
  };
  //****accesspopup Suggestion email push on email array */
  const onSelectSuggestion = (index) => {
    console.log(index);

    setEmailData([...emailData, filteredSuggestions[index].userEmail]);
    setEmailexits("");

    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  ///****Access select Option****/
  const AccessValueHandle = (val) => {
    if (val === "read") {
      setReadAccess(true);
      setWriteAccess(false);
      setDownloadAccess(false);
    } else if (val === "write") {
      setWriteAccess(true);
      setReadAccess(true);
      setDownloadAccess(false);
    } else if (val === "download") {
      setDownloadAccess(true);
      setWriteAccess(true);
      setReadAccess(true);
    }
  };
  const handleSingleUser = () => {
    if (emailexits === "") {
      setErrEmail(<>*{text_validationemail}</>);
    } else {
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        handleGetUser(value);
      }
    }
  };
  const alreadyemail = emailData.filter(
    (item, index) => emailData.indexOf(item) !== index
  );
  //*** user is register or Not register *****/
  const handleGetUser = (value) => {
    if (alreadyemail.length > 0) {
      setErrEmail(<>{text_alemail}</>);
    } else {
      var FinalEmail = [];
      if (emailData.length === 0) {
        FinalEmail.push({ email: [value] }); //if one email added but not added in email array than by default push email in array
      } else {
        FinalEmail.push({ email: emailData });
      }
      if (FinalEmail.length === 0) {
        setErrEmail(<>*{text_validationemail}</>);
      } else {
        console.log(FinalEmail);
        if (uploadLocation === "aws" || uploadLocation === "both") {
          sessionStorage.setItem("uploadLocation", uploadLocation);
        }
        // for (let i = 0; FinalEmail[0].email.length > i; i++) {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        // var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/emailexist`;
        // var data = {
        //   EmailOrPhone: FinalEmail[0].email[i].toLowerCase(),
        // };
        var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/email-exists`;
        var data = {
          emails: FinalEmail[0].email,
        };
        axios
          .post(API_URL, data, headerConfig)
          .then((res) => {
            // var val = res?.data?.data;
            if (res?.status === 200) {
              ////***** Conditions Give Access for multiple file upload [register user]*******/
              if (res?.data.data.found.length > 0) {
                for (let i = 0; res?.data.data.found.length > i; i++) {
                  if (uploadFrom === "multiple") {
                    if (uploadLocation === "ipfs" && uploadFile === true) {
                      GiveAccessToUserMultiIPFS(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "ipfs" && uploadFolder === true) {
                      GiveAccessToUserMultiIPFSFolder(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "aws" && uploadFile === true) {
                      GiveAccessToUserMultiAWS(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "aws" && uploadFolder === true) {
                      GiveAccessToUserMultiAWSFolder(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "both") {
                      if (uploadFile === true) {
                        GiveAccessToUserMultiIPFS(
                          res?.data.data.found[i].email,
                          res?.data.data.found[i].userId
                        );
                        GiveAccessToUserMultiAWS(
                          res?.data.data.found[i].email,
                          res?.data.data.found[i].userId
                        );
                      }
                      if (uploadFolder === true) {
                        GiveAccessToUserMultiAWSFolder(
                          res?.data.data.found[i].email,
                          res?.data.data.found[i].userId
                        );
                        GiveAccessToUserMultiIPFSFolder(
                          res?.data.data.found[i].email,
                          res?.data.data.found[i].userId
                        );
                      }
                    }
                  } else {
                    if (uploadLocation === "ipfs") {
                      GiveAccessToUser(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "aws") {
                      GiveAccessToUserAws(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                    if (uploadLocation === "both") {
                      GiveAccessToUserAws(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                      GiveAccessToUser(
                        res?.data.data.found[i].email,
                        res?.data.data.found[i].userId
                      );
                    }
                  }
                }
              }
              if (res?.data.data.notFound.length > 0) {
                for (let i = 0; res?.data.data.notFound.length > i; i++) {
                  if (uploadFrom === "multiple") {
                    if (uploadFile === true && uploadLocation === "aws") {
                      handleInviteUserMulti(res?.data.data.notFound[i]);
                    }
                    if (uploadFile === true && uploadLocation === "ipfs") {
                      handleInviteUserMulti(res?.data.data.notFound[i]);
                    }
                    if (uploadFile === true && uploadLocation === "both") {
                      handleInviteUserMulti(res?.data.data.notFound[i]);
                    }
                    if (uploadFolder === true && uploadLocation === "both") {
                      handleInviteUserMultiFolder(res?.data.data.notFound[i]);
                    }
                    if (uploadFolder === true && uploadLocation === "ipfs") {
                      handleInviteUserMultiFolder(res?.data.data.notFound[i]);
                    }
                    if (uploadFolder === true && uploadLocation === "aws") {
                      handleInviteUserMultiFolder(res?.data.data.notFound[i]);
                    }
                  } else {
                    handleInviteUserMulti(res?.data.data.notFound[i]);
                  }
                }
              }
            }
          })
          .catch((err) => {
            console.log("get data ipfs err***", err);
            // if (uploadFrom === "multiple") {
            //   if (uploadFile === true && uploadLocation === "aws") {
            //     handleInviteUserMulti(FinalEmail[0].email[i]);
            //   }
            //   if (uploadFile === true && uploadLocation === "ipfs") {
            //     handleInviteUserMulti(FinalEmail[0].email[i]);
            //   }
            //   if (uploadFile === true && uploadLocation === "both") {
            //     handleInviteUserMulti(FinalEmail[0].email[i]);
            //   }
            //   if (uploadFolder === true && uploadLocation === "both") {
            //     handleInviteUserMultiFolder(FinalEmail[0].email[i]);
            //   }
            //   if (uploadFolder === true && uploadLocation === "ipfs") {
            //     handleInviteUserMultiFolder(FinalEmail[0].email[i]);
            //   }
            //   if (uploadFolder === true && uploadLocation === "aws") {
            //     handleInviteUserMultiFolder(FinalEmail[0].email[i]);
            //   }
            // } else {
            //   handleInviteUserMulti(FinalEmail[0].email[i]);
            // }

            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              window.location.replace("/");
            }
          })
          .then(function () {});
        // }
      }
    }
  };
  ///****Get user [Added on Company]****/
  const getAllUserData = () => {
    if (
      CompanyID === undefined ||
      CompanyID === "null" ||
      CompanyID === "" ||
      CompanyID === null
    ) {
      console.log("Not A Company");
    } else {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/company/user-count?companyId=${CompanyID}`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      axios
        .get(API_URL, headerConfig)
        .then(function (response) {
          var data = response?.data?.companyUsers;
          console.log("User Count", data);
          var FinalData = data.filter((val) => {
            if (val.userID === undefined || val.userID === null) {
              console.log(val.userID);
            } else {
              return val.userID.email !== useremail;
            }
          });
          setUserData(FinalData);
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
          errorToast(error?.response?.data?.message);
        })
        .then(function () {});
    }
  };
  useEffect(() => {
    getAllUserData();
  }, []);
  //****IPFS Give Access File for register user */
  const GiveAccessToUser = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: singleMetaData?.file_unique_id,
      folder_id: allFileData.FolderIDIPFS,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: allFileData.bucketIdIpfs,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res?.data?.success === true) {
          dispatch(fetchSharedByMeFiles("ipfs"));

          if (emailData.length === 0) {
            if (uploadLocation !== "both") {
              successToast(res?.data?.message);
            }
            closeAccessPopup();

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            if (uploadLocation !== "both") {
              successToast(res?.data?.message);
            }
            closeAccessPopup();

            setShareFileLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };

  //*** Give Access to Multi file aws *****/
  const GiveAccessToUserAws = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: singleMetaData.file_unique_id,
      folder_id: allFileData?.FolderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: allFileData?.bucketId,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
      to_both: uploadLocation === "both" ? true : false,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            successToast(res?.data?.message);
            closeAccessPopup();

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res?.data?.message);
            closeAccessPopup();

            setShareFileLoader(false);
          }
        }
        dispatch(fetchSharedByMeFiles("aws", allFileData?.bucketId));
        if (uploadLocation === "both") {
          dispatch(fetchSharedByMeFiles("both", allFileData?.bucketId));
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Give Access to Multi file ipfs Register user *****/
  const GiveAccessToUserMultiIPFS = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    console.log(multipledata.bucketIdIpfs);
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record-bulk`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      sender_user_id: sessionStorage.getItem("userID"),
      bucket_id:
        multipledata.bucketIdIpfs === "" || multipledata.bucketIdIpfs === null
          ? "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          : multipledata.bucketIdIpfs,
      file_unique_id: multiMetadata, // array
      folder_id:
        uploadLocation === "aws" ? multipledata.awsFolder : multipledata.ipfsID,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      to_both: uploadLocation === "both" ? true : false,
      // ----- data for email starts -----
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // null when no companyName
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            if (uploadLocation !== "both") {
              successToast(res?.data?.message);
            }
            if (uploadFolder === false) {
              closeAccessPopup();
            }

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            if (uploadLocation !== "both") {
              successToast(res?.data?.message);
            }
            if (uploadFolder === false) {
              closeAccessPopup();
            }

            setShareFileLoader(false);
          }
          if (uploadLocation === "ipfs" || uploadLocation === "both") {
            dispatch(fetchSharedByMeFiles("ipfs"));
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Give Access to Multi folder ipfs *****/
  const GiveAccessToUserMultiIPFSFolder = async (email, userID_to) => {
    setShareFileLoader(true);

    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-ipfs`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        company_id: CompanyID, // company that is giving the access
        company_user_id: userID, // user that is giving the access
        user_id: userID_to, // user whom access is being given
        db_version: "v1",
        other_data_2: other_data_2_ipfs, // data of existing file in the folders which access is being given (files only) (you won't get data about an empty folder here)
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        folderArr: folderArr_ipfs,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,

        // ----- data for email starts -----
        email: email,
        companyName: CompanyName, // null when no companyName
        firstName: "", // don't add validation now
        lastName: "", // don't add validation now
        sender_firstname: UserF_Name, // don't add validation now
        sender_lastname: UserL_name, // don't add validation now
        share_location: uploadLocation === "ipfs" ? "01" : "11",
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_ipfs_folder_access res***", res);
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();

        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        closeAccessPopup();

        setShareFileLoader(false);
      }
      if (uploadLocation === "ipfs") {
        dispatch(fetchSharedByMeFiles("ipfs"));
        dispatch(fetchSharedByMeFolders("ipfs"));
      }
      if (uploadLocation === "ipfs") {
        dispatch(fetchSharedByMeFiles("ipfs"));
      }
    } catch (err) {
      console.log(
        "give_ipfs_folder_access err***",
        err?.response?.data?.message
      );
    }
  };

  //*** Give Access to Multi file aws Register user*****/
  const GiveAccessToUserMultiAWS = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record-bulk`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      sender_user_id: sessionStorage.getItem("userID"),
      bucket_id: multipledata.bucketId,
      file_unique_id: multiMetadata, // array
      folder_id: multipledata?.FolderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,

      // ----- data for email starts -----
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // null when no companyName
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      to_both: uploadLocation === "both" ? true : false,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            successToast(res?.data?.message);
            closeAccessPopup();

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res?.data?.message);
            closeAccessPopup();

            setShareFileLoader(false);
          }
        }
        if (uploadLocation === "aws") {
          dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
        }

        if (uploadLocation === "both") {
          dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
          dispatch(fetchSharedByMeFiles("both", multipledata.bucketId));
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };

  //*** Give Access to Multi folder aws *****/
  const GiveAccessToUserMultiAWSFolder = async (email, userID_to) => {
    setShareFileLoader(true);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-aws`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        company_id: CompanyID,
        company_user_id: userID,
        user_id: userID_to,
        db_version: "v1",
        other_data_2: other_data_2,
        bucketId: multipledata.bucketId,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,
        email: email,
        companyName: CompanyName,
        firstName: "",
        lastName: "",
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        folderArr: folderArr,
        share_location: uploadLocation === "aws" ? "10" : "11",
        to_both: uploadLocation === "both" ? true : false,
        folderArr_Ipfs: folderArr_ipfs,
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_aws_folder_access res***", res);
      if (res?.data?.success === true) {
        closeAccessPopup();

        setShareFileLoader(false);
        if (uploadLocation === "aws") {
          dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
          dispatch(
            fetchSharedByMeFolders(
              "aws",
              multipledata.bucketId,
              multipledata.awsBucketName
            )
          );
        }

        if (uploadLocation === "both") {
          dispatch(fetchSharedByMeFiles("both", multipledata.bucketId));
          dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
          // dispatch(fetchSharedByMeFiles("ipfs", multipledata.bucketId));
          dispatch(
            fetchSharedByMeFolders(
              "both",
              multipledata.bucketId,
              multipledata.awsBucketName
            )
          );
        }
      }
    } catch (err) {
      setShareFileLoader(false);
      console.log(
        "give_aws_folder_access err***",
        err?.response?.data?.message
      );
    }
  };
  //*** Invite user for file access multiple file [not register user]*****/
  const handleInviteUserMulti = (email, lastIndex) => {
    setShareFileLoader(true);

    var companyID = sessionStorage.getItem("companyID");

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/inviteuser-for-access`;

    var data = {
      firstName: "", // new user
      lastName: "", // new user
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // (send "" for individual user)
      sender_firstname: sessionStorage.getItem("fName"), // user who is giving access
      sender_lastname: sessionStorage.getItem("lName"), // user who is giving access
      sender_user_id: sessionStorage.getItem("userID"), // user who is giving access

      // following data is to insert an access record in pgTable and ipfsPgTable
      is_aws:
        uploadFrom === "single"
          ? allFileData.isAmazon
          : // : allFileDataMulti[0]?.isAmazon, //
            uploadLocAws,

      is_ipfs:
        uploadFrom === "single"
          ? allFileData.isIPFS
          : // : allFileDataMulti[0]?.isIPFS, //
            uploadLocIpfs,

      company_id: companyID, // company that is giving the access (send null when no company_id)
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,

      bucket_id:
        uploadFrom === "single"
          ? uploadLocation === "ipfs"
            ? ""
            : allFileData?.bucketId
          : uploadLocation === "ipfs"
          ? ""
          : multipledata.bucketId,

      other_data_aws: other_data_invite, // [ [file_unique_id, folder_id], [file_unique_id, folder_id], [file_unique_id, folder_id] ]

      bucket_id_ipfs:
        uploadFrom === "single"
          ? uploadLocation === "aws"
            ? ""
            : allFileData.bucketIdIpfs
          : uploadLocation === "aws"
          ? ""
          : multipledata.bucketIdIpfs,

      other_data_ipfs: other_data_invite_ipfs, // [ [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs] ]
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        if (res?.status === 200) {
          handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
          if (emailData.length === 0) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
          }
          if (emailData.slice(-1)[0] === email) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
          }
          if (uploadLocation === "aws") {
            dispatch(fetchSharedByMeFiles("aws", allFileData?.bucketId));
          }
          if (uploadLocation === "ipfs") {
            dispatch(fetchSharedByMeFiles("ipfs"));
          }
          if (uploadLocation === "both") {
            dispatch(fetchSharedByMeFiles("both", allFileData?.bucketId));
            dispatch(fetchSharedByMeFiles("ipfs"));
            dispatch(fetchSharedByMeFiles("aws", allFileData?.bucketId));
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Invited user default IPFS folder create API *****/
  const handleCreateDefaultFolder = (userId) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userId,
      folderName: `atom-storage/${userId}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("reg res***", res);
        if (res?.data?.success === true) {
          closeAccessPopup();
        }
      })
      .catch((err) => {
        console.log("reg err***", err);

        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };
  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("root folder aws res***", res);
      })
      .catch((err) => {
        console.log("root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        console.log("private root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  //*** Invite user for folder access *****/
  const handleInviteUserMultiFolder = async (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/inviteuser-for-folder-access`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        firstName: "",
        lastName: "",
        email: email,
        companyName: CompanyName,
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        sender_user_id: userID,
        is_aws: uploadLocation === "ipfs" ? false : true,
        is_ipfs: uploadLocation === "aws" ? false : true,
        company_id: CompanyID,
        db_version: "v1",
        bucket_id: multipledata?.bucketId,
        other_data_aws: other_data_2,
        folderArr_aws: uploadLocation === "ipfs" ? [] : folderArr,
        bucket_id_ipfs:
          uploadLocation === "aws"
            ? ""
            : "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        other_data_ipfs: other_data_2_ipfs,
        folderArr_ipfs: uploadLocation === "aws" ? [] : folderArr_ipfs,
        read_access_aws: uploadLocation === "ipfs" ? false : readAccess,
        write_access_aws: uploadLocation === "ipfs" ? false : writeAccess,
        download_access_aws: uploadLocation === "ipfs" ? false : downloadAccess,
        read_access_ipfs: uploadLocation === "aws" ? false : readAccess,
        write_access_ipfs: uploadLocation === "aws" ? false : writeAccess,
        download_access_ipfs: uploadLocation === "aws" ? false : downloadAccess,
        // share_location_aws: uploadLocation === "aws"  ? "10" : "11",
        share_location_aws:
          uploadLocation !== "ipfs"
            ? uploadLocation === "aws"
              ? "10"
              : "11"
            : null,

        share_location_ipfs:
          uploadLocation !== "aws"
            ? uploadLocation === "ipfs"
              ? "01"
              : "11"
            : null,
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_ipfs_folder_access res***", res);
      if (res?.status === 200) {
        handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
        handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
        handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
      }

      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();

        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        closeAccessPopup();

        setShareFileLoader(false);
      }
      if (uploadLocation === "aws") {
        dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
        dispatch(
          fetchSharedByMeFolders(
            "aws",
            multipledata.bucketId,
            multipledata.awsBucketName
          )
        );
      }
      if (uploadLocation === "ipfs") {
        dispatch(fetchSharedByMeFiles("ipfs"));
        dispatch(fetchSharedByMeFolders("ipfs"));
      }
      if (uploadLocation === "both") {
        // dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
        dispatch(
          fetchSharedByMeFolders(
            "aws",
            multipledata.bucketId,
            multipledata.awsBucketName
          )
        );
        // dispatch(fetchSharedByMeFiles("ipfs"));
        dispatch(fetchSharedByMeFolders("ipfs"));
        dispatch(fetchSharedByMeFiles("both", multipledata.bucketId));
        dispatch(fetchSharedByMeFiles("aws", multipledata.bucketId));
        dispatch(fetchSharedByMeFiles("ipfs", multipledata.bucketId));
      }
    } catch (err) {
      console.log(
        "give_ipfs_folder_access err***",
        err?.response?.data?.message
      );
      if (emailData.slice(-1)[0] === email) {
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        setShareFileLoader(false);
      }
    }
  };

  const cancleUpload = () => {
    dispatch(
      cancleSingleUpload(
        uploadLocation,
        allFileData.awsFolder,
        file_unique_id,
        unique_fileName,
        uploadFrom,
        abortController
      )
    );
    dispatch(resetUploadMetaData());
    dispatch(resetUploadData());
    setTimeout(function () {
      //  window.location.replace("/my-files");
      window.history.pushState(null, "", "/my-files");
      const popStateEvent = new PopStateEvent("popstate", { state: null });
      dispatchEvent(popStateEvent);
    }, 3000);
  };

  const cance_upload_single = (uploading) => {
    if (uploading === "metadata") {
      dispatch(
        cancel_single_upload_metadata(
          uploadLocation,
          allFileData?.awsBucketName
        )
      );
    } else if (uploading === "chunks") {
      dispatch(
        cancel_single_upload_chunks(
          uploadLocation,
          allFileData?.awsBucketName,
          file_unique_id,
          unique_fileName
        )
      );
    }

    setTimeout(function () {
      dispatch(resetUploadMetaData());
      dispatch(resetUploadData());
      window.history.pushState(null, "", "/my-files");
      const popStateEvent = new PopStateEvent("popstate", { state: null });
      dispatchEvent(popStateEvent);
      dispatch(resetCancelStateFunc());
      setClickedInd([]);
    }, 3000);
  };

  const cancelUploadMultiple = (i) => {
    var bucket_name =
      uploadLocation === "aws"
        ? allFileDataMulti[0]?.awsBucketName
        : "atom-storage";
    setCancelIndex([...cancelIndex, i]);
    console.log(File_id_multi);
    dispatch(
      cancleMultipleUpload(
        uploadFrom,
        uploadLocation,
        multipledata,
        File_id_multi,
        multi_pro,
        newFolderData,
        file_unique_id,
        unique_fileName,
        allFileDataMulti,
        i,
        bucket_name,
        multipleabortController
      )
    );
  };

  const checkUploadStatus_single = (index) => {
    if (index === cancelState.fileData.ind) {
      if (cancelState.fileData.data === "metadata") {
        // abort "metadata" api
        cance_upload_single("metadata");
      } else if (cancelState.fileData.data === "chunks") {
        // abort "upload" api
        cance_upload_single("chunks");
      }
    } else {
      // file upload is not started yet. This won't happen for single file
    }
  };

  const checkUploadStatus = (index) => {
    if (index === cancelState.fileData.ind) {
      // file is uploading
      if (cancelState.fileData.data === "metadata") {
        // abort "metadata" api
        dispatch(
          cancel_multi_upload_metadata(
            uploadFrom,
            uploadLocation,
            multipledata,
            allFileDataMulti,
            multi_pro,
            newFolderData,
            abortController,
            index
          )
        );
      } else if (cancelState.fileData.data === "chunks") {
        // abort "upload" api
        dispatch(
          cancel_multi_upload_chunks(
            uploadFrom,
            uploadLocation,
            multipledata,
            allFileDataMulti,
            multi_pro,
            newFolderData,
            abortController,
            index
          )
        );
      }
    } else {
      // file upload is not started yet
      // allFileDataMulti, multi_pro
      dispatch(cancel_multi_not_started(index));
    }
  };

  const handleDisableBtn = (i) => {
    setClickedInd((prev) => [...prev, i]);
  };

  ///***language */
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_selectaccesstype(
        xpath.select1("/resources/string[@name='text_accesstype']", doc)
          .firstChild.data
      );
      setText_validationemail(
        xpath.select1("/resources/string[@name='text_validationemail']", doc)
          .firstChild.data
      );
      setText_alemail(
        xpath.select1("/resources/string[@name='text_alemail']", doc).firstChild
          .data
      );
      settext_noticeemail(
        xpath.select1("/resources/string[@name='text_noticeemail']", doc)
          .firstChild.data
      );
      setText_valemail(
        xpath.select1("/resources/string[@name='text_valemail']", doc)
          .firstChild.data
      );
      setText_Own_email_err(
        xpath.select1("/resources/string[@name='text_Own_email_err']", doc)
          .firstChild.data
      );
      setText_enteruseremail(
        xpath.select1("/resources/string[@name='text_enteruseremail']", doc)
          .firstChild.data
      );
      setText_skip(
        xpath.select1("/resources/string[@name='text_skip']", doc).firstChild
          .data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_Uploding(
        xpath.select1("/resources/string[@name='text_Uploding']", doc)
          .firstChild.data
      );
      setText_item(
        xpath.select1("/resources/string[@name='text_item']", doc).firstChild
          .data
      );
      setText_uploadCancel(
        xpath.select1("/resources/string[@name='text_uploadCancel']", doc)
          .firstChild.data
      );
      setText_err_cancel(
        xpath.select1("/resources/string[@name='text_err_cancel']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      {progressBox === false || showpopup === true ? (
        // showProgressBox === "" ||
        // showProgressBox === undefined ||
        // showProgressBox === null ||
        // showpopup === true ||
        // sessionStorage.getItem("ProgressBox") === "false"
        ""
      ) : (
        <div className="p-4">
          <div className="main-box-content">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <div className="d-flex">
                    {/* Progress box main close button on top*/}
                    {multiMetadata.length === cancelfilescount.length ? (
                      <CgCloseO
                        title="Close"
                        className="cancle-sty text-end"
                        onClick={() => [
                          closeBoxOnCancel(),

                          // navigate("/my-files")
                          // sessionStorage.setItem("ProgressBox", false),
                          // setCloseProgressBox(false),
                          // window.location.reload(),
                        ]}
                      />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  backgroundColor: "#f7fcfc",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                {/* Uploading item count */}
                <h6 className="mx-3 mt-1">
                  {text_Uploding}{" "}
                  {uploadFrom === "multiple" ? multi_pro.length : "1"}{" "}
                  {text_item}
                </h6>
              </AccordionSummary>
              <AccordionDetails>
                <div className="upload-scrollbar">
                  <div>
                    {uploadFrom === "multiple" ? (
                      <>
                        {/* upload file folder data [Progressbar , retry, cancel file] start*/}
                        {multi_pro.map((e, i) => (
                          <>
                            <div className="d-flex  justify-content-between flex-wrap">
                              <div className="d-flex gap-1  mt-1">
                                <img
                                  src={FileImg}
                                  className="login-emailphone"
                                  alt=""
                                />
                                <p
                                  className="mx-2 upload-file-truncate"
                                  title={e.filename}
                                >
                                  {e.filename}
                                </p>
                              </div>

                              {(() => {
                                if (
                                  cancelState.overallData.abortedInd.includes(
                                    e.index
                                  )
                                ) {
                                  return <h6>{text_uploadCancel}</h6>;
                                } else if (
                                  cancelState.overallData.abortErrInd.includes(
                                    e.index
                                  )
                                ) {
                                  return <h6>{text_err_cancel}</h6>;
                                } else {
                                  return (
                                    <h6 className="text-end mx-3">
                                      {e.progress === 100 ? (
                                        <>
                                          {/* {setCancelIndexNumber(e.index + 1)} */}
                                          <FaCheckCircle className="text-success check-icon" />
                                        </>
                                      ) : (
                                        <>
                                          {(() => {
                                            if (clickedInd.includes(e.index)) {
                                              return <></>;
                                            } else {
                                              return (
                                                <CgCloseO
                                                  title="Cancel Upload"
                                                  className="cancle-sty"
                                                  // onClick={() => cancelUploadMultiple(i)}
                                                  onClick={() => {
                                                    checkUploadStatus(e.index);
                                                    handleDisableBtn(e.index);
                                                  }}
                                                />
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </h6>
                                  );
                                }
                              })()}
                            </div>

                            {(() => {
                              if (
                                cancelState.overallData.abortedInd.includes(
                                  e.index
                                )
                              ) {
                                return <></>;
                              } else if (
                                cancelState.overallData.abortErrInd.includes(
                                  e.index
                                )
                              ) {
                                return <></>;
                              } else {
                                return (
                                  <>
                                    {e.progress === 100 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex progress-div">
                                        <ProgressBar
                                          now={
                                            e.progress === 0 ? 0 : e.progress
                                          }
                                          label={`${e.progress}%`}
                                          variant="progress-bar"
                                          className="my-progressBar"
                                          visuallyHidden
                                        />
                                        <span className="uploadProgress-font mx-1">
                                          {e.progress}%
                                        </span>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            })()}

                            <hr />
                          </>
                        ))}
                        {/* upload file folder data [Progressbar , retry, cancel file] end*/}
                      </>
                    ) : (
                      <>
                        {/* upload file For Single data [Progressbar , retry, cancel file] start*/}
                        <div className="d-flex  justify-content-between flex-wrap">
                          <div className="d-flex gap-1  mt-1">
                            <img
                              src={FileImg}
                              className="login-emailphone"
                              alt=""
                            />
                            {console.log(signleFileData)}
                            <p
                              className="mx-2 upload-file-truncate"
                              title={signleFileData.name}
                            >
                              {signleFileData.name}
                            </p>
                          </div>

                          {(() => {
                            if (
                              cancelState.overallData.abortedInd.includes(0)
                            ) {
                              return <h6>{text_uploadCancel}</h6>;
                            } else if (
                              cancelState.overallData.abortErrInd.includes(0)
                            ) {
                              return <h6>{text_err_cancel}</h6>;
                            } else {
                              return (
                                <h6 className="text-end mx-3 ">
                                  {/* {FileUploadCancelAtom === "" ? ( */}
                                  <>
                                    {uploadProgress === 100 ? (
                                      <FaCheckCircle className="text-success check-icon" />
                                    ) : (
                                      <>
                                        {(() => {
                                          if (clickedInd.includes(0)) {
                                            return <></>;
                                          } else {
                                            return (
                                              <CgCloseO
                                                className="cancle-sty"
                                                // onClick={cancleUpload}
                                                onClick={() => [
                                                  checkUploadStatus_single(0),
                                                  handleDisableBtn(0),
                                                ]}
                                                title="Cancel Upload"
                                              />
                                            );
                                          }
                                        })()}
                                      </>
                                    )}
                                  </>
                                  {/* ) : ( */}
                                  {/* FileUploadCancel */}
                                  {/* )} */}
                                </h6>
                              );
                            }
                          })()}
                        </div>

                        {(() => {
                          if (cancelState.overallData.abortedInd.includes(0)) {
                            return <></>;
                          } else if (
                            cancelState.overallData.abortErrInd.includes(0)
                          ) {
                            return <></>;
                          } else {
                            return (
                              <>
                                {FileUploadCancelAtom === "" ? (
                                  <>
                                    {uploadProgress === 100 ? (
                                      ""
                                    ) : (
                                      <div className="d-flex progress-div">
                                        <ProgressBar
                                          now={
                                            uploadProgress === 0
                                              ? 0
                                              : uploadProgress
                                          }
                                          label={`${uploadProgress}%`}
                                          variant="progress-bar"
                                          className="my-progressBar"
                                          visuallyHidden
                                        />
                                        <span className="uploadProgress-font mx-1">
                                          {uploadProgress}%
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          }
                        })()}

                        {/* upload file For Single data [Progressbar , retry, cancel file] end*/}
                        <hr />
                      </>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      <Dialog
        open={accesspopup}
        onClose={closeAccessPopup}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center w-100">
          {/* Access data file name and count*/}
          <div className="d-flex justify-content-between">
            <h5 className="mt-1">
              {text_share}{" "}
              {singleMetaData.fileName === "" || uploadFrom === "multiple" ? (
                <>{multiMetadata.length - cancelfilescount.length} Item To</>
              ) : (
                <>
                  <img
                    src={FileImg}
                    alt=""
                    style={{
                      height: "19px",
                      width: "19px",
                      marginBottom: "3px",
                    }}
                  />{" "}
                  {singleMetaData.fileName} To
                </>
              )}{" "}
            </h5>
            <h3>
              <CgCloseO
                onClick={() => closeAccessPopup()}
                className="close-popup"
              />
            </h3>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Access data given access email added*/}
            <div className="d-flex flex-wrap">
              <div>
                <div className="participants_box">
                  <div className="tags-input">
                    {emailData &&
                      emailData.map((dataitem, i) => (
                        <li key={i} className="tag">
                          <span className="tag-title">{dataitem}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => handleDelete(i)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    <input
                      className="user-input"
                      type="text"
                      onKeyPress={handleKeyDown}
                      onChange={EmailHandler}
                      value={emailexits}
                      placeholder={
                        emailData.length === 0 && text_enteruseremail
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    filteredSuggestions.length === 0 ? "" : "suggestion-list"
                  }
                  onKeyDown={(e) => onSelectSuggestionKey(e)}
                >
                  {displaySuggestions && (
                    <>
                      {filteredSuggestions.map((suggestion, index) => {
                        return (
                          <div className="suggestion" key={index}>
                            <li
                              className="li-suggestion"
                              onClick={() => onSelectSuggestion(index)}
                            >
                              {suggestion.userEmail}
                            </li>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <p className="mx-3 errMsg">{errEmail}</p>
              <select
                className="accessinput access-select mx-4  mb-4"
                onChange={(e) => [AccessValueHandle(e.target.value)]}
              >
                <option value="" disabled>
                  {text_selectaccesstype}
                </option>
                <option value="read">{text_read}</option>
                <option value="write">
                  {text_read}/{text_write}
                </option>
                <option value="download" selected>
                  {text_read}/{text_write}/{text_download}
                </option>
              </select>
            </div>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <div className="d-flex justify-content-center gap-4 mt-2 mb-3">
          <button className="box-btn" onClick={() => closeAccessPopup()}>
            {text_skip}
          </button>
          <button
            className="box-btn-save"
            onClick={() =>
              emailData.length === 0 ? handleSingleUser() : handleGetUser()
            }
            disabled={shareFileLoader || errEmail !== "" ? true : false}
          >
            {shareFileLoader ? <Loader2 /> : text_share}
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default ProgressBoxWIthRedux;
