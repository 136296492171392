import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import EmailPhoneImg from "../../utils/images/EmailPhone.png";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import atomImg from "../../utils/images/atom_1.png";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import {
  GLOBAL_URL_DOMAIN,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";

const ForgotPassword = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  // ----- remember me starts -----
  const handleClose = () => {
    setOpen(false);
    navigate("/signin");
  };
  const setDataToSessionFromCookie = async () => {
    var fName_InCookie = Cookie.get("fName");
    var lName_InCookie = Cookie.get("lName");
    var userID_InCookie = Cookie.get("userID");
    var userToken_InCookie = Cookie.get("userToken");

    sessionStorage.setItem("fName", fName_InCookie);
    sessionStorage.setItem("lName", lName_InCookie);
    sessionStorage.setItem("userID", userID_InCookie);
    sessionStorage.setItem("userToken", userToken_InCookie);
  };

  useEffect(() => {
    var tokenInCookie = Cookie.get("userToken");
    var token_start_timeInCookie = Cookie.get("token_start_time");

    if (tokenInCookie) {
      if (Date.now() - token_start_timeInCookie < 86400) {
        var fName_InCookie = Cookie.get("fName");
        var lName_InCookie = Cookie.get("lName");
        var userID_InCookie = Cookie.get("userID");

        setDataToSessionFromCookie().then(() => {
          navigate("/my-files", {
            state: {
              tokenInCookie,
              fName_InCookie,
              lName_InCookie,
              userID_InCookie,
            },
          });
        });
      } else {
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("user_id");
        Cookie.remove("token_start_time");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
    }
  }, []);

  // ----- remember me ends -----

  const handleForgotpass = () => {
    if (emailOrPhone !== "") {
      const API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/forgot-password-email";
      const data = {
        email: emailOrPhone.toLowerCase(),
      };
      axios
        .post(API_URL, data)
        .then((res) => {
          if (res?.data?.success === true) {
            console.log(res?.data?.message);
            setEmailMsg(res?.data?.message);
            setOpen(true);
          }
        })
        .catch((err) => {
          console.log("havingTroubleSigninginFunc err***", err);
          if (err?.response?.data?.success === false) {
            console.log(err?.response?.data?.message);
            setEmailMsg(err?.response?.data?.message);
          }
        })
        .then(function () {});
    } else {
      setEmailMsg("*Please Enter Registered Email or Phone no!");
    }
  };
  return (
    <div className="login-main">
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">{emailMsg}</h3>
          </DialogContentText>

          <Divider />
        </DialogContent>
        <DialogActions>
          <button
            className="confirm-btn mx-4"
            onClick={() => navigate("/signin")}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <div className="form login-form fp-form">
        <div className="login-title-main">
          <div className="login-title-logo">
            <img src={atomImg} alt="" className="login-atom-img" />
          </div>
          <div className="login-title-text">Forgot Password</div>
        </div>
        <div className="login-subtitle">
          <p className="login-subtitle-1">
            Enter your Email or Phone Number and We'll Send you an OTP to Reset
            your Password.
          </p>
        </div>
        <div className="login-content">
          <div className="flex-column">
            <label className="login-labels">Email or Phone Number</label>
          </div>
          <div className="inputForm">
            <img src={EmailPhoneImg} alt="" className="login-emailphone" />
            <input
              type="text"
              className="input login-input"
              placeholder="Enter your Email or Phone Number"
              onChange={(e) => setEmailOrPhone(e.target.value)}
              value={emailOrPhone.toLowerCase()}
            />
          </div>
          <span className="errMsg">{emailMsg}</span>
          <button className="button-submit" onClick={handleForgotpass}>
            Submit
          </button>
          <p className="p login-last-text fp-backto-signin">
            <center>
              <div className="forgot-fp-back">
                <Link to="/signin" className="login-fp-inner fp-backto-signin">
                  <span className="span fp-last">
                    <IoIosArrowBack className="fp-back-icon" /> Back to Sign In
                  </span>
                </Link>
              </div>
            </center>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
