import React, { useState } from "react";
import "./YourFiles.css";
import { useEffect } from "react";
import axios from "axios";
import { customTableStyles } from "../../utils/Files/tableStyle";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import FilterComponent from "../../utils/Files/FilterComponent";
import moment from "moment";
import { LuDownload } from "react-icons/lu";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { IoShareSocialSharp } from "react-icons/io5";
import folderImg from "../../utils/images/folderAWS.png";
import fileImg from "../../utils/images/file_1.png";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import Cookie from "js-cookie";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { ToastContainer, toast } from "react-toastify";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader2 from "../../Components/Loader2/Loader2";
import Loader3 from "../../utils/Files/data";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import peopleimg from "../../utils/images/peopleimg.png";
import { useDispatch, useSelector } from "react-redux";
import { getBothFileData } from "../../redux/BothFilesSlice";
import { rootApiCall } from "../../redux/MetaDataSlice";
import {
  fetchSharedByMeFiles,
  // fetchSharedByMeFolders,
} from "../../redux/SharedByMeSlice";
import { getAllUserData } from "../../redux/UserListSlice";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const YourFilesBoth = () => {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.bothfileData.bothData);
  const Bothloader = useSelector((state) => state.bothfileData.bothloader);
  console.log(Data);
  const callBothRootApi = useSelector(
    (state) => state.metadata.callBothRootApi
  );
  const userData = useSelector((state) => state.comapanyUserList.userdata);

  //variable
  var UserEmailId = sessionStorage.getItem("email");
  var CompanyID = sessionStorage.getItem("companyID");
  var userID = sessionStorage.getItem("userID");
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  // const [Data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [shareFileUniqID, setShareFileUniqID] = useState();
  const [emailexits, setEmailexits] = useState("");
  const [inviteuserPopup, setInviteuserPopup] = useState(false);
  const [bucketIdIpfs, setBucketIdIPFS] = useState("");
  const [FolderIDIPFS, setfolderIDIPFS] = useState("");
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [allFileData, setAllFileData] = useState("");
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [Filename, setFileName] = useState("");
  const [loader, setLoader] = useState(false + "");
  const [loaderAccess, setLoaderAccess] = useState(false + "");

  const [loaderIPFS, setLoaderIPFS] = useState(false);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [loaderData, setLoaderData] = useState(false);
  const [opentooltipatom, setOpenTooltipatom] = useState(false);
  const [accesspopup, setAccesspopup] = useState(false);
  const [bucketId, setBucketID] = useState("");
  const [folderID, setFolderID] = useState("");
  const [accessEmailAws, setAccessEmailAws] = useState([]);
  const [accessEmailIpfs, setAccessEmailIpfs] = useState([]);

  const [emailData, setEmailData] = useState([]);
  const [errEmail, setErrEmail] = useState("");
  const [folderName, setFolderName] = useState("");
  const [combinedDataAws, setCombinedDataAws] = useState([]);
  const [combinedDataIpfs, setCombinedDataIpfs] = useState([]);

  const [shareDataPopup, setSharedDataPopup] = useState(false);
  const [shareFileLoader, setShareFileLoader] = useState(false);
  const [readAccess, setReadAccess] = useState(true);
  const [writeAccess, setWriteAccess] = useState(true);
  const [downloadAccess, setDownloadAccess] = useState(true);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  // const [userData, setUserData] = useState([]);
  const [folderNameForInsertRecordaws, setFolderNameForInsertRecordaws] =
    useState("");
  const [folderNameForInsertRecordipfs, setFolderNameForInsertRecordipfs] =
    useState("");
  const [noRecordsFoundaws, setNoRecordsFoundAws] = useState("");
  const [noRecordsFoundIpfs, setNoRecordsFoundIpfs] = useState("");

  //language variable start
  const [text_srno, setText_srno] = useState("SR NO.");
  const [text_filename1, setText_filename1] = useState("FILE NAME");
  const [text_filesize1, setText_filesize1] = useState("FILE SIZE");
  const [text_amazons3link1, setText_amazons3link1] =
    useState("AMAZON S3 LINK");
  const [text_dateofupload, setText_dateofupload] = useState("DATE OF UPLOAD");
  const [text_amazons3folder, setText_amazons3folder] =
    useState("AMAZON S3 FOLDER");
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_atomstorage, setText_atomstorage] = useState("Atom Storage");
  const [text_atomfileid, setText_atomfileid] = useState("ATOM FILE ID");
  const [text_viewdetails, setText_viewdetails] = useState("VIEW DETAILS");
  const [text_view, setText_view] = useState("View");
  const [text_edit, setText_edit] = useState("EDIT");
  const [text_downfromamazons3, setText_downfromamazons3] = useState(
    "DOWNLOAD FROM AMAZON S3"
  );
  const [text_atomstoragenetwork, settext_atomstoragenetwork] = useState(
    "DOWNLOAD FROM ATOM STORAGE"
  );
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_share, setText_share] = useState("Share");
  const [text_username, setText_username] = useState("User Name");
  const [text_useremail, setText_useremail] = useState("User Email");
  const [text_accessoption, setText_accessoption] = useState("Access Option");
  const [text_selaccessoption, setText_selaccessoption] = useState(
    "Select Access Option"
  );
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_download, setText_download] = useState("Download");
  const [text_validationemail, setText_validationemail] = useState(
    "Please Enter User Email"
  );
  const [text_enteruseremail, setText_enteruseremail] =
    useState("Enter User Email");
  const [text_send, setText_send] = useState("Send");
  const [text_inviteuser, setText_inviteuser] = useState("Invite User");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_verify, setText_verify] = useState("Verify");
  const [text_accesstype, setText_selectaccesstype] =
    useState("Select Access Type");
  const [text_firstname1, setText_firstname1] = useState("First Name");
  const [text_lastname1, setText_lastname1] = useState("Last Name");
  const [text_companyname, setText_companyname] = useState("Company Name");
  const [text_select_any_option, setText_select_any_option] = useState(
    "Please Select Any one Option"
  );
  const [text_Own_email_err, setText_Own_email_err] = useState(
    "You can not give access to your own email"
  );
  const [text_valemail, setText_valemail] = useState("*Enter valid email Id!");
  const [text_alemail, setText_alemail] = useState(
    "*Email has already been added!"
  );
  const [text_noticeemail, settext_noticeemail] = useState(
    "*You cannot give access to more than 50 emails at once!"
  );
  const [text_atomfolder, setText_atomfolder] = useState("Atom Folder");

  const [text_done, setText_done] = useState("Done");
  const [text_peoplewithaccess, setText_peoplewithaccess] =
    useState("People With Access");
  const [Web_server_text, setWeb_server_text] = useState(
    "Upload to Web Server in progress"
  );
  //language variable end
  useEffect(() => {
    sessionStorage.removeItem("uploadLocation");
  });
  const closeAccessPopup = () => {
    setAccesspopup(false);
    setEmailexits("");
    setAccesspopup(false);
    setInviteuserPopup(false);
    setEmailexits("");
    setFileName("");
    setEmailData([]);
    setFname("");
    setEmailexits("");
    setLname("");
    setFileName("");
    setEmailData([]);
    setErrEmail("");
    setReadAccess(true);
    setWriteAccess(true);
    setDownloadAccess(true);
    // setUserData([]);
    setDisplaySuggestions(false);
    setErrEmail("");
    setFilteredSuggestions([]);
  };

  const navigate = useNavigate();
  // const getFileData = () => {
  //   setLoaderData(true);
  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE +
  //     `/file/filedata?userId=${sessionStorage.getItem("userID")}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       console.log("get data both res***", res);
  //       const sortedData = res.data.data.sort((a, b) => {
  //         // Assuming you want to sort by a property called "createdAt"
  //         return new Date(b.createdAt) - new Date(a.createdAt);
  //       });
  //       if (sortedData.length > 0) {
  //         setData(sortedData);
  //       }

  //       setLoaderData(false);
  //     })
  //     .catch((err) => {
  //       setLoaderData(false);
  //       console.log("get data both err***", err);
  //       if (err?.response?.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});
  // };
  useEffect(() => {
    console.log(callBothRootApi);
    if (Data?.length === 0 || callBothRootApi === true) {
      // getFileData();
      dispatch(getBothFileData());
      dispatch(rootApiCall());
    }
  }, [Data?.length]);

  const handleGetUser = (value) => {
    if (alreadyemail.length > 0) {
      setErrEmail(<>{text_alemail}</>);
    } else {
      var FinalEmail = [];
      if (emailData.length === 0) {
        FinalEmail.push({ email: [value] });
      } else {
        FinalEmail.push({ email: emailData });
      }
      console.log(FinalEmail[0].email);
      if (FinalEmail.length === 0) {
        setErrEmail(<>*{text_validationemail}</>);
      } else {
        // for (let i = 0; FinalEmail[0].email.length > i; i++) {
        setVerifyBtn(true);
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        // var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/emailexist`;
        // var data = {
        //   EmailOrPhone: FinalEmail[0].email[i].toLowerCase(),
        // };
        var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/email-exists`;
        var data = {
          emails: FinalEmail[0].email,
        };
        axios
          .post(API_URL, data, headerConfig)
          .then((res) => {
            console.log("userDetails:-", res?.data?.data?._id);
            // var val = res?.data?.data;
            if (res?.status === 200) {
              // GiveAccessToUser(FinalEmail[0].email[i], val._id);
              if (res?.data.data.found.length > 0) {
                for (let i = 0; res?.data.data.found.length > i; i++) {
                  GiveAccessToUser(
                    res?.data.data.found[i].email,
                    res?.data.data.found[i].userId
                  );
                }
              }
              if (res?.data.data.notFound.length > 0) {
                for (let i = 0; res?.data.data.notFound.length > i; i++) {
                  handleInviteUser(res?.data.data.notFound[i]);
                }
              }
            }
          })
          .catch((err) => {
            console.log("get data ipfs err***", err);
            // handleInviteUser(FinalEmail[0].email[i]);
            setVerifyBtn(false);
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          })
          .then(function () {});
        // }
      }
    }
  };
  const filteredItems = Data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="text-end col-md-6 mb-2">
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              PlaceHolder={text_searchhere}
              onClear={handleClear}
              filterText={filterText}
              className="fc-input"
              clearClass="fc-x"
            />
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );

      setText_filesize1(
        xpath.select1("/resources/string[@name='text_filesize1']", doc)
          .firstChild.data
      );
      setText_amazons3link1(
        xpath.select1("/resources/string[@name='text_amazons3link1']", doc)
          .firstChild.data
      );
      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );
      setText_amazons3folder(
        xpath.select1("/resources/string[@name='text_amazons3folder']", doc)
          .firstChild.data
      );
      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_downfromamazons3(
        xpath.select1("/resources/string[@name='text_downfromamazons3']", doc)
          .firstChild.data
      );
      settext_atomstoragenetwork(
        xpath.select1("/resources/string[@name='text_atomstoragenetwork']", doc)
          .firstChild.data
      );
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_verify(
        xpath.select1("/resources/string[@name='text_verify']", doc).firstChild
          .data
      );
      setText_selectaccesstype(
        xpath.select1("/resources/string[@name='text_accesstype']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_inviteuser(
        xpath.select1("/resources/string[@name='text_inviteuser']", doc)
          .firstChild.data
      );
      setText_send(
        xpath.select1("/resources/string[@name='text_send']", doc).firstChild
          .data
      );
      setText_enteruseremail(
        xpath.select1("/resources/string[@name='text_enteruseremail']", doc)
          .firstChild.data
      );
      setText_username(
        xpath.select1("/resources/string[@name='text_username']", doc)
          .firstChild.data
      );
      setText_useremail(
        xpath.select1("/resources/string[@name='text_useremail']", doc)
          .firstChild.data
      );
      setText_accessoption(
        xpath.select1("/resources/string[@name='text_accessoption']", doc)
          .firstChild.data
      );
      setText_selaccessoption(
        xpath.select1("/resources/string[@name='text_selaccessoption']", doc)
          .firstChild.data
      );
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_validationemail(
        xpath.select1("/resources/string[@name='text_validationemail']", doc)
          .firstChild.data
      );
      setText_firstname1(
        xpath.select1("/resources/string[@name='text_firstname1']", doc)
          .firstChild.data
      );
      setText_lastname1(
        xpath.select1("/resources/string[@name='text_lastname1']", doc)
          .firstChild.data
      );
      setText_companyname(
        xpath.select1("/resources/string[@name='text_companyname']", doc)
          .firstChild.data
      );
      setText_select_any_option(
        xpath.select1("/resources/string[@name='text_select_any_option']", doc)
          .firstChild.data
      );
      setText_Own_email_err(
        xpath.select1("/resources/string[@name='text_Own_email_err']", doc)
          .firstChild.data
      );
      setText_valemail(
        xpath.select1("/resources/string[@name='text_valemail']", doc)
          .firstChild.data
      );
      setText_alemail(
        xpath.select1("/resources/string[@name='text_alemail']", doc).firstChild
          .data
      );
      settext_noticeemail(
        xpath.select1("/resources/string[@name='text_noticeemail']", doc)
          .firstChild.data
      );
      setText_atomfolder(
        xpath.select1("/resources/string[@name='text_atomfolder']", doc)
          .firstChild.data
      );

      setText_done(
        xpath.select1("/resources/string[@name='text_done']", doc).firstChild
          .data
      );
      setText_peoplewithaccess(
        xpath.select1("/resources/string[@name='text_peoplewithaccess']", doc)
          .firstChild.data
      );
      setWeb_server_text(
        xpath.select1("/resources/string[@name='Web_server_text']", doc)
          .firstChild.data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_atomstorage(
        xpath.select1("/resources/string[@name='text_atomstorage']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  ////******File with access user data */
  const userAccessData = async (fileUniqueId, row_id) => {
    setCombinedDataAws([]);
    setAccessEmailAws([]);
    setCombinedDataIpfs([]);
    setAccessEmailIpfs([]);
    setNoRecordsFoundAws("");
    setNoRecordsFoundIpfs("");

    setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    const data = {
      file_unique_id: fileUniqueId,
      sender_user_id: userID,
    };
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/file-access/users-with-file-access`;

      let res = await axios.post(API_URL, data, headerConfig);
      console.log("userAccessData res***", res);
      // setSharedDataPopup(true);
      setCombinedDataAws(res?.data?.data);
      setAccessEmailAws(res?.data?.userData);

      if (res?.data?.data === null || res?.data?.userData.length === 0) {
        setNoRecordsFoundAws("There are no records to display");
      }
      // setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    } catch (err) {
      // setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/users-with-file-access`;

      let res = await axios.post(API_URL, data, headerConfig);
      console.log("userAccessData res***", res);
      setSharedDataPopup(true);
      setCombinedDataIpfs(res?.data?.data);
      setAccessEmailIpfs(res?.data?.userData);
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (res?.data?.data.length === 0 || res?.data?.userData.length === 0) {
        setNoRecordsFoundIpfs("There are no records to display");
      }
    } catch (err) {
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const checkStatusOnShare = async (id) => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        setAccesspopup(true);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadStartTime_IPFS !== null
      ) {
        // ipfs upload is complete
        setAccesspopup(true);
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };

  const checkStatusOnDownload = async (row, index) => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: row.file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        handleDownload(row.fileType, row.fileNameWithUuid, index, row._id);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadStartTime_IPFS !== null
      ) {
        // ipfs upload is complete
        console.log(row);
        ipfs_download(
          row.fileCId,
          row.fileNameWithUuid,
          row.fileType,
          row.fileName,
          index,
          row._id
        );
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };
  const handleDownload = async (File_type, File_Name, row_id) => {
    const filename = File_Name;
    const fileType = File_type;
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-server`;

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        fileName: filename,
        fileType: fileType,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("stream_ipfs_server_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (res?.status === 200) {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      console.log("stream_ipfs_server_download error***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };

  const checkStatusOnDownloadAWS = async (row, index) => {
    console.log(row);
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: row.file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_AWSS3 === false
      ) {
        // server upload is complete and file is uploading on ipfs
        handleDownload(row.fileType, row.fileNameWithUuid, index, row._id);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_AWSS3 === true &&
        res?.data?.data?.uploadEndTime_AWSS3 !== null
      ) {
        // ipfs upload is complete
        if (row.bucketACLOption === "public") {
          handleDownloadFile(
            row.amazonKey,
            row.fileName,
            index,
            row._id,
            row.fileType,
            row.bucketName
          );
        } else if (row.bucketACLOption === "private") {
          downloadPrivateS3File(
            row.bucketName,
            row.amazonKey,
            row.fileName,
            index,
            row._id,
            row.fileType
          );
        }
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };
  useEffect(() => {
    if (userData?.length === 0) {
      dispatch(getAllUserData());
    }
    // getAllUserData();
  }, [userData?.length]);

  const columns = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_filename1}</>,
      selector: (row) => (
        <>
          {(row?.shared_count !== 0 || row?.shared_count_ipfs !== 0) && (
            <button
              onClick={() => [
                userAccessData(row?.file_unique_id, row._id),
                setFileName(row?.fileName),
              ]}
              className="shared-data-email"
              title="Shared"
            >
              {loaderAccess[row?._id] ? (
                <Loader2 className="mx-5" />
              ) : (
                <img src={peopleimg} alt="" className="peopleimg" />
              )}
            </button>
          )}{" "}
          <span title={row?.fileName}>{row?.fileName}</span>
        </>
      ),
    },
    {
      name: <>{text_amazons3folder}</>,
      selector: (row) => (
        <>
          {row?.folderName === "" || row?.folderName === null
            ? "/"
            : row?.folderName
                .split(`/`)
                .slice(0, -1)
                .map((folder, index) => (
                  <span key={index}>
                    {console.log(folder)}
                    {folder === userID ? "/" : <> {folder} /</>}
                  </span>
                ))}
        </>
      ),
    },
    {
      name: <>{text_atomfolder}</>,
      selector: (row) => (
        <>
          {row?.ipfsFolderName === "" || row?.ipfsFolderName === null
            ? "/"
            : row?.ipfsFolderName
                .split(`/`)
                .slice(1, -1)
                .map((folder, index) => (
                  <span key={index}>
                    <span key={index}>
                      {folder === userID ? "/" : <> {folder} /</>}
                    </span>
                  </span>
                ))}
        </>
      ),
    },
    // {
    //   name: <>{text_amazons3link1}</>,
    //   width: "250px",
    //   selector: (row) => (
    //     <p className="fileid">
    //       {truncate(row.amazonUrl, 8)}
    //       &nbsp;&nbsp; &nbsp;
    //       {row?.amazonUrl === "" ? (
    //         "NA"
    //       ) : (
    //         <ContentCopyIcon
    //           className="text-black copysize copy-sty"
    //           onClick={() => [
    //             setShowTooltipId(row._id),
    //             setOpenTooltip(true),
    //             navigator.clipboard.writeText(row.amazonUrl),
    //           ]}
    //           onMouseOut={onMouseOutamazon}
    //         />
    //       )}
    //       {showTooltipId === row._id ? (
    //         <>
    //           <Tooltip
    //             placement="top"
    //             PopperProps={{
    //               disablePortal: true,
    //             }}
    //             arrow
    //             open={opentooltip}
    //             disableFocusListener
    //             disableHoverListener
    //             disableTouchListener
    //             title="Copied!"
    //           ></Tooltip>
    //         </>
    //       ) : (
    //         <></>
    //       )}
    //     </p>
    //   ),
    // },
    // {
    //   name: <>{text_atomfileid}</>,
    //   width: "250px",
    //   selector: (row) => (
    //     <p className="fileid">
    //       {truncate(row.fileCId, 8)}
    //       &nbsp;&nbsp; &nbsp;
    //       {row?.fileCId === "" ? (
    //         "NA"
    //       ) : (
    //         <ContentCopyIcon
    //           className="text-black copysize copy-sty"
    //           onClick={() => [
    //             setShowTooltipIdatom(row._id),
    //             setOpenTooltipatom(true),
    //             navigator.clipboard.writeText(row.fileCId),
    //           ]}
    //           onMouseOut={onMouseOutatom}
    //         />
    //       )}
    //       {showTooltipIdatom === row._id ? (
    //         <>
    //           <Tooltip
    //             placement="top"
    //             PopperProps={{
    //               disablePortal: true,
    //             }}
    //             arrow
    //             open={opentooltipatom}
    //             disableFocusListener
    //             disableHoverListener
    //             disableTouchListener
    //             title="Copied!"
    //           ></Tooltip>
    //         </>
    //       ) : (
    //         <></>
    //       )}
    //     </p>
    //   ),
    // },
    {
      name: <>{text_filesize1}</>,
      selector: (row) => row?.fileSize,
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uploadDateAndTime).format("MMM DD, YYYY"),
    },
    {
      name: <>{text_viewdetails}</>,
      selector: (row, index) => (
        <button
          className="yf-download-btn"
          onClick={() => {
            console.log(row?.bucketACLOption);
            navigate("/view-file", {
              state: {
                fileData: row,
                selecttab: 2,
              },
            });
          }}
        >
          <span className="yf-download">{text_view}</span>
        </button>
      ),
      width: "150px",
    },
    {
      name: <>{text_edit}</>,
      selector: (row, index) => (
        <button
          className="yf-ed-btn"
          onClick={() =>
            navigate("/edit-file", {
              state: { EditFileData: row, selectedTab: 2 },
            })
          }
        >
          <EditIcon className="yf-ed-icon" />
        </button>
      ),
      width: "150px",
    },
    {
      name: <>{text_downfromamazons3}</>,
      selector: (row, index) => (
        <button
          className="download-btn"
          onClick={
            () => checkStatusOnDownloadAWS(row, index)
            //    [
            //   row.bucketACLOption === "public"
            //     ? handleDownloadFile(
            //         row.amazonKey,
            //         row.fileName,
            //         index,
            //         row._id,
            //         row.fileType,
            //         row.bucketName
            //       )
            //     : downloadPrivateS3File(
            //         row.bucketName,
            //         row.amazonKey,
            //         row.fileName,
            //         index,
            //         row._id,
            //         row.fileType
            //       ),
            // ]
          }
        >
          {loader[row._id] ? (
            <Loader2 />
          ) : (
            <LuDownload className="download-btn-icon" />
          )}
        </button>
      ),
      width: "150px",
    },
    {
      name: <>{text_atomstoragenetwork}</>,
      selector: (row, index) => (
        <button
          className="download-btn"
          onClick={() =>
            // handleDownloadFileIPFS(
            //   row.fileCId,
            //   row.fileNameWithUuid,
            //   row.fileType,
            //   row.fileName,
            //   index,
            //   row._id
            // )
            // ipfs_download(
            //   row.fileCId,
            //   row.fileNameWithUuid,
            //   row.fileType,
            //   row.fileName,
            //   index,
            //   row._id
            // )
            checkStatusOnDownload(row, index)
          }
        >
          {loaderIPFS[row._id] ? (
            <Loader2 />
          ) : (
            <LuDownload className="download-btn-icon" />
          )}
        </button>
      ),
      width: "150px",
    },
    {
      name: <>{text_share}</>,
      selector: (row, index) => (
        <button
          className="yf-download-btn"
          onClick={() => [
            // setAccesspopup(!accesspopup),
            checkStatusOnShare(row?.file_unique_id),
            setEmailexits(""),
            setFileName(row?.fileName),
            setShareFileUniqID(row?.file_unique_id),
            setFolderID(row?.folderId),
            setBucketID(row?.bucketId_aws),
            setfolderIDIPFS(row?.ipfsFolderId),
            setBucketIdIPFS(row?.bucketId_ipfs),
            setAllFileData(row),
            setFolderNameForInsertRecordaws(
              `${row?.bucketName}/${row?.folderName}`
            ),
            setFolderNameForInsertRecordipfs(row?.ipfsFolderName),
            // getAllUserData(),
          ]}
        >
          <IoShareSocialSharp className="download-btn-icon" />
        </button>
      ),
      width: "150px",
    },
  ];
  ////******Download from aws s3 */
  const handleDownloadFile = async (
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type,
    bucket_aws
  ) => {
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucket_aws; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("response****", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      console.log("stream_s3_download error:", err);
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  ////******Download from Private s3 */
  const downloadPrivateS3File = async (
    bucketName,
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type
  ) => {
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucketName; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3-private`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("response****", res);

      const blobData = new Blob([res?.data], { type: fileType });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      console.log("stream_s3_download error:", err);
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  ////******Download from atom storage */
  const ipfs_download = async (
    folder_cid,
    file_format,
    File_type,
    File_Name,
    i,
    row_id
  ) => {
    const filecid = folder_cid;
    const filename = File_Name;
    const fileType = File_type;
    setLoaderIPFS((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-ipfs?filecid=${filecid}&filename=${filename}`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.get(url, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("stream_ipfs_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (res?.status === 200) {
        setLoaderIPFS((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      console.log("stream_ipfs_download error***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
      setLoaderIPFS((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  ////***** Access Popup Start */
  const handleSingleUser = () => {
    if (emailexits === "") {
      setErrEmail(<>{text_validationemail}</>);
    } else {
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        handleGetUser(value);
      }
    }
  };
  ////******Create Default folder******/
  const handleCreateDefaultFolder = (userId) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userId,
      folderName: `atom-storage/${userId}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("reg res***", res);
      })
      .catch((err) => {
        console.log("reg err***", err);

        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("root folder aws res***", res);
      })
      .catch((err) => {
        console.log("root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        console.log("private root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };
  ////***** Invite user0[for File access] for not register user */
  const handleInviteUser = (email, lastIndex) => {
    setShareFileLoader(true);
    var companyID = sessionStorage.getItem("companyID");
    var otherDataAWS = [[allFileData.file_unique_id, allFileData.folderId]];
    var otherDataIPFS = [
      [allFileData.file_unique_id, allFileData.ipfsFolderId],
    ];
    var AWSBucketId = allFileData.bucketId_aws;
    var IPFSbucketId = allFileData.bucketId_ipfs;
    var isIPFS = allFileData.isIPFS;
    var isAws = allFileData.isAmazon;
    var FileName = allFileData.fileName;
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/inviteuser-for-access`;

    var data = {
      firstName: Fname,
      lastName: Lname,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      fileName: FileName,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      company_id: companyID, // company that is giving the access (send null when no company_id)
      bucket_id: AWSBucketId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      is_aws: isAws,
      is_ipfs: isIPFS,
      other_data_aws: otherDataAWS, // [ [file_unique_id, folder_id], [file_unique_id, folder_id], [file_unique_id, folder_id] ]

      bucket_id_ipfs: IPFSbucketId,
      other_data_ipfs: otherDataIPFS, // [ [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs] ]
      sender_user_id: sessionStorage.getItem("userID"),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        if (res.status === 200) {
          handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
          if (emailData.slice(-1)[0] === email) {
            successToast(res.data.message);
            setShareFileLoader(false);
            closeAccessPopup();
            // getFileData();
            dispatch(getBothFileData());
          }
          if (emailData.length === 0) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
            closeAccessPopup();
            // getFileData();
            dispatch(getBothFileData());
          }
        }
        dispatch(fetchSharedByMeFiles("aws", bucketId));
        dispatch(fetchSharedByMeFiles("ipfs"));
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ////***** give file access  for aWS register user */
  const GiveAccessToUser = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: shareFileUniqID,
      folder_id: folderID,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: bucketId,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: Fname,
      lastName: Lname,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      to_both: true,
      sender_user_id: sessionStorage.getItem("userID"),
      folder_name: folderNameForInsertRecordaws,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            // successToast(res.data.message);
            // closeAccessPopup();
            // setShareFileLoader(false);
            // getFileData();
            GiveAccessToUserIPFS(email, userID_to);
          }
          if (emailData.slice(-1)[0] === email) {
            // successToast(res.data.message);
            // closeAccessPopup();
            // setShareFileLoader(false);
            // getFileData();
            GiveAccessToUserIPFS(email, userID_to);
          }
        }
        dispatch(fetchSharedByMeFiles("aws", bucketId));
        dispatch(fetchSharedByMeFiles("ipfs"));
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setShareFileLoader(false);
          setErrEmail(err?.response?.data?.message);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  ////***** give file access  for ipfs register user */
  const GiveAccessToUserIPFS = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: shareFileUniqID,
      folder_id: FolderIDIPFS,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: bucketIdIpfs,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: Fname,
      lastName: Lname,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
      folder_name: folderNameForInsertRecordipfs,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res.data.success === true) {
          if (emailData.length === 0) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            // getFileData();
            dispatch(getBothFileData());
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            // getFileData();
            dispatch(getBothFileData());
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ////***** get user which added on company for suggestion */
  // const getAllUserData = () => {
  //   if (
  //     CompanyID === undefined ||
  //     CompanyID === "null" ||
  //     CompanyID === "" ||
  //     CompanyID === null
  //   ) {
  //     console.log("Not A Company");
  //   } else {
  //     var API_URL =
  //       GLOBAL_URL_DOMAIN_LIVE + `/company/user-count?companyId=${CompanyID}`;

  //     let headerConfig = {
  //       headers: {
  //         authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //       },
  //     };

  //     axios
  //       .get(API_URL, headerConfig)
  //       .then(function (response) {
  //         var data = response.data.companyUsers;
  //         console.log("User Count", data);
  //         var FinalData = data.filter((val) => {
  //           if (val.userID === undefined || val.userID === null) {
  //             console.log(val.userID);
  //           } else {
  //             return val.userID.email !== UserEmailId;
  //           }
  //         });
  //         setUserData(FinalData);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         if (error.response.status === 427) {
  //           sessionStorage.clear();
  //           localStorage.clear();
  //           Cookie.remove("fName");
  //           Cookie.remove("lName");
  //           Cookie.remove("userID");
  //           Cookie.remove("userToken");
  //           Cookie.remove("token_start_time");
  //           setTimeout(() => {
  //             navigate("/");
  //           });
  //         }
  //         errorToast(error.response.data.message);
  //       })
  //       .then(function () {});
  //   }
  // };
  ////***** select given access value */
  const AccessValueHandle = (val) => {
    if (val === "read") {
      setReadAccess(true);
      setWriteAccess(false);
      setDownloadAccess(false);
    } else if (val === "write") {
      setWriteAccess(true);
      setReadAccess(true);
      setDownloadAccess(false);
    } else if (val === "download") {
      setDownloadAccess(true);
      setWriteAccess(true);
      setReadAccess(true);
    }
  };
  ////***** email data onchanges func*/
  const EmailHandler = async (event) => {
    const value = event.target.value;
    setEmailexits(value.toLowerCase());
    setErrEmail("");
    if (value === "") {
      setFilteredSuggestions([]);
      setDisplaySuggestions(false);
      return;
    }
    if (value == UserEmailId) {
      isValid();
    }
    const filteredSuggestions = userData.filter(
      (suggestion, i) =>
        suggestion.userEmail.toLowerCase().includes(value.toLowerCase()) &&
        suggestion.userEmail.toLowerCase() !== UserEmailId
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
    // setErrEmail("");
    // setEmailexits(e.target.value);
  };
  ////***** select email from suggestion */
  const onSelectSuggestion = (index) => {
    setSelectedSuggestion(index);
    console.log(index);
    // var value = filteredSuggestions[index].userEmail.trim();
    // if (value && isValid(value)) {
    setEmailData([...emailData, filteredSuggestions[index].userEmail]);
    setEmailexits("");
    // }
    // setEmailexits(filteredSuggestions[index].userEmail);
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  const alreadyemail = emailData.filter(
    (item, index) => emailData.indexOf(item) !== index
  );
  ////***** accesspopup Validation */
  function isInList(value) {
    return emailData.includes(value);
  }
  function isValid() {
    if (!EMAIL_REGEX.test(emailexits)) {
      setErrEmail(<>{text_valemail}</>);
      return false;
    } else {
      setErrEmail("");
    }
    if (isInList(emailexits)) {
      setErrEmail(<>{text_alemail}</>);
      return false;
    } else {
      setErrEmail("");
    }
    if (emailData.length > 51) {
      setErrEmail(<>{text_noticeemail}</>);
    } else {
      setErrEmail("");
    }
    if (emailexits == UserEmailId) {
      setErrEmail(<>*{text_Own_email_err}!</>);
      return false;
    } else {
      setErrEmail("");
    }
    return true;
  }
  ////***** selete user from email array list */
  const handleDelete = (participants1) => {
    // console.log("called", item1);
    // console.log("delete");
    setEmailData(emailData.filter((item, i) => i !== participants1));
  };
  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        // setMeetingParticipants("");
      }
    }
  };
  ////***** Access Popup end */
  return (
    <div className="yf-ipfs-main mt-5">
      <div className={accesspopup || inviteuserPopup === true ? "bgblur" : ""}>
        {subHeaderComponent}
        {/***** get all file data show on datatableF**********/}
        {Bothloader === true ? (
          <>
            <div className="text-center">
              <Loader3 />
            </div>
          </>
        ) : (
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            fixedHeader
            selectableRowsHighlight
            highlightOnHover
            customStyles={customTableStyles}
            className="datatable-width"
          />
        )}
      </div>
      {/***** File share with user data start**********/}
      <Dialog
        open={shareDataPopup}
        onClose={() => setSharedDataPopup(false)}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: "custom-dialog" }} // Apply custom class to the dialog box
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <div className="d-flex justify-content-between">
            <h5 className="mt-1">{text_peoplewithaccess}</h5>
            <h3>
              <CgCloseO onClick={() => setSharedDataPopup(false)} />
            </h3>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h6 className="mt-1" style={{ color: "#009087" }}>
              {text_amazons3}
            </h6>
            <div className="row scroll-container">
              {/* For large screens, maintain original layout */}
              <p className="text-center ">
                {" "}
                {noRecordsFoundaws !== "" ? noRecordsFoundaws : <></>}
              </p>
              <div className="col-md-6 d-none d-md-block">
                {accessEmailAws?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                  </div>
                ))}
              </div>
              <div className="col-md-6 d-none d-md-block">
                {combinedDataAws?.map((item, i) => (
                  <div key={i} className="access-box">
                    <span className="d-flex">
                      {item?.read_access &&
                        !item?.write_access &&
                        !item?.download_access &&
                        "Read"}
                      {item?.read_access &&
                        item?.write_access &&
                        !item?.download_access &&
                        "Read/Write"}
                      {item?.read_access &&
                        item?.write_access &&
                        item?.download_access &&
                        "Read/Write/Download"}
                    </span>
                  </div>
                ))}
              </div>
              {/* For small screens, switch to stacked layout */}
              <div className="col-md-6 d-md-none ">
                {accessEmailAws?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                    {/* Render combinedData corresponding to this accessEmail */}
                    <div className="access-box">
                      <span className="d-flex">
                        {combinedDataAws[i]?.read_access &&
                          !combinedDataAws[i]?.write_access &&
                          !combinedDataAws[i]?.download_access &&
                          "Read"}
                        {combinedDataAws[i]?.read_access &&
                          combinedDataAws[i]?.write_access &&
                          !combinedDataAws[i]?.download_access &&
                          "Read/Write"}
                        {combinedDataAws[i]?.read_access &&
                          combinedDataAws[i]?.write_access &&
                          combinedDataAws[i]?.download_access &&
                          "Read/Write/Download"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
                        
            </div>
            <hr />
            <h6 className="mt-1" style={{ color: "#009087" }}>
              {text_atomstorage}
            </h6>
            <div className="row scroll-container">
              {/* For large screens, maintain original layout */}
              <p className="text-center ">
                {noRecordsFoundIpfs !== "" ? noRecordsFoundIpfs : <></>}
              </p>
              <div className="col-md-6 d-none d-md-block">
                {accessEmailIpfs?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                  </div>
                ))}
              </div>
              <div className="col-md-6 d-none d-md-block">
                {combinedDataIpfs?.map((item, i) => (
                  <div key={i} className="access-box">
                    <span className="d-flex">
                      {item?.read_access &&
                        !item?.write_access &&
                        !item?.download_access &&
                        "Read"}
                      {item?.read_access &&
                        item?.write_access &&
                        !item?.download_access &&
                        "Read/Write"}
                      {item?.read_access &&
                        item?.write_access &&
                        item?.download_access &&
                        "Read/Write/Download"}
                    </span>
                  </div>
                ))}
              </div>
              {/* For small screens, switch to stacked layout */}
              <div className="col-md-6 d-md-none">
                {accessEmailIpfs?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                    {/* Render combinedData corresponding to this accessEmail */}
                    <div className="access-box">
                      <span className="d-flex">
                        {combinedDataIpfs[i]?.read_access &&
                          !combinedDataIpfs[i]?.write_access &&
                          !combinedDataIpfs[i]?.download_access &&
                          "Read"}
                        {combinedDataIpfs[i]?.read_access &&
                          combinedDataIpfs[i]?.write_access &&
                          !combinedDataIpfs[i]?.download_access &&
                          "Read/Write"}
                        {combinedDataIpfs[i]?.read_access &&
                          combinedDataIpfs[i]?.write_access &&
                          combinedDataIpfs[i]?.download_access &&
                          "Read/Write/Download"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
                        
            </div>
          </DialogContentText>
        </DialogContent>
        <div className="d-flex justify-content-center mb-2 ">
          <button
            className="box-btn-save"
            onClick={() => setSharedDataPopup(false)}
          >
            {text_done}
          </button>
        </div>
      </Dialog>
      {/***** File share with user data end**********/}
      {/***** File Access data start**********/}
      <Dialog
        open={accesspopup}
        onClose={closeAccessPopup}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center w-100">
          <div className="d-flex justify-content-between">
            <h5 className="mt-1">
              {text_share}{" "}
              {Filename === "" ? (
                <>
                  <img
                    src={folderImg}
                    alt=""
                    style={{
                      height: "21px",
                      width: "21px",
                      marginBottom: "3px",
                    }}
                  />{" "}
                  {folderName}
                </>
              ) : (
                <>
                  <img
                    src={fileImg}
                    alt=""
                    style={{
                      height: "19px",
                      width: "19px",
                      marginBottom: "3px",
                    }}
                  />{" "}
                  {Filename}
                </>
              )}{" "}
              To
            </h5>
            <h3>
              <CgCloseO onClick={() => closeAccessPopup()} />
            </h3>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="d-flex flex-wrap">
              <div>
                <div className="participants_box">
                  <div className="tags-input">
                    {emailData &&
                      emailData.map((dataitem, i) => (
                        <li key={i} className="tag">
                          <span className="tag-title">{dataitem}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => handleDelete(i)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    <input
                      className="user-input"
                      type="text"
                      onKeyPress={handleKeyDown}
                      onChange={EmailHandler}
                      value={emailexits}
                      placeholder={
                        emailData.length === 0 && text_enteruseremail
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    filteredSuggestions.length === 0 ? "" : "suggestion-list"
                  }
                >
                  {displaySuggestions && (
                    <>
                      {filteredSuggestions.map((suggestion, index) => {
                        return (
                          <div className="suggestion" key={index}>
                            <li
                              className="li-suggestion"
                              onClick={() => onSelectSuggestion(index)}
                            >
                              {suggestion.userEmail}
                            </li>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <select
                className="accessinput access-select mx-4 "
                onChange={(e) => [AccessValueHandle(e.target.value)]}
              >
                <option value="" disabled>
                  {text_selaccessoption}
                </option>
                <option value="read">{text_read}</option>
                <option value="write">
                  {text_read}/{text_write}
                </option>
                <option value="download" selected>
                  {text_read}/{text_write}/{text_download}
                </option>
              </select>
            </div>
            <p className="mx-3 errMsg">{errEmail}</p>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <div className="d-flex justify-content-center gap-4 mt-2 mb-3">
          <button className="box-btn" onClick={() => closeAccessPopup()}>
            {text_cancel}
          </button>
          <button
            className="box-btn-save"
            onClick={() =>
              emailData.length === 0 ? handleSingleUser() : handleGetUser()
            }
            disabled={shareFileLoader || errEmail !== "" ? true : false}
          >
            {shareFileLoader ? <Loader2 /> : text_share}
          </button>
        </div>
      </Dialog>
      {/***** File Access data start**********/}
      <ToastContainer />
    </div>
  );
};

export default YourFilesBoth;
