export const customTableStyles = {
  rows: {
    style: {
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      border: "0.5px solid rgb(215, 215, 215)",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "uppercase",
      border: "0.5px solid rgb(215, 215, 215)",
      backgroundColor: "#009087",
      color: "#FFFFFF",
    },
  },
  title: {
    style: {
      overflow: "none",
    },
  },
};

export const customTableStylesFile = {
  rows: {
    style: {
      fontSize: "16px",
      border: "none",
      borderBottom: "none",
    },
  },
  cells: {
    style: {
      // border: "0.5px solid rgb(215, 215, 215)",
      borderBottom: "0.5px solid rgb(215, 215, 215)",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "uppercase",
      // border: "0.5px solid rgb(215, 215, 215)",
      backgroundColor: "#009087",
      color: "#FFFFFF",
    },
  },
  title: {
    style: {
      overflow: "none",
    },
  },
};

export const customTableStylesFileBoth = {
  rows: {
    style: {
      fontSize: "16px",
      border: "none",
      borderBottom: "none",
    },
  },
  cells: {
    style: {
      // border: "0.5px solid rgb(215, 215, 215)",
      borderBottom: "0.5px solid rgb(215, 215, 215)",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "uppercase",
      // border: "0.5px solid rgb(215, 215, 215)",
      backgroundColor: "#009087",
      color: "#FFFFFF",
    },
  },
  title: {
    style: {
      overflow: "none",
    },
  },
};
