import { toast } from "react-toastify";

export function successToast(message, autoclosetime) {
  toast.success(message, {
    position: "top-right",
    autoClose: autoclosetime,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
  });
}

export function errorToast(message, autoclosetime) {
  toast.error(message, {
    position: "top-right",
    autoClose: autoclosetime,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
  });
}
export function InfoToast(message, autoclosetime) {
  toast.info(message, {
    position: "top-right",
    autoClose: autoclosetime,
    closeOnClick: true,
    pauseOnHover: false,
    progress: undefined,
  });
}

export function warnToast(message, autoclosetime) {
  toast.warn(message, {
    position: "top-right",
    autoClose: autoclosetime,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}
