import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = ({ Component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    let token = sessionStorage.getItem("userToken");
    if (!token) {
      navigate("/");
      sessionStorage.clear();
      localStorage.clear();
      // do i need to clear cookie as well?
    }
  });
  return <Component />;
};

export default Protected;
