import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
const initialState = {
  data: {},
  loading: false,
  error: null,
};
const EditFileDataSlice = createSlice({
  name: "EditFileData",
  initialState,
  reducers: {
    fetchEditFileDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchEditFileDataSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchEditFileDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetEditFileData(state) {
      return initialState;
    },
  },
});
export const {
  fetchEditFileDataStart,
  fetchEditFileDataSuccess,
  fetchEditFileDataFailure,
  resetEditFileData,
} = EditFileDataSlice.actions;

export const fetchEditFileData = (fileId) => async (dispatch) => {
  try {
    dispatch(fetchEditFileDataStart());
    const headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    const response = await axios.get(
      `${GLOBAL_URL_DOMAIN_LIVE}/file/filedata-by-id?fileId=${fileId}`,
      headerConfig
    );
    dispatch(fetchEditFileDataSuccess(response?.data?.data));
  } catch (error) {
    if (error?.response && error?.response?.status === 427) {
      sessionStorage.clear();
      localStorage.clear();
      Cookie.remove("fName");
      Cookie.remove("lName");
      Cookie.remove("userID");
      Cookie.remove("userToken");
      Cookie.remove("token_start_time");
      window.location.replace("/");
    }
    dispatch(fetchEditFileDataFailure(error?.response?.data));
  }
};

export default EditFileDataSlice.reducer;

// export function resetEditFileData() {
//   return async function resetEditFileDataThunk(dispatch, getState) {
//     try {
//       dispatch(fetchEditFileDataFailure());
//     } catch (err) {
//       console.log(err);
//     }
//   };
// }
