import React from "react";
import "./ContactUs.css";
import Header from "../../Components/Header/Header";

const ContactUs = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default ContactUs;
