import React, { useContext, useEffect, useRef, useState } from "react";
import "./ProgressBox.css";
import ProgressBoxContext from "../../Context/ProgressBoxContext";
import ProgressBar from "react-bootstrap/ProgressBar";
import FileImg from "../../utils/images/file_1.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { partial } from "filesize";
import { CgCloseO } from "react-icons/cg";
import { IoReload } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import fileImg from "../../utils/images/file_1.png";
import Cookie from "js-cookie";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import axios from "axios";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import Loader2 from "../Loader2/Loader2";
import { FaCheckCircle } from "react-icons/fa";

import { GlobalConstants } from "../../utils/globalconstant";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const ProgressBox = () => {
  const {
    message,
    uploadLocation,
    uploadStatus,
    allFileData,
    uploadFrom,
    uploadStatusMulti,
    allFileDataMulti,
    fileFolderData,
    keyFolder,
  } = useContext(ProgressBoxContext);
  ///***abortcontroller for cancel file*** */
  const abortController = useRef(null);

  var UserF_Name = sessionStorage.getItem("fName");
  var UserL_name = sessionStorage.getItem("lName");

  const size = partial({ base: 2, standard: "jedec" });
  const userID = sessionStorage.getItem("userID");
  const useremail = sessionStorage.getItem("email");
  const CompanyID = sessionStorage.getItem("companyID");
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  ///****Variable****/
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file_id, setFile_id] = useState("");
  const [fileUniqueName, setFileUniqueName] = useState("");
  const [file_id_multi, setFile_id_multi] = useState([]);
  const [cancelIndex, setCancelIndex] = useState([]);
  const [retryIndex, setRetryIndex] = useState([]);
  const [accesspopup, setAccesspopup] = useState(false);
  const [singleMetadata, setsingleMetadata] = useState([]);
  const [multiMetadata, setMultiMetadata] = useState([]);
  const [MultiFileandFolderID, setMultiFileandFolderID] = useState([]);
  const [MultiFileandFolderIDAWS, setMultiFileandFolderIDAWS] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [errEmail, setErrEmail] = useState("");
  const [emailexits, setEmailexits] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [userData, setUserData] = useState([]);
  const [readAccess, setReadAccess] = useState(true);
  const [writeAccess, setWriteAccess] = useState(true);
  const [downloadAccess, setDownloadAccess] = useState(true);
  const [shareFileLoader, setShareFileLoader] = useState(false);
  const [FileUploadCancel, setFileUploadCancel] = useState("");
  const [FileUploadCancelAtom, setFileUploadCancelAtom] = useState("");
  const [retryError, setRetryError] = useState("");
  const [closeProgressBox, setCloseProgressBox] = useState(false);
  const [totalFilecancled, setTotalFileCancled] = useState(1);
  const [abortControllers, setAbortControllers] = useState([]);
  const [retryIcon, setRetryIcon] = useState(false);
  const [retryFile, setRetryFile] = useState(false);
  const [multi_pro, setMulti_pro] = useState([]);
  const [uploadFolder, setUploadFolder] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [retryIconMultiple, setRetryIconMultiple] = useState(
    Array(multi_pro.length).fill(false)
  );
  const [othe_data_2, setOther_data_2] = useState([]);
  const [other_data_2_ipfs, setOther_data_2_ipfs] = useState([]);
  const [folderArr, setfolderArr] = useState([]);
  const [folderArr_ipfs, setfolderArr_ipfs] = useState([]);
  const [retryFileMultiple, setRetryFileMultiple] = useState(
    Array(multi_pro.length).fill(false)
  );
  const [FolderIds, setFolderIds] = useState([]);
  const [folderIdSingleAws, setFolderIdSingleAws] = useState("");
  const [folderIdSingleIpfs, setFolderIdSingleIpfs] = useState("");
  const [hashSingle, setHashSingle] = useState("");
  const [showProgressBox, setshowProgressbox] = useState("");
  const [folderNameForInsertRecordaws, setFolderNameForInsertRecordaws] =
    useState("");
  const [folderNameForInsertRecordipfs, setFolderNameForInsertRecordipfs] =
    useState("");
  //language start
  const [text_selectaccesstype, setText_selectaccesstype] =
    useState("Select Access Type");
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_download, setText_download] = useState("Download");
  const [text_validationemail, setText_validationemail] = useState(
    "Please Enter User Email!"
  );
  const [text_alemail, setText_alemail] = useState(
    "*Email has already been added!"
  );
  const [text_noticeemail, settext_noticeemail] = useState(
    "*You cannot give access to more than 50 emails at once!"
  );
  const [text_valemail, setText_valemail] = useState("*Enter valid email Id!");

  const [text_Own_email_err, setText_Own_email_err] = useState(
    "You can not give access to your own email"
  );
  const [text_enteruseremail, setText_enteruseremail] =
    useState("Enter User Email");
  const [text_skip, setText_skip] = useState("Skip");
  const [text_share, setText_share] = useState("Share");

  //language end
  useEffect(() => {
    window.onbeforeunload = function () {
      return (
        sessionStorage.setItem("ProgressBox", false),
        sessionStorage.removeItem("successUploadFile"),
        sessionStorage.removeItem("cancel_file")
      );
    };
  }, []);
  ///****Access select Option****/
  const AccessValueHandle = (val) => {
    if (val === "read") {
      setReadAccess(true);
      setWriteAccess(false);
      setDownloadAccess(false);
    } else if (val === "write") {
      setWriteAccess(true);
      setReadAccess(true);
      setDownloadAccess(false);
    } else if (val === "download") {
      setDownloadAccess(true);
      setWriteAccess(true);
      setReadAccess(true);
    }
  };

  ///****Close Access Popup****/
  const closeAccessPopup = () => {
    setAccesspopup(false);
    sessionStorage.setItem("ProgressBox", false);
    setTimeout(function () {
      window.location.replace("/my-files");
    }, 3000);

    setEmailexits("");
    setEmailData([]);
    setDownloadAccess(true);
    setWriteAccess(true);
    setDisplaySuggestions(false);
    setReadAccess(true);
    setErrEmail("");
    setFilteredSuggestions([]);
  };

  console.log(allFileData);
  ///****Get user [Added on Company]****/
  const getAllUserData = () => {
    if (
      CompanyID === undefined ||
      CompanyID === "null" ||
      CompanyID === "" ||
      CompanyID === null
    ) {
      console.log("Not A Company");
    } else {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/company/user-count?companyId=${CompanyID}`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      axios
        .get(API_URL, headerConfig)
        .then(function (response) {
          var data = response.data.companyUsers;
          console.log("User Count", data);
          var FinalData = data.filter((val) => {
            if (val.userID === undefined || val.userID === null) {
              console.log(val.userID);
            } else {
              return val.userID.email !== useremail;
            }
          });
          setUserData(FinalData);
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
          errorToast(error.response.data.message);
        })
        .then(function () {});
    }
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  ///****Multiple Data Upload start */
  useEffect(() => {
    if (multi_pro.length > 0) {
      handleMultipleUpload(uploadLocation, 0);
    }
  }, [multi_pro]);

  const setMultiPro_data_new = async (uploadStatusMulti) => {
    const multi_progress_temp_arr = [];
    for (let i = 0; i < uploadStatusMulti[0]?.length; i++) {
      multi_progress_temp_arr.push({
        index: i,
        progress: 0,
        filename: uploadStatusMulti[0][i]?.name,
        message: "",
        Cancel_id: "",
      });
    }
    // setMulti_pro(multi_progress_temp_arr);
    console.log(multi_progress_temp_arr);
  };
  ///****Multiple Data Upload end */

  ///*** handle multi and single data upload function call from here */
  useEffect(() => {
    console.log(uploadFrom);
    if (uploadFrom === "multiple") {
      setMultiPro_data_new(uploadStatusMulti);
    } else {
      handleSingleUpload(uploadLocation);
    }
  }, [uploadFrom]);

  //multiple file upload
  const handleMultipleUpload = (location, file_index) => {
    // Determining bucket name based on upload location
    var Bucket_name = "";
    if (allFileDataMulti !== undefined) {
      Bucket_name =
        location === "ipfs"
          ? "atom-storage"
          : allFileDataMulti[0]?.awsBucketName;
    }
    // Checking if any files are selected for upload
    console.log(uploadStatusMulti);
    if (uploadStatusMulti[0]?.length === 0 || uploadStatusMulti === undefined) {
      alert("Please select a file to upload.");
      return;
    }
    // Function to upload file chunks
    const uploadFileChunks = async (fileIndex) => {
      console.log(multi_pro.find((data) => data.Cancel_id === fileIndex));

      if (multi_pro.find((data) => data.Cancel_id === fileIndex)) {
        uploadFileChunks(fileIndex + 1);
      } else {
        // one by one files uploaded
        if (fileIndex >= uploadStatusMulti[0].length) {
          return;
        }
        const abortController = new AbortController();
        setAbortControllers((prev) => [...prev, abortController]);
        const file = uploadStatusMulti[0][fileIndex];
        console.log(file, uploadStatusMulti[0], fileIndex);
        const uniqueID = uuidv4();
        const fileNameWithUuid = uniqueID + "_" + file.name;
        const file_name_with_hyphen = file.name?.replace(/ /g, "-");
        const unique_fileName = uniqueID + "_" + file_name_with_hyphen;

        const file_id_prefix =
          location === "aws" ? "1_0_" : location === "ipfs" ? "0_1_" : "1_1_";
        const file_unique_id =
          file_id_prefix + uniqueID + "_" + file_name_with_hyphen;

        console.log("name multiple:", unique_fileName);
        console.log("id multiple:", file_unique_id);

        const data = file.path.lastIndexOf("/");
        const str_data = file.path.slice(1, data + 1);
        console.log(allFileDataMulti);

        const data_folder = allFileDataMulti[0].awsFolder.indexOf("/");
        const str_data_folder = allFileDataMulti[0].awsFolder.slice(
          data_folder + 1
        );
        const Final_aws_folder =
          allFileDataMulti[0].awsFolder === `${userID}/`
            ? `${userID}/`
            : str_data_folder;
        const final_folder_aws =
          uploadLocation === "ipfs" ? "" : `${Final_aws_folder}${str_data}`; //Folder name send on formdata AWS
        const final_folder_ipfs =
          uploadLocation === "aws"
            ? ""
            : `${allFileDataMulti[0].FolderNameIPFS}${str_data}`; //Folder name send on formdata IPFS
        const File_Folder_aws =
          allFileDataMulti[0].awsFolder === final_folder_aws
            ? "file"
            : "folder";
        const File_Folder_ipfs =
          allFileDataMulti[0].FolderNameIPFS === final_folder_ipfs
            ? "file"
            : "folder";

        setFile_id_multi((prev) => [
          ...prev,
          {
            fileid: file_unique_id,
            index: fileIndex,
            filename: unique_fileName,
            foldernameaws:
              uploadLocation === "ipfs" ? final_folder_ipfs : final_folder_aws,
          },
        ]);
        const chunkSize = 10 * 1024 * 1024; // 10 MB (adjust based on your requirements)
        const totalChunks = Math.ceil(file.size / chunkSize);

        let chunkNumber = 0;
        let start = 0;
        let end = chunkSize;

        abortController.current = new AbortController();

        const uploadNextChunk = async () => {
          if (end <= file.size + chunkSize) {
            const chunk = file.slice(start, end);

            console.log("chunk***", chunk);

            if (chunk === 0) {
              return;
            }
            if (chunk.size === 0) {
              console.log(
                "chunk size is 0. return from here... (multiple files)"
              );
              return;
            }

            const formData = new FormData();

            formData.append("user_id", userID);
            formData.append("ipfsfile", chunk);
            formData.append("fileName", unique_fileName);
            formData.append("chunkNumber", chunkNumber);
            formData.append("totalChunks", totalChunks);
            formData.append("file_id", file_unique_id);
            formData.append("parent_file_hash", "hashDataMulti[i]");
            formData.append("per_chunk_size", "10MB");
            formData.append("size_in_bytes", file.size);
            formData.append("remoteupload", location);
            formData.append("bucketName", Bucket_name);
            formData.append("folderName", final_folder_aws);
            formData.append("bucketIdAWS", allFileDataMulti[0].bucketId);
            formData.append("ipfsFolderName", final_folder_ipfs);
            formData.append("bucketIdIPFS", allFileDataMulti[0].bucketIdIpfs);
            formData.append("bucketNameIPFS", "atom-storage");
            formData.append("companyId", CompanyID);

            fetch(GLOBAL_URL_DOMAIN_File_Upload_Live + "/file/upload", {
              signal: abortController.signal,
              method: "POST",
              headers: {
                authorization: "Bearer " + sessionStorage.getItem("userToken"),
              },
              body: formData,
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                console.log({ data });
                //if get error than cancel file
                if (data.success === false) {
                  cancelUpload(fileIndex);
                  uploadFileChunks(fileIndex + 1);
                } else {
                  if (data.DataIpfs || data.DataAws) {
                    if (location === "ipfs" && data.DataIpfs) {
                      if (data?.DataIpfs?.success === true) {
                        //if upload ipfs data success call metadataipfs
                        if (location === "ipfs") {
                          handleUploadMetadataIPFS(
                            data?.DataIpfs?.data?.Hash,
                            fileNameWithUuid,
                            file,
                            fileIndex,
                            final_folder_ipfs,
                            data?.DataIpfs?.ipfsFolderId,
                            "",
                            "",
                            "multiple",
                            File_Folder_ipfs,
                            ""
                          );
                          //uploaded data is file or folder
                          if (File_Folder_ipfs === "file") {
                            setUploadFile(true);
                          }
                          if (File_Folder_ipfs === "folder") {
                            setUploadFolder(true);
                          }
                        }
                      } else if (
                        data.DataIpfs.error &&
                        data.DataIpfs.success == false &&
                        data.DataIpfs.progress === 1
                      ) {
                        console.log("RETRY");
                        //if upload aws get error than call retry
                        FolderIds.push({
                          awsFolderId: data?.DataAws?.awsFolderId,
                          ipfsFolderId: data?.DataIpfs?.ipfsFolderId,
                          index: fileIndex,
                          fileHash: data?.DataIpfs?.data?.Hash,
                        });
                        console.log(FolderIds);
                        setFolderIds(FolderIds);
                        setRetryIconMultiple((prev) => {
                          const updatedRetryIcons = [...prev];
                          updatedRetryIcons[fileIndex] = true;
                          return updatedRetryIcons;
                        });
                        uploadFileChunks(fileIndex + 1); //next file upload start
                      }
                    }
                    if (location === "aws" && data.DataAws) {
                      if (
                        data.DataAws.error &&
                        data.DataAws.error.success == false
                      ) {
                        if (data.DataAws.error.progress === 1) {
                          console.log("RETRY");
                          //if upload aws get error than call retry
                          FolderIds.push({
                            awsFolderId: data?.DataAws?.awsFolderId,
                            ipfsFolderId: data?.DataIpfs?.ipfsFolderId,
                            index: fileIndex,
                            fileHash: data?.DataIpfs?.data?.Hash,
                          });
                          console.log(FolderIds);
                          setFolderIds(FolderIds);
                          setRetryIconMultiple((prev) => {
                            const updatedRetryIcons = [...prev];
                            updatedRetryIcons[fileIndex] = true;
                            return updatedRetryIcons;
                          });
                          uploadFileChunks(fileIndex + 1); //next file upload start
                        }
                      } else {
                        console.log("aws", location);

                        if (location === "aws") {
                          //if upload aws data success than call metadataaws
                          console.log("aws", location);
                          handleUploadMetadataAWS(
                            data?.DataAws?.Key,
                            data?.DataAws?.Location,
                            fileNameWithUuid,
                            file,
                            fileIndex,
                            final_folder_aws,
                            data?.DataAws?.awsFolderId,
                            final_folder_ipfs,
                            data?.DataIpfs?.ipfsFolderId,
                            "multiple",
                            "",
                            File_Folder_aws,
                            ""
                          );
                          //uploaded data is file or folder
                          if (File_Folder_aws === "file") {
                            setUploadFile(true);
                          }
                          if (File_Folder_aws === "folder") {
                            setUploadFolder(true);
                          }
                        }
                      }
                    }
                    if (location === "both" && data.DataAws && data.DataIpfs) {
                      if (
                        data.DataAws.error &&
                        data.DataAws.error.success == false
                      ) {
                        if (data.DataAws.error.progress === 1) {
                          //error on upload  than call retry func
                          console.log("RETRY");
                          FolderIds.push({
                            awsFolderId: data?.DataAws?.awsFolderId,
                            ipfsFolderId: data?.DataIpfs?.ipfsFolderId,
                            index: fileIndex,
                            fileHash: data?.DataIpfs?.data?.Hash,
                          });
                          console.log(FolderIds);
                          setFolderIds(FolderIds);

                          setRetryIconMultiple((prev) => {
                            const updatedRetryIcons = [...prev];
                            updatedRetryIcons[fileIndex] = true;
                            return updatedRetryIcons;
                          });
                          uploadFileChunks(fileIndex + 1);
                        }
                      } else if (data.success === true) {
                        console.log(data);
                        //upload success than call metadataAWS
                        handleUploadMetadataAWS(
                          data?.DataAws?.Key,
                          data?.DataAws?.Location,
                          fileNameWithUuid,
                          file,
                          fileIndex,
                          final_folder_aws,
                          data?.DataAws?.awsFolderId,
                          final_folder_ipfs,
                          data?.DataIpfs?.ipfsFolderId,
                          "multiple",
                          data?.DataIpfs?.data?.Hash,
                          File_Folder_aws,
                          ""
                        );
                        //uploaded data is file or folder
                        if (
                          File_Folder_aws === "file" ||
                          File_Folder_ipfs === "file"
                        ) {
                          setUploadFile(true);
                        }
                        if (
                          File_Folder_aws === "folder" ||
                          File_Folder_ipfs === "folder"
                        ) {
                          setUploadFolder(true);
                        }
                      }
                    }
                  }

                  setUploadProgress(
                    Math.floor(((chunkNumber + 1) / totalChunks) * 100)
                  );
                  const ind = multi_pro.findIndex(
                    (value) => value.index === fileIndex
                  );
                  if (ind !== -1) {
                    multi_pro[fileIndex].progress = Math.floor(
                      ((chunkNumber + 1) / totalChunks) * 100
                    );
                  }

                  if (data?.DataIpfs?.success === true) {
                    console.log("========== additional if block ==========");
                    multi_pro[fileIndex].progress = 100;
                  }
                  if (data?.DataIpfs?.success === false) {
                    multi_pro[fileIndex].progress = -1;
                  }
                  if (data?.DataAws) {
                    console.log("========== additional if block ==========");
                    multi_pro[fileIndex].progress = 100;
                  }

                  chunkNumber++;
                  start = end;
                  end = start + chunkSize;
                  uploadNextChunk();
                  if (successfulUploadsAWS !== 0) {
                    sessionStorage.setItem(
                      "successfulUploadsAWS",
                      successfulUploadsAWS + 1
                    );
                  }
                }
              })
              .catch((error) => {
                console.error("Error uploading chunk:", error);
                if (error?.response?.status === 400) {
                  errorToast("Error While uploading. Something went wrong");
                  sessionStorage.setItem("ProgressBox", false);
                  window.location.reload();
                }
                if (error?.response?.status === 427) {
                  sessionStorage.clear();
                  localStorage.clear();
                  Cookie.remove("fName");
                  Cookie.remove("lName");
                  Cookie.remove("userID");
                  Cookie.remove("userToken");
                  Cookie.remove("token_start_time");
                  window.location.replace("/");
                }
              });
          } else {
            setUploadProgress(100);
            const ind = multi_pro.findIndex(
              (value) => value.index === fileIndex
            );
            if (ind !== -1) {
              multi_pro[fileIndex].progress = 100;
            }
          }
        };
        uploadNextChunk();
      }
    };
    var Index_file =
      file_index === "" || file_index === null || file_index === undefined
        ? 0
        : file_index;
    uploadFileChunks(Index_file); //upload file func call start from here
  };

  //Single file upload
  const handleSingleUpload = (location) => {
    // Determining bucket name based on upload location
    var Bucket_name = "";
    if (allFileData !== undefined) {
      Bucket_name =
        location === "ipfs" ? "atom-storage" : allFileData[0]?.awsBucketName;
    }
    // Generating unique IDs and names for the file
    const file_name_with_hyphen = uploadStatus.name?.replace(/ /g, "-");
    const uniqueID = uuidv4();
    const unique_fileName = uniqueID + "_" + file_name_with_hyphen;
    const fileNameWithUuid = uniqueID + "_" + uploadStatus?.name;
    const file_id_prefix =
      location === "aws" ? "1_0_" : location === "ipfs" ? "0_1_" : "1_1_";
    const file_unique_id =
      file_id_prefix + uniqueID + "_" + file_name_with_hyphen;
    // Logging file details
    console.log("name single:", unique_fileName);
    console.log("id single:", file_unique_id);
    // Setting file IDs and unique names
    setFile_id(file_unique_id);
    setFileUniqueName(unique_fileName);
    console.log(uploadStatus.size);
    // Setting chunk size for uploading
    const chunkSize = 10 * 1024 * 1024; // 10 MB (adjust based on your requirements)
    const totalChunks = Math.ceil(uploadStatus.size / chunkSize);

    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;

    abortController.current = new AbortController();

    const uploadNextChunk = async () => {
      // Uploading next chunk if there are more chunks remaining
      if (start < uploadStatus.size) {
        const chunk = uploadStatus.slice(start, end);
        // Extracting necessary data for folder names
        const str_aws =
          allFileDataMulti === undefined ? "" : allFileDataMulti[0]?.awsFolder;
        const ind_aws = str_aws.indexOf("/");
        const new_str_aws = str_aws.slice(ind_aws + 1);
        console.log(new_str_aws);

        var Folder_name = allFileData[0].awsFolder;

        const formData = new FormData();
        // Appending data to the form for upload
        formData.append("user_id", userID);
        formData.append("ipfsfile", chunk);
        formData.append("fileName", unique_fileName);
        formData.append("chunkNumber", chunkNumber);
        formData.append("totalChunks", totalChunks);
        formData.append("file_id", file_unique_id);
        formData.append("parent_file_hash", "hashdata");
        formData.append("per_chunk_size", "10MB");
        formData.append("size_in_bytes", uploadStatus.size);
        formData.append("remoteupload", location);
        formData.append("bucketName", Bucket_name);
        formData.append("folderName", Folder_name);
        formData.append("bucketIdAWS", allFileData[0].bucketId);
        formData.append("ipfsFolderName", allFileData[0].FolderNameIPFS);
        formData.append("bucketIdIPFS", allFileData[0].bucketIdIpfs);
        formData.append("bucketNameIPFS", "atom-storage");
        formData.append("companyId", CompanyID);
        // Sending fetch request for file upload
        fetch(GLOBAL_URL_DOMAIN_File_Upload_Live + "/file/upload", {
          signal: abortController.current.signal,
          method: "POST",
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            // Handling response from the server
            console.log("data***", data);
            console.log("location***", location);
            // Setting folder IDs and hashes for IPFS and AWS
            setFolderIdSingleIpfs(data?.DataIpfs?.ipfsFolderId);
            setFolderIdSingleAws(data?.DataAws?.awsFolderId);
            setHashSingle(data?.DataIpfs?.data?.Hash);
            // Handling upload success or failure
            if (data.success === false) {
              cancelUploadSingle();
            } else {
              if (location === "ipfs" && data.DataIpfs) {
                if (data.DataIpfs.success === true) {
                  if (location === "ipfs") {
                    //upload success than call metadataIPFS
                    handleUploadMetadataIPFS(
                      data?.DataIpfs?.data?.Hash,
                      fileNameWithUuid,
                      uploadStatus,
                      "",
                      allFileData[0].ipfsfoldername,
                      data?.DataIpfs?.ipfsFolderId,
                      allFileData[0].awsFolder,
                      data?.DataAws?.awsFolderId,
                      "single",
                      "",
                      ""
                    );
                  }
                } else if (
                  data.DataIpfs.success === false &&
                  data.DataIpfs.error &&
                  data.DataIpfs.progress === 1
                ) {
                  console.log("RETRY");
                  setRetryIcon(true);
                }
              }
              if (location === "aws" && data.DataAws) {
                if (
                  data.DataAws.error &&
                  data.DataAws.error.progress === 1 &&
                  data.DataAws.error.success == false
                ) {
                  //upload give error than call retry func
                  console.log("RETRY");
                  setRetryIcon(true);
                } else if (data.success === true) {
                  if (location === "aws") {
                    //upload success than call metadataAWS
                    handleUploadMetadataAWS(
                      data?.DataAws?.Key,
                      data?.DataAws?.Location,
                      fileNameWithUuid,
                      uploadStatus,
                      "",
                      allFileData[0].awsFolder,
                      data?.DataAws?.awsFolderId,
                      allFileData[0].ipfsfoldername,
                      data?.DataIpfs?.ipfsFolderId,
                      "single",
                      ""
                    );
                  }
                }
              }
              if (location === "both" && data.DataAws && data.DataIpfs) {
                console.log(
                  "object*****",
                  data.DataIpfs.success,
                  data.DataAws.success
                );
                if (
                  data.DataAws.error &&
                  data.DataAws.error.progress === 1 &&
                  data.DataAws.error.success == false
                ) {
                  //upload error than call retry func
                  console.log("RETRY");
                  setRetryIcon(true);
                }
                if (data.DataAws.success === true) {
                  //upload success than call metadataAWS
                  handleUploadMetadataAWS(
                    data?.DataAws?.Key,
                    data?.DataAws?.Location,
                    fileNameWithUuid,
                    uploadStatus,
                    "",
                    allFileData[0].awsFolder,
                    data?.DataAws?.awsFolderId,
                    allFileData[0].ipfsfoldername,
                    data?.DataIpfs?.ipfsFolderId,
                    "single",
                    data?.DataIpfs?.data?.Hash
                  );
                }
                if (
                  data.DataIpfs.success === false ||
                  data.DataAws.success === false
                ) {
                  console.log("ipfs error:", data.DataIpfs.message);
                }
              }
              // Setting upload progress
              setUploadProgress(
                Math.floor(((chunkNumber + 1) / totalChunks) * 100)
              );
              // Updating chunk number and ranges for next chunk
              chunkNumber++;
              start = end;
              end = Math.min(start + chunkSize, uploadStatus.size);
              // Uploading next chunk
              uploadNextChunk();
            }
          })
          .catch((error) => {
            // Handling errors during upload process
            if (error.name === "AbortError") {
              console.error("File upload cancelled..........");
              // Handling session expiration error
              if (error?.response?.status === 427) {
                sessionStorage.clear();
                localStorage.clear();
                Cookie.remove("fName");
                Cookie.remove("lName");
                Cookie.remove("userID");
                Cookie.remove("userToken");
                Cookie.remove("token_start_time");
                window.location.replace("/");
              }
            }
            console.error("Error uploading chunk:", error);
            // Handling specific error code 400
            if (error?.response?.status === 400) {
              errorToast("Error While uploading. Something went wrong");
              sessionStorage.setItem("ProgressBox", false);
              window.location.reload();
            }
          });
      }
    };
    // Initiating upload of first chunk
    uploadNextChunk();
  };
  //****Retry func for single */
  const handleRetry = async () => {
    // Setting states to indicate retry process starting
    setRetryFile(true);
    setRetryIcon(false);

    // Determining the bucket name based on the upload location
    var bucket_name =
      uploadLocation === "aws" ? allFileData[0]?.awsBucketName : "atom-storage";
    try {
      // Constructing the API URL
      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/retry-upload`;

      // Creating data object with information needed for retry upload
      const data = {
        fileName: fileUniqueName,
        file_id: file_id,
        remoteupload: uploadLocation,
        bucketName: bucket_name,
        folderName: allFileData[0]?.awsFolder,
      };

      // Constructing header configuration with user authorization token
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      // Sending POST request to retry upload API endpoint
      const res = await axios.post(API_URL, data, headerConfig);
      // Logging the response from the API
      console.log(" res***", res);
      // Resetting retry state
      setRetryFile(false);
      // Handling successful retry upload
      if (res.data.success === true) {
        console.log("Retry upload data successfully ");
        setRetryFile(true);
        const uniqueID = uuidv4();

        const fileNameWithUuid = uniqueID + "_" + uploadStatus?.name;
        // Handling upload to AWS if upload location is AWS
        if (
          uploadLocation === "aws" &&
          res?.data?.retryUploadDataAWS?.Location !== ""
        ) {
          // Handling metadata upload to AWS
          handleUploadMetadataAWS(
            res?.data?.retryUploadDataAWS?.Key,
            res?.data?.retryUploadDataAWS?.Location,
            fileNameWithUuid,
            uploadStatus,
            "",
            allFileData[0].awsFolder,
            folderIdSingleAws,
            allFileData[0].ipfsfoldername,
            folderIdSingleIpfs,
            "single",
            "",
            "Retry"
          );
        }
        if (
          uploadLocation === "ipfs" &&
          res?.data?.retryUploadDataIPFS?.Hash !== ""
        ) {
          // Handling metadata upload to IPFS
          handleUploadMetadataIPFS(
            res?.data?.retryUploadDataIPFS?.Hash,
            fileNameWithUuid,
            uploadStatus,
            "",
            allFileData[0].ipfsfoldername,
            folderIdSingleIpfs,
            allFileData[0].awsFolder,
            folderIdSingleAws,
            "single",
            "",
            "Retry"
          );
        }
        // Handling upload to both AWS and IPFS if upload location is both
        if (
          uploadLocation === "both" &&
          res?.data?.retryUploadDataAWS?.Location !== "" &&
          res?.data?.retryUploadDataIPFS?.Hash !== ""
        ) {
          // Handling metadata upload to AWS
          handleUploadMetadataAWS(
            res?.data?.retryUploadDataAWS?.Key,
            res?.data?.retryUploadDataAWS?.Location,
            fileNameWithUuid,
            uploadStatus,
            "",
            allFileData[0].awsFolder,
            allFileData[0].FolderId,
            allFileData[0].ipfsfoldername,
            allFileData[0].FolderIDIPFS,
            "single",
            hashSingle,
            "Retry"
          );
        }
      }
      // Handling unsuccessful retry upload
      else if (
        (res.data.retryUploadDataAWS.success === false &&
          res.data.retryUploadDataAWS.progress === 1) ||
        (res.data.retryUploadDataIPFS.success === false &&
          res.data.retryUploadDataIPFS.progress === 1)
      ) {
        setRetryError("Upload Failed");
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      // Resetting retry state
      setRetryFile(false);
    } catch (err) {
      // Catching any errors that occur during the retry process
      console.log(" err***", err);
      // Handling specific error code 427 - indicating session expiration
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      }
      // Setting error state and reloading the page after a delay
      setRetryError("Upload Failed");
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("totalFilecancled");
    sessionStorage.removeItem("successfulUploadsAWS");
    sessionStorage.removeItem("successUploadFile");
    sessionStorage.removeItem("cancel_file");
    sessionStorage.removeItem("retry_file");
  }, []);
  //****Retry func for multiple */
  const handleRetryMultiple = async (i) => {
    // Logging that multiple files are being retried
    console.log("Multiple files retry");
    const file = uploadStatusMulti[0][i];
    // Retrieving file information and folder IDs based on index
    const folderIdObj = FolderIds.find((item) => item.index === i);
    // Handling case where folder ID is not found
    if (!folderIdObj) {
      console.error("Folder ID not found for index:", i);
      return;
    }
    // Extracting necessary data for constructing paths and folder names
    const { awsFolderId, ipfsFolderId, fileHash } = folderIdObj;
    console.log(folderIdObj);
    const data = file.path.lastIndexOf("/");
    const str_data = file.path.slice(1, data + 1);
    console.log(allFileDataMulti[0].awsFolder);

    const data_folder = allFileDataMulti[0].awsFolder.indexOf("/");
    const str_data_folder = allFileDataMulti[0].awsFolder.slice(
      data_folder + 1
    );
    const Final_aws_folder =
      allFileDataMulti[0].awsFolder === `${userID}/`
        ? `${userID}/`
        : str_data_folder;
    const final_folder_aws =
      uploadLocation === "ipfs" ? "" : `${Final_aws_folder}${str_data}`;
    console.log(final_folder_aws);
    const final_folder_ipfs =
      uploadLocation === "aws"
        ? ""
        : `${allFileDataMulti[0].FolderNameIPFS}${str_data}`;
    const File_Folder_aws =
      allFileDataMulti[0].awsFolder === final_folder_aws ? "file" : "folder";
    const File_Folder_ipfs =
      allFileDataMulti[0].FolderNameIPFS === final_folder_ipfs
        ? "file"
        : "folder";
    console.log(
      File_Folder_aws,
      allFileDataMulti[0].awsFolder,
      Final_aws_folder
    );
    // Updating retry icons and files states to indicate retry in progress
    setRetryIconMultiple((prev) => {
      const updatedRetryIcons = [...prev];
      updatedRetryIcons[i] = false;
      return updatedRetryIcons;
    });

    setRetryFileMultiple((prev) => {
      const updatedRetryIcons = [...prev];
      updatedRetryIcons[i] = true;
      return updatedRetryIcons;
    });
    // Determining bucket name based on upload location
    var bucket_name =
      uploadLocation === "aws"
        ? allFileDataMulti[0]?.awsBucketName
        : "atom-storage";
    try {
      // Constructing the API URL
      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/retry-upload`;
      // Constructing data object for retry upload
      const data = {
        fileName:
          uploadFrom === "multiple"
            ? file_id_multi[i].filename
            : fileUniqueName,
        file_id: uploadFrom === "multiple" ? file_id_multi[i].fileid : file_id,
        remoteupload: uploadLocation,
        bucketName: bucket_name,
        folderName: file_id_multi[i].foldernameaws,
      };
      // Constructing header configuration with user authorization token
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      // Sending POST request to retry upload API endpoint
      const res = await axios.post(API_URL, data, headerConfig);
      // Logging the response from the API
      console.log(" res***", res);
      // Updating retry count
      var retry_file = Number(sessionStorage.getItem("retry_file")) + 1;
      console.log(retry_file);
      sessionStorage.setItem("retry_file", retry_file);
      var retry_data =
        sessionStorage.getItem("retry_file") === "" ||
        sessionStorage.getItem("retry_file") === null ||
        sessionStorage.getItem("retry_file") === undefined
          ? sessionStorage.getItem("successUploadFile")
          : Number(sessionStorage.getItem("successUploadFile")) +
            Number(sessionStorage.getItem("retry_file"));
      // Handling successful retry upload
      if (
        res.data.retryUploadDataAWS.success === true ||
        res.data.retryUploadDataIPFS.success === true
      ) {
        console.log("Retry upload data successfully ");

        const uniqueID = uuidv4();
        const fileNameWithUuid = uniqueID + "_" + file.name;
        // Handling upload to IPFS
        if (
          uploadLocation === "ipfs" &&
          res?.data?.retryUploadDataIPFS?.Hash !== ""
        ) {
          // Handling metadata upload to AWS
          handleUploadMetadataIPFS(
            res?.data?.retryUploadDataIPFS?.Hash,
            fileNameWithUuid,
            file,
            i,
            final_folder_ipfs,
            ipfsFolderId,
            "",
            "",
            "multiple",
            File_Folder_ipfs,
            "Retry"
          );
        }
        // Handling upload to AWS
        if (
          uploadLocation === "aws" &&
          res?.data?.retryUploadDataAWS?.Location !== ""
        ) {
          const numberOfRetry =
            Number(sessionStorage.getItem("successfulUploadsAWS")) + 1;
          sessionStorage.setItem("successfulUploadsAWS", numberOfRetry);
          const uniqueID = uuidv4();
          const fileNameWithUuid = uniqueID + "_" + file.name;
          // Handling metadata upload to AWS
          handleUploadMetadataAWS(
            res?.data?.retryUploadDataAWS?.Key,
            res?.data?.retryUploadDataAWS?.Location,
            fileNameWithUuid,
            file,
            i,
            final_folder_aws,
            awsFolderId,
            final_folder_ipfs,
            ipfsFolderId,
            "multiple",
            "",
            File_Folder_aws,
            "Retry"
          );
        }
        // Handling upload to both AWS and IPFS
        if (
          uploadLocation === "both" &&
          res?.data?.retryUploadDataAWS?.Location !== "" &&
          res?.data?.retryUploadDataIPFS?.Hash !== ""
        ) {
          const uniqueID = uuidv4();
          const fileNameWithUuid = uniqueID + "_" + file.name;
          // Handling metadata upload to AWS
          handleUploadMetadataAWS(
            res?.data?.retryUploadDataAWS?.Key,
            res?.data?.retryUploadDataAWS?.Location,
            fileNameWithUuid,
            file,
            i,
            final_folder_aws,
            awsFolderId,
            final_folder_ipfs,
            ipfsFolderId,
            "multiple",
            fileHash,
            File_Folder_aws,
            "Retry"
          );
        }
        // Resetting retry state
        setRetryFileMultiple((prev) => {
          const updatedRetryIcons = [...prev];
          updatedRetryIcons[i] = false;
          return updatedRetryIcons;
        });
      }
      // Handling unsuccessful retry upload
      else if (
        (res.data.retryUploadDataAWS.success === false &&
          res.data.retryUploadDataAWS.progress === 1) ||
        (res.data.retryUploadDataIPFS.success === false &&
          res.data.retryUploadDataIPFS.progress === 1)
      ) {
        // Updating retry index and retry files states
        setRetryIndex([...retryIndex, i]);
        setRetryFileMultiple((prev) => {
          const updatedRetryIcons = [...prev];
          updatedRetryIcons[i] = false;
          return updatedRetryIcons;
        });
        // Checking if all files are retried or not
        if (
          retry_data === uploadStatusMulti[0].length ||
          Number(sessionStorage.getItem("retry_file")) +
            Number(sessionStorage.getItem("cancel_file")) +
            Number(sessionStorage.getItem("successUploadFile")) ===
            uploadStatusMulti[0].length
        ) {
          // Resetting progress box and displaying access popup
          sessionStorage.setItem("ProgressBox", false);
          setAccesspopup(true);
          sessionStorage.removeItem("totalFilecancled");
          sessionStorage.removeItem("successfulUploadsAWS");
          sessionStorage.removeItem("successUploadFile");
          sessionStorage.removeItem("cancel_file");
          sessionStorage.removeItem("retry_file");
        }
      }
    } catch (err) {
      // Catching any errors that occur during the retry process
      console.log(" err***", err);
      // Handling specific error code 427 - indicating session expiration
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      }
    }
  };

  //Access popup email handler
  const EmailHandler = async (event) => {
    const value = event.target.value;
    console.log(value);
    setEmailexits(value);
    setErrEmail("");
    if (value == useremail) {
      isValid();
    }
    const filteredSuggestions = userData.filter((suggestion, i) =>
      suggestion.userEmail.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };
  //****accesspopup Suggestion email push on email array */
  const onSelectSuggestion = (index) => {
    console.log(index);

    setEmailData([...emailData, filteredSuggestions[index].userEmail]);
    setEmailexits("");

    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  //****accesspopup Suggestion email push on email array  */
  const onSelectSuggestionKey = (e, index) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      console.log(index);
    }
  };
  //****accesspopup remove email from array list */
  const handleDelete = (participants1) => {
    setEmailData(emailData.filter((item, i) => i !== participants1));
  };
  //****push enter email in array*/
  const handleKeyDown = (e) => {
    console.log(e);
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);

        setEmailexits("");
      }
    }
  };
  const alreadyemail = emailData.filter(
    (item, index) => emailData.indexOf(item) !== index
  );
  //****accesspopup validation */
  function isInList(value) {
    return emailData.includes(value);
  }

  function isValid() {
    if (!EMAIL_REGEX.test(emailexits)) {
      setErrEmail(<>{text_valemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (isInList(emailexits)) {
      setErrEmail(<>{text_alemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailData.length > 51) {
      setErrEmail(<>{text_noticeemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailexits == useremail) {
      setErrEmail(<>*{text_Own_email_err}!</>);
      return false;
    } else {
      setErrEmail("");
    }

    return true;
  }

  const handleSingleUser = () => {
    if (emailexits === "") {
      setErrEmail(<>*{text_validationemail}</>);
    } else {
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        handleGetUser(value);
      }
    }
  };
  //*****Cancel Upload For Single file */
  const cancelUploadSingle = async (i) => {
    console.log(file_id);
    var bucket_name =
      uploadLocation === "aws" ? allFileData[0]?.awsBucketName : "atom-storage";
    try {
      abortController.current && abortController.current.abort();
      const API_URL =
        GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;
      const data = {
        file_id: file_id,
        file_name: fileUniqueName,
        location: uploadLocation,
        bucketName: bucket_name,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("cancelUpload res***", res);
      if (res.data.success === true) {
        if (uploadFrom === "multiple") {
          setCloseProgressBox(true);
        } else {
          setCloseProgressBox(true);
        }
        setFileUploadCancelAtom("Upload cancelled");
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      console.log("cancelUpload err***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      }
    }
  };
  //*****Cancel Upload For Multiple file */
  const cancelUpload = async (i) => {
    var bucket_name =
      uploadLocation === "aws"
        ? allFileDataMulti[0]?.awsBucketName
        : "atom-storage";
    setCancelIndex([...cancelIndex, i]);
    try {
      const abortController = abortControllers[i];
      abortController && abortController.abort();
      console.log(abortController, i);
      // calling the "cancel_upload" API
      const API_URL =
        GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      const data = {
        file_id: uploadFrom === "multiple" ? file_id_multi[i].fileid : file_id,
        file_name:
          uploadFrom === "multiple"
            ? file_id_multi[i].filename
            : fileUniqueName,
        location: uploadLocation,
        bucketName: bucket_name,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("cancelUpload res***", res);

      if (res.data.success === true) {
        if (uploadFrom === "multiple") {
          if (i === uploadStatusMulti[0].length) {
            setCloseProgressBox(true);
          } else {
            setCloseProgressBox(false);
            handleMultipleUpload(uploadLocation, i + 1);
          }
        } else {
          setCloseProgressBox(true);
        }
        setFileUploadCancel("Upload cancelled");
        let uploadcancelOneFile = uploadStatusMulti[0].length;
        setTotalFileCancled(
          (prevTotalFilesCancelled) =>
            prevTotalFilesCancelled + uploadcancelOneFile
        );
        sessionStorage.setItem("totalFilecancled", totalFilecancled);
      }
    } catch (err) {
      console.log("cancelUpload err***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      }
    }
    var canceled_file = Number(sessionStorage.getItem("cancel_file")) + 1;
    console.log(canceled_file);
    sessionStorage.setItem("cancel_file", canceled_file);
    let uploadcancelOneFile = uploadStatusMulti.length;
    //*****Cancel Upload For pending Multiple file */
    for (let id = 0; id < uploadStatusMulti[0].length; id++) {
      console.log(id === i);
      setFileUploadCancel("Upload cancelled");

      if (id === i) {
        multi_pro[i].Cancel_id = id;
        setTotalFileCancled(
          (prevTotalFilesCancelled) =>
            prevTotalFilesCancelled + uploadcancelOneFile
        );
        sessionStorage.setItem("totalFilecancled", totalFilecancled);
      }
    }

    console.log(file_id);

    var success_or_Cancel =
      sessionStorage.getItem("successUploadFile") === "" ||
      sessionStorage.getItem("successUploadFile") === 0 ||
      sessionStorage.getItem("successUploadFile") === undefined ||
      sessionStorage.getItem("successUploadFile") === null
        ? sessionStorage.getItem("cancel_file")
        : Number(sessionStorage.getItem("cancel_file")) +
          Number(sessionStorage.getItem("successUploadFile"));
    if (
      Number(sessionStorage.getItem("cancel_file")) ===
      uploadStatusMulti[0].length
    ) {
      setCloseProgressBox(true);
    }
    if (success_or_Cancel === uploadStatusMulti[0].length) {
      setAccesspopup(true);
    }
  };
  //****IPFS Give Access File for register user */
  const GiveAccessToUser = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: singleMetadata?.file_unique_id,
      folder_id: singleMetadata.ipfsFolderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: singleMetadata.bucketId_ipfs,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
      folder_name: folderNameForInsertRecordipfs,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res.data.success === true) {
          if (emailData.length === 0) {
            if (uploadLocation !== "both") {
              successToast(res.data.message);
            }
            closeAccessPopup();
            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            if (uploadLocation !== "both") {
              successToast(res.data.message);
            }
            closeAccessPopup();
            setShareFileLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };

  //*** Give Access to Multi file aws *****/
  const GiveAccessToUserAws = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: singleMetadata?.file_unique_id,
      folder_id: singleMetadata.folderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: singleMetadata.bucketId_aws,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
      to_both: uploadLocation === "both" ? true : false,
      folder_name: folderNameForInsertRecordaws,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res.data.success === true) {
          if (emailData.length === 0) {
            successToast(res.data.message);
            closeAccessPopup();
            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res.data.message);
            closeAccessPopup();
            setShareFileLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Give Access to Multi file ipfs Register user *****/
  const GiveAccessToUserMultiIPFS = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record-bulk`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      sender_user_id: sessionStorage.getItem("userID"),
      bucket_id:
        allFileDataMulti[0]?.bucketIdIpfs === "" ||
        allFileDataMulti[0]?.bucketIdIpfs === null
          ? "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          : allFileDataMulti[0]?.bucketIdIpfs,
      file_unique_id: multiMetadata, // array
      folder_id:
        uploadLocation === "aws"
          ? allFileDataMulti[0]?.awsFolder
          : allFileDataMulti[0]?.ipfsID,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      to_both: uploadLocation === "both" ? true : false,
      // ----- data for email starts -----
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // null when no companyName
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      folder_name: folderNameForInsertRecordipfs,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res.data.success === true) {
          if (emailData.length === 0) {
            if (uploadLocation !== "both") {
              successToast(res.data.message);
            }
            if (uploadFolder === false) {
              closeAccessPopup();
            }

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            if (uploadLocation !== "both") {
              successToast(res.data.message);
            }
            if (uploadFolder === false) {
              closeAccessPopup();
            }

            setShareFileLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Give Access to Multi folder ipfs *****/
  const GiveAccessToUserMultiIPFSFolder = async (
    email,
    userID_to,
    lastIndex,
    uniqueIDforboth
  ) => {
    setShareFileLoader(true);
    var files = uploadStatusMulti[0];

    function filterPaths(fileArray) {
      const basePath = sessionStorage.getItem("requrlIpfs");
      // Retrieve basePath from sessionStorage

      const filteredPaths = fileArray
        .map((file) => file.path)
        .map((path) => {
          const parts = path.split("/");
          return `${parts[1]}/`;
        })
        .filter((path, index, self) => self.indexOf(path) === index)
        .map((path) => `${basePath}${path}`);

      return filteredPaths;
    }

    // Get the filtered and unique paths with basePath added
    const result = filterPaths(files);

    console.log(result, folderArr_ipfs);

    debugger;
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-ipfs`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        company_id: CompanyID, // company that is giving the access
        company_user_id: userID, // user that is giving the access
        user_id: userID_to, // user whom access is being given
        db_version: "v1",
        other_data_2: other_data_2_ipfs, // data of existing file in the folders which access is being given (files only) (you won't get data about an empty folder here)
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        folderArr: folderArr_ipfs,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,
        to_both: uploadLocation === "both" ? true : false,
        both_folder_id: uniqueIDforboth,
        // ----- data for email starts -----
        email: email,
        companyName: CompanyName, // null when no companyName
        firstName: "", // don't add validation now
        lastName: "", // don't add validation now
        sender_firstname: UserF_Name, // don't add validation now
        sender_lastname: UserL_name, // don't add validation now
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_ipfs_folder_access res***", res);
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        setShareFileLoader(false);
      }
    } catch (err) {
      console.log(
        "give_ipfs_folder_access err***",
        err?.response?.data?.message
      );
    }
  };

  //*** Give Access to Multi file aws Register user*****/
  const GiveAccessToUserMultiAWS = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record-bulk`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      sender_user_id: sessionStorage.getItem("userID"),
      bucket_id: allFileDataMulti[0]?.bucketId,
      file_unique_id: multiMetadata, // array
      folder_id: allFileDataMulti[0]?.FolderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,

      // ----- data for email starts -----
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // null when no companyName
      firstName: "",
      lastName: "",
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      to_both: uploadLocation === "both" ? true : false,
      folder_name: folderNameForInsertRecordaws,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Access Given", res);
        if (res.data.success === true) {
          if (emailData.length === 0) {
            successToast(res.data.message);
            closeAccessPopup();

            setShareFileLoader(false);
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res.data.message);
            closeAccessPopup();
            setShareFileLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };

  //*** Give Access to Multi folder aws *****/
  const GiveAccessToUserMultiAWSFolder = async (
    email,
    userID_to,
    lastIndex,
    uniqueIDforboth
  ) => {
    setShareFileLoader(true);
    var files = uploadStatusMulti[0];

    function filterPaths(fileArray) {
      const basePath = sessionStorage.getItem("requrlAws");
      // Retrieve basePath from sessionStorage

      const filteredPaths = fileArray
        .map((file) => file.path)
        .map((path) => {
          const parts = path.split("/");
          return `${parts[1]}/`;
        })
        .filter((path, index, self) => self.indexOf(path) === index)
        .map((path) => `${basePath}${path}`);

      return filteredPaths;
    }

    // Get the filtered and unique paths with basePath added
    const result = filterPaths(files);

    console.log(result, folderArr_ipfs);

    debugger;

    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-aws`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        company_id: CompanyID,
        company_user_id: userID,
        user_id: userID_to,
        db_version: "v1",
        other_data_2: othe_data_2,
        bucketId: allFileDataMulti[0]?.bucketId,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,
        email: email,
        companyName: CompanyName,
        firstName: "",
        lastName: "",
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        folderArr: folderArr,
        to_both: uploadLocation === "both" ? true : false,
        both_folder_id: uniqueIDforboth,
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_aws_folder_access res***", res);
      if (res.data.success === true) {
        closeAccessPopup();
        setShareFileLoader(false);
      }
    } catch (err) {
      setShareFileLoader(false);
      console.log(
        "give_aws_folder_access err***",
        err?.response?.data?.message
      );
    }
  };

  //*** Invite user for file access multiple file [not register user]*****/
  const handleInviteUserMulti = (email, lastIndex) => {
    setShareFileLoader(true);
    var otherDataAWS = [
      [singleMetadata.file_unique_id, singleMetadata.folderId],
    ];
    var otherDataIPFS = [[file_id, singleMetadata.ipfsFolderId]];
    var companyID = sessionStorage.getItem("companyID");

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/inviteuser-for-access`;

    var data = {
      firstName: "", // new user
      lastName: "", // new user
      email: email,
      companyName: CompanyName === "" ? null : CompanyName, // (send "" for individual user)
      sender_firstname: sessionStorage.getItem("fName"), // user who is giving access
      sender_lastname: sessionStorage.getItem("lName"), // user who is giving access
      sender_user_id: sessionStorage.getItem("userID"), // user who is giving access

      // following data is to insert an access record in pgTable and ipfsPgTable
      is_aws:
        uploadFrom === "single"
          ? allFileData[0]?.isAmazon
          : allFileDataMulti[0]?.isAmazon,
      is_ipfs:
        uploadFrom === "single"
          ? allFileData[0]?.isIPFS
          : allFileDataMulti[0]?.isIPFS,

      company_id: companyID, // company that is giving the access (send null when no company_id)
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,

      bucket_id:
        uploadFrom === "single"
          ? uploadLocation === "ipfs"
            ? ""
            : allFileData[0]?.bucketId
          : uploadLocation === "aws" || uploadLocation === "both"
          ? allFileDataMulti[0]?.bucketId
          : "",
      other_data_aws:
        uploadFrom === "single"
          ? uploadLocation === "ipfs"
            ? []
            : otherDataAWS
          : MultiFileandFolderIDAWS, // [ [file_unique_id, folder_id], [file_unique_id, folder_id], [file_unique_id, folder_id] ]

      bucket_id_ipfs:
        uploadFrom === "single"
          ? allFileData[0]?.bucketIdIpfs
          : "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
      other_data_ipfs:
        uploadFrom === "single"
          ? uploadLocation === "aws"
            ? []
            : otherDataIPFS
          : MultiFileandFolderID, // [ [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs] ]
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        if (res.status === 200) {
          handleCreateDefaultFolder(res.data.data.registered_user_id);
          handleCreateRootFolderS3(res.data.data.registered_user_id);
          handleCreateRootFolderS3_Private(res.data.data.registered_user_id);
          if (emailData.length === 0) {
            setShareFileLoader(false);
            successToast(res.data.message);
          }
          if (emailData.slice(-1)[0] === email) {
            setShareFileLoader(false);
            successToast(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err.response.data.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
    // }
  };
  //*** Invite user for folder access *****/
  const handleInviteUserMultiFolder = async (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/inviteuser-for-folder-access`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        firstName: "",
        lastName: "",
        email: email,
        companyName: CompanyName,
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        sender_user_id: userID,
        is_aws: uploadLocation === "ipfs" ? false : true,
        is_ipfs: uploadLocation === "aws" ? false : true,
        company_id: CompanyID,
        db_version: "v1",
        bucket_id: allFileDataMulti[0]?.bucketId,
        other_data_aws: othe_data_2,
        folderArr_aws: uploadLocation === "ipfs" ? [] : folderArr,
        bucket_id_ipfs:
          uploadLocation === "aws"
            ? ""
            : "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        other_data_ipfs: other_data_2_ipfs,
        folderArr_ipfs: uploadLocation === "aws" ? [] : folderArr_ipfs,
        read_access_aws: uploadLocation === "ipfs" ? false : readAccess,
        write_access_aws: uploadLocation === "ipfs" ? false : writeAccess,
        download_access_aws: uploadLocation === "ipfs" ? false : downloadAccess,
        read_access_ipfs: uploadLocation === "aws" ? false : readAccess,
        write_access_ipfs: uploadLocation === "aws" ? false : writeAccess,
        download_access_ipfs: uploadLocation === "aws" ? false : downloadAccess,
      };

      let res = await axios.post(API_URL, data, headerConfig);

      console.log("give_ipfs_folder_access res***", res);
      if (res.status === 200) {
        handleCreateDefaultFolder(res.data.data.registered_user_id);
        handleCreateRootFolderS3(res.data.data.registered_user_id);
        handleCreateRootFolderS3_Private(res.data.data.registered_user_id);
      }

      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        setShareFileLoader(false);
      }
    } catch (err) {
      console.log(
        "give_ipfs_folder_access err***",
        err?.response?.data?.message
      );
      if (emailData.slice(-1)[0] === email) {
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        setShareFileLoader(false);
      }
    }
  };
  //*** Invited user default IPFS folder create API *****/
  const handleCreateDefaultFolder = (userId) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userId,
      folderName: `atom-storage/${userId}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("reg res***", res);
        if (res.data.success === true) {
          closeAccessPopup();
        }
      })
      .catch((err) => {
        console.log("reg err***", err);

        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };
  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("root folder aws res***", res);
      })
      .catch((err) => {
        console.log("root folder aws err***", err);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        console.log("private root folder aws err***", err);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  //*** user is register or Not register *****/
  const handleGetUser = (value) => {
    if (alreadyemail.length > 0) {
      setErrEmail(<>{text_alemail}</>);
    } else {
      var FinalEmail = [];
      if (emailData.length === 0) {
        FinalEmail.push({ email: [value] }); //if one email added but not added in email array than by default push email in array
      } else {
        FinalEmail.push({ email: emailData });
      }
      if (FinalEmail.length === 0) {
        setErrEmail(<>*{text_validationemail}</>);
      } else {
        for (let i = 0; FinalEmail[0].email.length > i; i++) {
          let headerConfig = {
            headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
            },
          };

          var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/emailexist`;
          var data = {
            EmailOrPhone: FinalEmail[0].email[i].toLowerCase(),
          };
          axios
            .post(API_URL, data, headerConfig)
            .then((res) => {
              console.log(
                "userDetails:-",
                res.data.data._id,
                "****",
                uploadFolder
              );
              var val = res.data.data;
              if (res.status === 200) {
                ////***** Conditions Give Access for multiple file upload [register user]*******/
                if (uploadFrom === "multiple") {
                  if (uploadLocation === "ipfs" && uploadFile === true) {
                    GiveAccessToUserMultiIPFS(FinalEmail[0].email[i], val._id);
                  }
                  if (uploadLocation === "ipfs" && uploadFolder === true) {
                    GiveAccessToUserMultiIPFSFolder(
                      FinalEmail[0].email[i],
                      val._id
                    );
                  }
                  if (uploadLocation === "aws" && uploadFile === true) {
                    GiveAccessToUserMultiAWS(FinalEmail[0].email[i], val._id);
                  }
                  if (uploadLocation === "aws" && uploadFolder === true) {
                    GiveAccessToUserMultiAWSFolder(
                      FinalEmail[0].email[i],
                      val._id
                    );
                  }
                  if (uploadLocation === "both") {
                    if (uploadFile === true) {
                      GiveAccessToUserMultiIPFS(
                        FinalEmail[0].email[i],
                        val._id
                      );
                      GiveAccessToUserMultiAWS(FinalEmail[0].email[i], val._id);
                    }
                    if (uploadFolder === true) {
                      const uniqueIDforboth = uuidv4();
                      GiveAccessToUserMultiAWSFolder(
                        FinalEmail[0].email[i],
                        val._id,
                        uniqueIDforboth
                      );
                      GiveAccessToUserMultiIPFSFolder(
                        FinalEmail[0].email[i],
                        val._id,
                        uniqueIDforboth
                      );
                    }
                  }
                } else {
                  if (uploadLocation === "ipfs") {
                    GiveAccessToUser(FinalEmail[0].email[i], val._id);
                  }
                  if (uploadLocation === "aws") {
                    GiveAccessToUserAws(FinalEmail[0].email[i], val._id);
                  }
                  if (uploadLocation === "both") {
                    GiveAccessToUserAws(FinalEmail[0].email[i], val._id);
                    GiveAccessToUser(FinalEmail[0].email[i], val._id);
                  }
                }
              }
            })
            .catch((err) => {
              console.log("get data ipfs err***", err);
              if (uploadFrom === "multiple") {
                if (uploadFile === true && uploadLocation === "aws") {
                  handleInviteUserMulti(FinalEmail[0].email[i]);
                }
                if (uploadFile === true && uploadLocation === "ipfs") {
                  handleInviteUserMulti(FinalEmail[0].email[i]);
                }
                if (uploadFile === true && uploadLocation === "both") {
                  handleInviteUserMulti(FinalEmail[0].email[i]);
                }
                if (uploadFolder === true && uploadLocation === "both") {
                  handleInviteUserMultiFolder(FinalEmail[0].email[i]);
                }
                if (uploadFolder === true && uploadLocation === "ipfs") {
                  handleInviteUserMultiFolder(FinalEmail[0].email[i]);
                }
                if (uploadFolder === true && uploadLocation === "aws") {
                  handleInviteUserMultiFolder(FinalEmail[0].email[i]);
                }
              } else {
                handleInviteUserMulti(FinalEmail[0].email[i]);
              }

              if (err?.response?.status === 427) {
                sessionStorage.clear();
                localStorage.clear();
                Cookie.remove("fName");
                Cookie.remove("lName");
                Cookie.remove("userID");
                Cookie.remove("userToken");
                Cookie.remove("token_start_time");
                window.location.replace("/");
              }
            })
            .then(function () {});
        }
      }
    }
  };
  //*** upload metadata for AWS [single file, multiple file, folders] *****/
  let successfulUploadsAWS = 0;
  const handleUploadMetadataAWS = (
    awsKey,
    awsUrl,
    nameWithUUIDIpfs,
    uploadStatus,
    id,
    folderNameaws,
    folderIdAws,
    folderIpfs,
    folderIPFSId,
    uploadDataFrom,
    cid,
    FileorFolder,
    Retry
  ) => {
    var IPFS_folder_single =
      folderIpfs === "" || folderIpfs === undefined || folderIpfs === null
        ? `atom-storage/${userID}/`
        : folderIpfs;

    var Folder_name_ipfs =
      uploadDataFrom === "single"
        ? allFileData[0]?.ipfsfoldername === undefined
          ? `atom-storage/${userID}/`
          : allFileData[0]?.ipfsfoldername
        : folderIPFSId;
    const str_aws =
      allFileDataMulti === undefined ? "" : allFileDataMulti[0]?.awsFolder;
    const ind_aws = str_aws.indexOf("/");
    const new_str_aws = str_aws.slice(ind_aws + 1);
    console.log(new_str_aws);

    var Folder_name =
      uploadDataFrom === "single"
        ? folderNameaws === ""
          ? `${new_str_aws}/`
          : allFileData[0]?.awsFolder.endsWith("/")
          ? allFileData[0]?.awsFolder
          : `${allFileData[0]?.awsFolder}/`
        : folderNameaws === "" ||
          folderNameaws === `${userID}/` ||
          folderNameaws.endsWith("/") ||
          folderNameaws.includes(`${new_str_aws}/`)
        ? new_str_aws === ""
          ? `${userID}/`
          : new_str_aws.includes(`${userID}/`)
          ? `${new_str_aws}`
          : `${userID}/${new_str_aws}`
        : `${new_str_aws}${folderNameaws}/`;

    const file_name_with_hyphen = uploadStatus?.name?.replace(/ /g, "-");
    let file_unique_id = "";
    let nameWithUUID = uuidv4() + "_" + uploadStatus?.name;
    console.log(folderNameaws);

    if (uploadLocation === "aws") {
      file_unique_id = "1_0_" + uuidv4() + "_" + file_name_with_hyphen;
    } else if (uploadLocation === "ipfs") {
      file_unique_id = "0_1_" + uuidv4() + "_" + file_name_with_hyphen;
    } else if (uploadLocation === "both") {
      file_unique_id = "1_1_" + uuidv4() + "_" + file_name_with_hyphen;
    }

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var data = {
      userId: sessionStorage.getItem("userID"),
      amazonUrl: awsUrl,
      amazonKey: awsKey,
      fileCId: cid,
      isAmazon:
        uploadDataFrom === "single"
          ? allFileData[0]?.isAmazon
          : allFileDataMulti[0]?.isAmazon,
      isIPFS:
        uploadDataFrom === "single"
          ? allFileData[0]?.isIPFS
          : allFileDataMulti[0]?.isIPFS,
      bucketName:
        uploadDataFrom === "single"
          ? allFileData[0]?.awsBucketName
          : allFileDataMulti[0]?.awsBucketName,

      folderName: uploadDataFrom === "single" ? Folder_name : folderNameaws,
      active: true,
      fileSize: size(uploadStatus.size),
      fileName: uploadStatus?.name,
      fileNameReplace: uploadStatus?.name?.replace(/ /g, "_"),
      fileNameWithUuid: nameWithUUID,
      fileType: uploadStatus?.type,
      description:
        uploadDataFrom === "single"
          ? allFileData[0]?.fileDesc
          : allFileDataMulti[0]?.fileDesc,
      fileTags:
        uploadDataFrom === "single"
          ? allFileData[0]?.fileTag
          : allFileDataMulti[0]?.fileTag,
      keywords:
        uploadDataFrom === "single"
          ? allFileData[0]?.fileKeyWord
          : allFileDataMulti[0]?.fileKeyWord,
      orgName:
        uploadDataFrom === "single"
          ? allFileData[0]?.orgName
          : allFileDataMulti[0]?.orgName,
      isMedData:
        uploadDataFrom === "single"
          ? allFileData[0]?.medicalformvisible
          : allFileDataMulti[0]?.medicalformvisible,
      pFName:
        uploadDataFrom === "single"
          ? allFileData[0]?.firstName
          : allFileDataMulti[0]?.firstName,
      pMName: "",
      pLName:
        uploadDataFrom === "single"
          ? allFileData[0]?.lastName
          : allFileDataMulti[0]?.lastName,
      pDOB:
        uploadDataFrom === "single"
          ? allFileData[0]?.selectedDate
          : allFileDataMulti[0]?.selectedDate,
      pAge:
        uploadDataFrom === "single"
          ? Number(allFileData[0]?.age)
          : Number(allFileDataMulti[0]?.age),
      pGender:
        uploadDataFrom === "single"
          ? allFileData[0]?.gender
          : allFileDataMulti[0]?.gender,
      pEmail:
        uploadDataFrom === "single"
          ? allFileData[0]?.email
          : allFileDataMulti[0]?.email,
      pPhone:
        uploadDataFrom === "single"
          ? allFileData[0]?.phone
          : allFileDataMulti[0]?.phone,
      h_id_1:
        uploadDataFrom === "single"
          ? allFileData[0]?.h_id_1
          : allFileDataMulti[0]?.h_id_1,
      h_id_2:
        uploadDataFrom === "single"
          ? allFileData[0]?.h_id_2
          : allFileDataMulti[0]?.h_id_2,
      h_id_3:
        uploadDataFrom === "single"
          ? allFileData[0]?.h_id_3
          : allFileDataMulti[0]?.h_id_3,
      country:
        uploadDataFrom === "single"
          ? allFileData[0]?.CountryCode
          : allFileDataMulti[0]?.CountryCode,
      uploadDateAndTime: new Date(),
      file_unique_id: file_unique_id,
      folderId: folderIdAws, // my folder 2/my folder 22/ (*** make this dynamic ***)
      sizeInBytes: uploadStatus?.size,
      ipfsFolderName:
        uploadLocation === "both" ? IPFS_folder_single : Folder_name_ipfs,
      ipfsFolderId: uploadLocation === "both" ? folderIPFSId : "",
      bucketId_aws:
        uploadDataFrom === "single"
          ? allFileData[0]?.bucketId
          : allFileDataMulti[0]?.bucketId,
      bucketId_ipfs:
        uploadDataFrom === "single"
          ? allFileData[0]?.bucketIdIpfs
          : allFileDataMulti[0]?.bucketIdIpfs,
      bucketACLOption:
        uploadDataFrom === "single"
          ? allFileData[0]?.bucketAclOption
          : allFileDataMulti[0]?.bucketAclOption,
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/file/add-metadata";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("metadata res***", res);
        if (res.data.success === true) {
          if (uploadFrom === "multiple" && Retry === "") {
            handleMultipleUpload(uploadLocation, id + 1); //if metadata success than upload next file from array of Files
          }
        }
        const previousUniqueId = res?.data?.data?.file_unique_id;
        const bucket = res?.data?.data?.bucketName;
        const folderName = res?.data?.data?.folderName;
        const folderName_ipfs = res?.data?.data?.ipfsFolderName;
        const folderId = res?.data?.data?.folderId;
        const folderId_ipfs = res?.data?.data?.ipfsFolderId;
        setFolderNameForInsertRecordaws(
          `${res?.data?.data?.bucketName}/${res?.data?.data?.folderName}`
        );
        setFolderNameForInsertRecordipfs(res?.data?.data?.ipfsFolderName);
        if (uploadDataFrom === "multiple") {
          setMultiMetadata((prevIds) => [...prevIds, previousUniqueId]); //For give access user[register user] Multiple File name
          setMultiFileandFolderIDAWS((preData) => [
            ...preData,
            [res?.data?.data?.file_unique_id, res?.data?.data?.folderId],
          ]); //For invite user File Folder Data AWS
          if (uploadLocation === "both") {
            setMultiFileandFolderID((prevIds) => [
              ...prevIds,
              [res?.data?.data?.file_unique_id, res?.data?.data?.ipfsFolderId],
            ]); //For invite user File Folder Data IPFS
            setOther_data_2_ipfs((data) => [
              ...data,
              [folderName_ipfs, previousUniqueId, folderId_ipfs],
            ]); //For invite or give access user Folder Data IPFS
          }
          setOther_data_2((data) => [
            ...data,
            [bucket + "/" + folderName, previousUniqueId, folderId],
          ]); //For invite or give access user Folder Data aws

          setfolderArr((folder) => [...folder, bucket + "/" + folderName]); //For give access user Folder name aws
          setfolderArr_ipfs((folder) => [...folder, folderName_ipfs]); //For give access user Folder name IPFS
          if (Retry == "") {
            let success_count =
              Number(sessionStorage.getItem("successUploadFile")) + 1;

            sessionStorage.setItem("successUploadFile", success_count);
          }

          ///******Check conditions for Cancel file retry file and success upload file****/
          var TotalFileCancelToNumber =
            sessionStorage.getItem("totalFilecancled");
          console.log(sessionStorage.getItem("totalFilecancled"));
          console.log(
            uploadStatusMulti[0].length,
            "totalcancel",
            Number(TotalFileCancelToNumber),
            "upload-cancel",
            successfulUploadsAWS + Number(TotalFileCancelToNumber),
            "totalcancel-success",
            successfulUploadsAWS + Number(TotalFileCancelToNumber)
          );
          var cancel_data =
            sessionStorage.getItem("cancel_file") === "" ||
            sessionStorage.getItem("cancel_file") === null ||
            sessionStorage.getItem("cancel_file") === undefined
              ? sessionStorage.getItem("successUploadFile")
              : Number(sessionStorage.getItem("successUploadFile")) +
                Number(sessionStorage.getItem("cancel_file"));

          var retry_data =
            sessionStorage.getItem("retry_file") === "" ||
            sessionStorage.getItem("retry_file") === null ||
            sessionStorage.getItem("retry_file") === undefined
              ? sessionStorage.getItem("successUploadFile")
              : Number(sessionStorage.getItem("successUploadFile")) +
                Number(sessionStorage.getItem("retry_file"));
          if (
            Number(sessionStorage.getItem("successUploadFile")) ===
              uploadStatusMulti[0].length ||
            cancel_data === uploadStatusMulti[0].length ||
            retry_data === uploadStatusMulti[0].length ||
            Number(sessionStorage.getItem("retry_file")) +
              Number(sessionStorage.getItem("cancel_file")) +
              Number(sessionStorage.getItem("successUploadFile")) ===
              uploadStatusMulti[0].length
          ) {
            sessionStorage.setItem("ProgressBox", false);
            setAccesspopup(true);
            sessionStorage.removeItem("totalFilecancled");
            sessionStorage.removeItem("successfulUploadsAWS");
            sessionStorage.removeItem("successUploadFile");
            sessionStorage.removeItem("cancel_file");
            sessionStorage.removeItem("retry_file");
          }
        } else {
          if (uploadFrom === "single") {
            setsingleMetadata(res?.data?.data); //Single file metadata call and open access popup
            if (res.status === 201) {
              setAccesspopup(true);
            }
          }
        }
      })
      .catch((err) => {
        console.log("metadata err***", err);
        setFileUploadCancel("Upload cancelled");
        setFileUploadCancelAtom("Upload cancelled");
        multi_pro[id].message = err.response.data.message;
        if (uploadFrom === "multiple" && Retry === "") {
          handleMultipleUpload(uploadLocation, id + 1); // get error on metadata upload next file on filedata array
        }
        errorToast(err.response.data.message);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };
  //*** upload metadata for IPFS [single file, multiple file, folders] *****/
  const handleUploadMetadataIPFS = (
    cid,
    nameWithUUIDIpfs,
    fileData,
    i,
    folderName,
    folderId,
    folderNameaws,
    folderIdaws,
    MetadataFor,
    filefolder,
    Retry
  ) => {
    var Folder_nameAWS = folderNameaws === "" ? "" : allFileData[0]?.awsFolder;
    var Folder_name =
      folderName === undefined
        ? `atom-storage/${userID}/`
        : filefolder === "file"
        ? folderName.endsWith("/")
          ? folderName
          : folderName + "/"
        : folderName.endsWith("/")
        ? folderName
        : folderName + "/";

    const file_name_with_hyphen = fileData?.name?.replace(/ /g, "-");
    let file_unique_id = "";
    let file_name = fileData?.name;

    if (uploadLocation === "aws") {
      file_unique_id = "1_0_" + uuidv4() + "_" + file_name_with_hyphen;
    } else if (uploadLocation === "ipfs") {
      file_unique_id = "0_1_" + uuidv4() + "_" + file_name_with_hyphen;
    } else if (uploadLocation === "both") {
      file_unique_id = "1_1_" + uuidv4() + "_" + file_name_with_hyphen;
    }

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var data = {
      userId: sessionStorage.getItem("userID"),
      file_unique_id: file_unique_id,
      fileCId: cid,
      ipfsFolderName: Folder_name,
      ipfsFolderId: folderId,
      fileSize: size(fileData.size),
      sizeInBytes: fileData.size,
      folderName: Folder_nameAWS,
      folderId: folderIdaws,
      fileName: file_name,
      fileNameReplace: file_name?.replace(/ /g, "_"),
      fileNameWithUuid: nameWithUUIDIpfs,
      fileType: fileData.type,
      bucketId:
        MetadataFor === "single"
          ? allFileData[0]?.bucketId
          : allFileDataMulti[0]?.bucketId,
      description:
        MetadataFor === "single"
          ? allFileData[0]?.fileDesc
          : allFileDataMulti[0]?.fileDesc,
      fileTags:
        MetadataFor === "single"
          ? allFileData[0]?.fileTag
          : allFileDataMulti[0]?.fileTag,
      keywords:
        MetadataFor === "single"
          ? allFileData[0]?.fileKeyWord
          : allFileDataMulti[0]?.fileKeyWord,
      orgName:
        MetadataFor === "single"
          ? allFileData[0]?.orgName
          : allFileDataMulti[0]?.orgName,
      isMedData:
        MetadataFor === "single"
          ? allFileData[0]?.medicalformvisible
          : allFileDataMulti[0]?.medicalformvisible,
      pFName:
        MetadataFor === "single"
          ? allFileData[0]?.firstName
          : allFileDataMulti[0]?.firstName,
      pLName:
        MetadataFor === "single"
          ? allFileData[0]?.lastName
          : allFileDataMulti[0]?.lastName,
      pDOB:
        MetadataFor === "single"
          ? allFileData[0]?.selectedDate
          : allFileDataMulti[0]?.selectedDate,
      pAge:
        MetadataFor === "single"
          ? Number(allFileData[0]?.age)
          : Number(allFileDataMulti[0]?.age),
      pGender:
        MetadataFor === "single"
          ? allFileData[0]?.gender
          : allFileDataMulti[0]?.gender,
      pEmail:
        MetadataFor === "single"
          ? allFileData[0]?.email
          : allFileDataMulti[0]?.email,
      pPhone:
        MetadataFor === "single"
          ? allFileData[0]?.phone
          : allFileDataMulti[0]?.phone,
      uploadDateAndTime: new Date(),
      h_id_1:
        MetadataFor === "single"
          ? allFileData[0]?.h_id_1
          : allFileDataMulti[0]?.h_id_1,
      h_id_2:
        MetadataFor === "single"
          ? allFileData[0]?.h_id_2
          : allFileDataMulti[0]?.h_id_2,
      h_id_3:
        MetadataFor === "single"
          ? allFileData[0]?.h_id_3
          : allFileDataMulti[0]?.h_id_3,
      country:
        MetadataFor === "single"
          ? allFileData[0]?.CountryCode
          : allFileDataMulti[0]?.CountryCode,
      bucketId_ipfs: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
      bucketACLOption: uploadLocation === "ipfs" ? "private" : "",
    };
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/file/add-metadata-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("metadata res***", res);
        setFolderNameForInsertRecordipfs(res?.data?.data?.ipfsFolderName);
        if (res.data.success === true) {
          if (uploadFrom === "multiple") {
            handleMultipleUpload(uploadLocation, i + 1);
          }
        }

        const previousUniqueId = res?.data?.data?.file_unique_id;
        const folderName = res?.data?.data?.ipfsFolderName;
        const folderId = res?.data?.data?.ipfsFolderId;

        if (MetadataFor === "multiple") {
          setMultiMetadata((prevIds) => [...prevIds, previousUniqueId]); //For give access user[register user] Multiple File name
          setMultiFileandFolderID((prevIds) => [
            ...prevIds,
            [res?.data?.data?.file_unique_id, res?.data?.data?.ipfsFolderId],
          ]); //For invite user File Folder Data ipfs

          setOther_data_2_ipfs((data) => [
            ...data,
            [folderName, previousUniqueId, folderId],
          ]); //For invite or give access user Folder Data IPFS
          setfolderArr_ipfs((folder) => [...folder, folderName]); //For give access user Folder name IPFS

          if (Retry == "") {
            let success_count =
              Number(sessionStorage.getItem("successUploadFile")) + 1;

            sessionStorage.setItem("successUploadFile", success_count);
          }
          ///******Check conditions for Cancel file and success upload file****/
          var TotalFileCancelToNumber =
            sessionStorage.getItem("totalFilecancled");
          console.log(sessionStorage.getItem("totalFilecancled"));
          console.log(Number(TotalFileCancelToNumber));
          var cancel_data =
            sessionStorage.getItem("cancel_file") === "" ||
            sessionStorage.getItem("cancel_file") === null ||
            sessionStorage.getItem("cancel_file") === undefined
              ? sessionStorage.getItem("successUploadFile")
              : Number(sessionStorage.getItem("successUploadFile")) +
                Number(sessionStorage.getItem("cancel_file"));

          var retry_data =
            sessionStorage.getItem("retry_file") === "" ||
            sessionStorage.getItem("retry_file") === null ||
            sessionStorage.getItem("retry_file") === undefined
              ? sessionStorage.getItem("successUploadFile")
              : Number(sessionStorage.getItem("successUploadFile")) +
                Number(sessionStorage.getItem("retry_file"));
          if (
            Number(sessionStorage.getItem("successUploadFile")) ===
              uploadStatusMulti[0].length ||
            cancel_data === uploadStatusMulti[0].length ||
            retry_data === uploadStatusMulti[0].length ||
            Number(sessionStorage.getItem("retry_file")) +
              Number(sessionStorage.getItem("cancel_file")) +
              Number(sessionStorage.getItem("successUploadFile")) ===
              uploadStatusMulti[0].length
          ) {
            sessionStorage.setItem("ProgressBox", false);
            setAccesspopup(true);
            sessionStorage.removeItem("totalFilecancled");
            sessionStorage.removeItem("successUploadFile");
            sessionStorage.removeItem("cancel_file");
            sessionStorage.removeItem("retry_file");
          }
        } else {
          setsingleMetadata(res?.data?.data); //Single file metadata call and open access popup
          console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", res.data.data);
          if (res.status === 201) {
            setAccesspopup(true);
          }
        }
      })
      .catch((err) => {
        console.log("metadata err***", err);
        setFileUploadCancel("Upload cancelled");
        setFileUploadCancelAtom("Upload cancelled");

        if (uploadFrom === "multiple" && Retry === "") {
          handleMultipleUpload(uploadLocation, i + 1); // get error on metadata upload next file on filedata array
        }
        // sessionStorage.setItem("ProgressBox", false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (message === "Uploading...") {
        const confirmationMessage =
          "Changes you made may not be saved. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [message]);

  useEffect(() => {
    console.log(sessionStorage.getItem("userID"));
    setshowProgressbox(sessionStorage.getItem("userID"));
  });
  ///***language */
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_selectaccesstype(
        xpath.select1("/resources/string[@name='text_accesstype']", doc)
          .firstChild.data
      );
      setText_validationemail(
        xpath.select1("/resources/string[@name='text_validationemail']", doc)
          .firstChild.data
      );
      setText_alemail(
        xpath.select1("/resources/string[@name='text_alemail']", doc).firstChild
          .data
      );
      settext_noticeemail(
        xpath.select1("/resources/string[@name='text_noticeemail']", doc)
          .firstChild.data
      );
      setText_valemail(
        xpath.select1("/resources/string[@name='text_valemail']", doc)
          .firstChild.data
      );
      setText_Own_email_err(
        xpath.select1("/resources/string[@name='text_Own_email_err']", doc)
          .firstChild.data
      );
      setText_enteruseremail(
        xpath.select1("/resources/string[@name='text_enteruseremail']", doc)
          .firstChild.data
      );
      setText_skip(
        xpath.select1("/resources/string[@name='text_skip']", doc).firstChild
          .data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      {/*check condition for show progressbox */}
      <div
        className={
          uploadLocation === "" ||
          sessionStorage.getItem("ProgressBox") === "false"
            ? "d-none"
            : ""
        }
      >
        {showProgressBox === "" ||
        showProgressBox === undefined ||
        showProgressBox === null ||
        accesspopup === true ||
        sessionStorage.getItem("ProgressBox") === "false" ? (
          ""
        ) : (
          <div className="p-4">
            <div className="main-box-content">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <div className="d-flex">
                      {/* Progress box main close button on top*/}
                      {closeProgressBox === true ? (
                        <CgCloseO
                          title="Close"
                          className="cancle-sty text-end"
                          onClick={() => [
                            sessionStorage.setItem("ProgressBox", false),
                            setCloseProgressBox(false),
                            window.location.reload(),
                          ]}
                        />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    backgroundColor: "#f7fcfc",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  {/* Uploading item count */}
                  <h6 className="mx-3 mt-1">
                    Uploding{" "}
                    {uploadFrom === "multiple"
                      ? uploadStatusMulti[0].length
                      : "1"}{" "}
                    Item
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="upload-scrollbar">
                    <div>
                      {uploadFrom === "multiple" ? (
                        <>
                          {/* upload file folder data [Progressbar , retry, cancel file] start*/}
                          {multi_pro.map((e, i) => (
                            <>
                              {console.log(e)}
                              <div className="d-flex  justify-content-between flex-wrap">
                                <div className="d-flex gap-1  mt-1">
                                  <img
                                    src={FileImg}
                                    className="login-emailphone"
                                    alt=""
                                  />

                                  <p
                                    className="mx-2 upload-file-truncate"
                                    title={e.filename}
                                  >
                                    {e.filename}
                                  </p>
                                </div>

                                <h6 className="text-end mx-3 ">
                                  {cancelIndex.includes(i) ? (
                                    retryIndex.includes(i) ? (
                                      "Upload Failed"
                                    ) : (
                                      FileUploadCancel
                                    )
                                  ) : (
                                    <>
                                      {e.progress === 100 ? (
                                        retryIconMultiple[i] ? (
                                          <IoReload
                                            title="Reload"
                                            className="cancle-sty"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleRetryMultiple(i)
                                            }
                                          />
                                        ) : (
                                          <>
                                            {e.message === "" ||
                                            e.message === null
                                              ? !retryFileMultiple[i] && (
                                                  <FaCheckCircle className="text-success check-icon" />
                                                )
                                              : ""}
                                          </>
                                        )
                                      ) : (
                                        <>
                                          {retryIconMultiple[i] ? (
                                            <IoReload
                                              title="Reload"
                                              className="cancle-sty"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleRetryMultiple(i)
                                              }
                                            />
                                          ) : (
                                            <CgCloseO
                                              title="Close"
                                              className="cancle-sty"
                                              onClick={() => cancelUpload(i)}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </h6>
                              </div>

                              {cancelIndex.includes(i) ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  {retryFileMultiple[i] ? (
                                    <Loader2 />
                                  ) : (
                                    <>
                                      {e.progress === 100 ? (
                                        ""
                                      ) : (
                                        <div className="d-flex progress-div">
                                          <ProgressBar
                                            now={
                                              e.progress === 0 ? 0 : e.progress
                                            }
                                            label={`${e.progress}%`}
                                            variant="progress-bar"
                                            className="my-progressBar"
                                            visuallyHidden
                                          />
                                          <span className="uploadProgress-font mx-1">
                                            {e.progress}%
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <hr />
                            </>
                          ))}
                          {/* upload file folder data [Progressbar , retry, cancel file] end*/}
                        </>
                      ) : (
                        <>
                          {/* upload file For Single data [Progressbar , retry, cancel file] start*/}
                          <div className="d-flex  justify-content-between flex-wrap">
                            <div className="d-flex gap-1  mt-1">
                              <img
                                src={FileImg}
                                className="login-emailphone"
                                alt=""
                              />

                              <p
                                className="mx-2 upload-file-truncate"
                                title={uploadStatus.name}
                              >
                                {uploadStatus.name}
                              </p>
                            </div>

                            <h6 className="text-end mx-3 ">
                              {FileUploadCancelAtom === "" ? (
                                <>
                                  {retryIcon ? (
                                    <IoReload
                                      className="reload-icon"
                                      onClick={() => handleRetry()}
                                      title="Retry"
                                    />
                                  ) : (
                                    <>
                                      {uploadProgress === 100 && !retryFile ? (
                                        <FaCheckCircle className="text-success check-icon" />
                                      ) : (
                                        !retryFile && (
                                          <CgCloseO
                                            className="cancle-sty"
                                            onClick={() => cancelUploadSingle()}
                                            title="Close"
                                          />
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              ) : retryError !== "" ? (
                                retryError
                              ) : (
                                FileUploadCancel
                              )}
                            </h6>
                          </div>
                          {FileUploadCancelAtom === "" ? (
                            <>
                              {retryFile ? (
                                <Loader2 />
                              ) : (
                                <>
                                  {uploadProgress === 100 ? (
                                    ""
                                  ) : (
                                    <div className="d-flex progress-div">
                                      <ProgressBar
                                        now={
                                          uploadProgress === 0
                                            ? 0
                                            : uploadProgress
                                        }
                                        label={`${uploadProgress}%`}
                                        variant="progress-bar"
                                        className="my-progressBar"
                                        visuallyHidden
                                      />
                                      <span className="uploadProgress-font mx-1">
                                        {uploadProgress}%
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {/* upload file For Single data [Progressbar , retry, cancel file] end*/}
                          <hr />
                        </>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={accesspopup}
        onClose={closeAccessPopup}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center w-100">
          {/* Access data file name and count*/}
          <div className="d-flex justify-content-between">
            <h5 className="mt-1">
              Share{" "}
              {singleMetadata.fileName === "" || uploadFrom === "multiple" ? (
                <>{multiMetadata.length} Item To</>
              ) : (
                <>
                  <img
                    src={fileImg}
                    alt=""
                    style={{
                      height: "19px",
                      width: "19px",
                      marginBottom: "3px",
                    }}
                  />{" "}
                  {singleMetadata.fileName} To
                </>
              )}{" "}
            </h5>
            <h3>
              <CgCloseO
                onClick={() => closeAccessPopup()}
                className="close-popup"
              />
            </h3>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Access data given access email added*/}
            <div className="d-flex flex-wrap">
              <div>
                <div className="participants_box">
                  <div className="tags-input">
                    {emailData &&
                      emailData.map((dataitem, i) => (
                        <li key={i} className="tag">
                          <span className="tag-title">{dataitem}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => handleDelete(i)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    <input
                      className="user-input"
                      type="text"
                      onKeyPress={handleKeyDown}
                      onChange={EmailHandler}
                      value={emailexits}
                      placeholder={
                        emailData.length === 0 && text_enteruseremail
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    filteredSuggestions.length === 0 ? "" : "suggestion-list"
                  }
                  onKeyDown={(e) => onSelectSuggestionKey(e)}
                >
                  {displaySuggestions && (
                    <>
                      {filteredSuggestions.map((suggestion, index) => {
                        return (
                          <div className="suggestion" key={index}>
                            <li
                              className="li-suggestion"
                              onClick={() => onSelectSuggestion(index)}
                            >
                              {suggestion.userEmail}
                            </li>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <p className="mx-3 errMsg">{errEmail}</p>
              <select
                className="accessinput access-select mx-4  mb-4"
                onChange={(e) => [AccessValueHandle(e.target.value)]}
              >
                <option value="" disabled>
                  {text_selectaccesstype}
                </option>
                <option value="read">{text_read}</option>
                <option value="write">
                  {text_read}/{text_write}
                </option>
                <option value="download" selected>
                  {text_read}/{text_write}/{text_download}
                </option>
              </select>
            </div>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <div className="d-flex justify-content-center gap-4 mt-2 mb-3">
          <button className="box-btn" onClick={() => closeAccessPopup()}>
            {text_skip}
          </button>
          <button
            className="box-btn-save"
            onClick={() =>
              emailData.length === 0 ? handleSingleUser() : handleGetUser()
            }
            disabled={shareFileLoader || errEmail !== "" ? true : false}
          >
            {shareFileLoader ? <Loader2 /> : text_share}
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default ProgressBox;
