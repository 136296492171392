import { createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { GLOBAL_URL_DOMAIN_File_Upload_Live } from "../utils/Files/url";
import axios from "axios";
import {
  MultipleUploadMetaData,
  hideProgressBox,
  resetUploadMetaData,
  updateMultiFileFol,
  updateMultiFileFolaws,
  updateotherdata2,
  updateotherdata2Invite,
  updateotherdata2ipfs,
  updateotherdataInvite,
  updateotherdataIpfsInvite,
  updatwMultiPro,
  changeCurrentUploadLoc,
} from "./MetaDataSlice";
import { errorToast } from "../utils/Files/toasterMsgs";
import { getIPFSFileData } from "./IPFSRootFolderSlice";
import { foderwiseAwsFiless } from "./AwsRootFolderSlice";
import {
  setCancelDataFunc,
  setAbortedFunc,
  setAbortErrFunc,
} from "./CancelSlice";

const UploadSLice = createSlice({
  name: "Upload File",
  initialState: {
    uploadprogress: 0,
    file_unique_id: "",
    unique_fileName: "",
    FileUploadCancelAtom: "",
    AccessPopup: false,
    MultipleAbortControllers: [],
    File_id_multi: [],
    FileUploadCancel: "",
    TotalFileCancled: 0,
    chunkNo: 0,
    initialText: false,
    uploadFolder: false,
    uploadFile: false,
    FolderIdSingleAws: "",
    FolderIdSingleIpfs: "",
  },
  reducers: {
    setUploadProgress: (state, action) => {
      return { ...state, uploadprogress: action.payload };
    },
    setFile_unique_id(state, action) {
      return { ...state, file_unique_id: action.payload };
    },

    setUnique_fileName(state, action) {
      return { ...state, unique_fileName: action.payload };
    },
    setFileUploadCancelAtom: (state, action) => {
      return { ...state, FileUploadCancelAtom: action.payload };
    },
    setShowAccessPopup: (state, action) => {
      return { ...state, AccessPopup: action.payload };
    },
    setMultipleAbortControllers: (state, action) => {
      return { ...state, MultipleAbortControllers: action.payload };
    },
    setFile_id_multi: (state, action) => {
      return { ...state, File_id_multi: action.payload };
    },
    setTotalFileCancled: (state, action) => {
      return { ...state, TotalFileCancled: action.payload };
    },
    setFileUploadCancel: (state, action) => {
      return { ...state, FileUploadCancel: action.payload };
    },
    setChunkNo: (state, action) => {
      return { ...state, chunkNo: action.payload };
    },
    setInitialText: (state, action) => {
      return { ...state, initialText: action.payload };
    },
    setUploadFile: (state, action) => {
      return { ...state, uploadFile: action.payload };
    },
    setUploadFolder: (state, action) => {
      return { ...state, uploadFolder: action.payload };
    },
    setFolderIdSingleIpfs: (state, action) => {
      return { ...state, FolderIdSingleIpfs: action.payload };
    },
    setFolderIdSingleAws: (state, action) => {
      return { ...state, FolderIdSingleAws: action.payload };
    },
  },
});

const {
  setUploadProgress,
  setFile_unique_id,
  setUnique_fileName,
  setFileUploadCancelAtom,
  setShowAccessPopup,
  setMultipleAbortControllers,
  setFile_id_multi,
  setFileUploadCancel,
  setTotalFileCancled,
  setChunkNo,
  setInitialText,
  setUploadFolder,
  setUploadFile,
  setFolderIdSingleIpfs,
  setFolderIdSingleAws,
} = UploadSLice.actions;

export default UploadSLice.reducer;

export function UploadFile(
  handleuploadLocation,
  singledata,
  filedata,
  uploadDataFrom,
  abortController,
  nameWithUUID,
  file_unique_id
) {
  return async function UploadFileThunk(dispatch, getstate) {
    try {
      dispatch(
        setCancelDataFunc(0, "chunks", nameWithUUID, new AbortController())
      );
      // Determining bucket name based on upload location
      console.log(handleuploadLocation, singledata, filedata);
      console.log(abortController);
      var Bucket_name = "";
      if (singledata !== undefined) {
        Bucket_name =
          handleuploadLocation === "ipfs"
            ? "atom-storage"
            : singledata.awsBucketName;
      }

      // Logging file details
      console.log("id single:", file_unique_id);
      // Setting file IDs and unique names
      // setFile_id(file_unique_id);
      dispatch(setFile_unique_id(file_unique_id));
      dispatch(setUnique_fileName(nameWithUUID));

      // setFileUniqueName(unique_fileName);
      console.log(filedata.size);
      // Setting chunk size for uploading
      const chunkSize = 10 * 1024 * 1024; // 10 MB (adjust based on your requirements)
      const totalChunks = Math.ceil(filedata.size / chunkSize);
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;

      const uploadNextChunk = async () => {
        // Uploading next chunk if there are more chunks remaining
        if (start < filedata.size) {
          const chunk = filedata.slice(start, end);
          // Extracting necessary data for folder names

          var Folder_name = singledata.awsFolder;
          dispatch(setInitialText(true));

          const formData = new FormData();
          // Appending data to the form for upload
          formData.append("user_id", sessionStorage.getItem("userID"));
          formData.append("ipfsfile", chunk);
          formData.append("fileName", nameWithUUID);
          formData.append("chunkNumber", chunkNumber);
          formData.append("totalChunks", totalChunks);
          formData.append("file_id", file_unique_id);
          formData.append("parent_file_hash", "hashdata");
          formData.append("per_chunk_size", "10MB");
          formData.append("size_in_bytes", filedata.size);
          formData.append("remoteupload", handleuploadLocation);
          formData.append("bucketName", Bucket_name);
          formData.append("folderName", Folder_name);
          formData.append("bucketIdAWS", singledata.bucketId);
          formData.append("ipfsFolderName", singledata.FolderNameIPFS);
          formData.append("bucketIdIPFS", singledata.bucketIdIpfs);
          formData.append("bucketNameIPFS", "atom-storage");
          formData.append("companyId", sessionStorage.getItem("companyID"));
          // Sending fetch request for file upload
          fetch(GLOBAL_URL_DOMAIN_File_Upload_Live + "/file/upload", {
            // signal: abortController.signal,
            signal: getstate().cancel.fileData.abortController.signal,
            method: "POST",
            headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
            },
            body: formData,
          })
            .then((response) => response.json())
            .then((data) => {
              // Handling response from the server
              console.log("data***", data);
              console.log("location***", handleuploadLocation);
              // Setting folder IDs and hashes for IPFS and AWS
              dispatch(
                setFolderIdSingleIpfs(data?.ipfsFolderId, file_unique_id)
              );
              dispatch(setFolderIdSingleAws(data?.awsFolderId, file_unique_id));
              dispatch(updateotherdataInvite(data.awsFolderId, file_unique_id));
              dispatch(
                updateotherdataIpfsInvite(data.ipfsFolderId, file_unique_id)
              );
              //   setHashSingle(data?.DataIpfs?.data?.Hash);
              // Handling upload success or failure
              if (data.upload === true) {
                dispatch(hideProgressBox());
                dispatch(setShowAccessPopup(true));
                dispatch(getIPFSFileData());
                dispatch(foderwiseAwsFiless());
              }
              if (data.success === false) {
                dispatch(
                  cancleSingleUpload(
                    handleuploadLocation,
                    singledata.awsBucketName,
                    file_unique_id,
                    nameWithUUID,
                    uploadDataFrom,
                    abortController
                  )
                );
              } else {
                // Setting upload progress
                dispatch(
                  setUploadProgress(
                    Math.floor(((chunkNumber + 1) / totalChunks) * 100)
                  )
                );
                // Updating chunk number and ranges for next chunk
                dispatch(setChunkNo(chunkNumber));
                chunkNumber++;
                start = end;
                end = Math.min(start + chunkSize, filedata.size);
                // Uploading next chunk
                uploadNextChunk();
              }
            })
            .catch((error) => {
              // Handling errors during upload process
              if (error.name === "AbortError") {
                console.error("File upload canceled..........");
                // Handling session expiration error
                if (error?.response?.status === 427) {
                  sessionStorage.clear();
                  localStorage.clear();
                  Cookie.remove("fName");
                  Cookie.remove("lName");
                  Cookie.remove("userID");
                  Cookie.remove("userToken");
                  Cookie.remove("token_start_time");
                  window.location.replace("/");
                }
              }
              console.error("Error uploading chunk:", error);
              // Handling specific error code 400
              if (error?.response?.status === 400) {
                alert("Error While uploading. Something went wrong");
                sessionStorage.setItem("ProgressBox", false);
                window.location.reload();
              }
            });
        } else {
          dispatch(setCancelDataFunc(0, "completed", nameWithUUID, null));
        }
      };
      // Initiating upload of first chunk
      uploadNextChunk();
    } catch (err) {
      console.log(err);
    }
  };
}

export function cancleSingleUpload(
  handleuploadLocation,
  awsBucketName,
  file_unique_id,
  unique_fileName,
  uploadFrom,
  abortController
) {
  console.log(
    handleuploadLocation,
    "--",
    awsBucketName,
    "--",
    file_unique_id,
    "--",
    unique_fileName,
    "--",
    abortController
  );
  return async function cancleSingleUploadThunk(dispatch, getstate) {
    try {
      console.log(file_unique_id);
      var bucket_name =
        handleuploadLocation === "aws" ? awsBucketName : "atom-storage";
      try {
        abortController && abortController.abort();
        console.log("redux cancle", abortController);
        const API_URL =
          GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;
        const data = {
          file_id: file_unique_id,
          file_name: unique_fileName,
          location: handleuploadLocation,
          bucketName: bucket_name,
        };

        const headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        const res = await axios.post(API_URL, data, headerConfig);

        console.log("cancelUpload res***", res);
        if (res?.data?.success === true) {
          if (uploadFrom === "multiple") {
            // dispatch(setCloseProgressBox(true));
          } else {
            dispatch(resetUploadMetaData());
            dispatch(resetUploadData());
          }
          dispatch(setFileUploadCancelAtom("Upload canceled"));
          dispatch(getIPFSFileData());
          dispatch(foderwiseAwsFiless());
          // setFileUploadCancelAtom("Upload canceled");
          // setTimeout(function () {
          //   window.location.reload();
          // }, 3000);
        }
      } catch (err) {
        console.log("cancelUpload err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function resetUploadData() {
  return async function resetUploadDataThunk(dispatch, getstate) {
    try {
      dispatch(setShowAccessPopup(false));
      dispatch(setFile_unique_id(""));
      dispatch(setUnique_fileName(""));
      dispatch(setFileUploadCancelAtom(""));
      dispatch(setUploadProgress(0));
      dispatch(setMultipleAbortControllers([]));
      dispatch(setFile_id_multi([]));
      dispatch(setFileUploadCancel(""));
      dispatch(setTotalFileCancled(0));
      dispatch(setChunkNo(0));
      dispatch(setInitialText(false));
      dispatch(setUploadFolder(false));
      dispatch(setUploadFile(false));
    } catch (err) {
      console.log(err);
    }
  };
}

export function UploadMultipleFile(
  uploadDataFrom,
  handleuploadLocation,
  multipledata,
  multiplefiledata,
  // multi_pro,
  newFolderData,
  abortController,
  nameWithUUID,
  file_unique_id,
  index
) {
  return async function UploadMultipleFileThunk(dispatch, getstate) {
    try {
      dispatch(
        setCancelDataFunc(index, "chunks", nameWithUUID, new AbortController())
      );
      var multi_pro = getstate().metadata.multi_pro;
      console.log(multi_pro);
      // Determining bucket name based on upload location
      var Bucket_name = "";
      if (multipledata !== undefined) {
        Bucket_name =
          handleuploadLocation === "ipfs"
            ? "atom-storage"
            : multipledata?.awsBucketName;
      }
      // Checking if any files are selected for upload
      console.log();
      if (multiplefiledata[0]?.length === 0 || multiplefiledata === undefined) {
        alert("Please select a file to upload.");
        return;
      }
      dispatch(changeCurrentUploadLoc(index, "chunks"));
      // dispatch(setCancelDataFunc(index, "chunks", nameWithUUID, new AbortController()))

      // Function to upload file chunks
      const uploadFileChunks = async (fileIndex) => {
        console.log(multi_pro.find((data) => data.Cancel_id === fileIndex));

        if (multi_pro.find((data) => data.Cancel_id === fileIndex)) {
          uploadFileChunks(fileIndex + 1);
        } else {
          // one by one files uploaded
          if (fileIndex >= multiplefiledata[0].length) {
            dispatch(hideProgressBox());
            dispatch(setShowAccessPopup(true));
            dispatch(getIPFSFileData());
            dispatch(foderwiseAwsFiless());
          }
          // const abortController = new AbortController();
          dispatch(
            setMultipleAbortControllers((prev) => [...prev, abortController])
          );
          const file = multiplefiledata[0][fileIndex];
          console.log(file, multiplefiledata[0], fileIndex);
          // const uniqueID = uuidv4();
          // const fileNameWithUuid = uniqueID + "_" + file.name;
          // const file_name_with_hyphen = file.name?.replace(/ /g, "-");
          // const unique_fileName = uniqueID + "_" + file_name_with_hyphen;

          // const file_id_prefix =
          //   handleuploadLocation === "aws"
          //     ? "1_0_"
          //     : handleuploadLocation === "ipfs"
          //     ? "0_1_"
          //     : "1_1_";
          // const file_unique_id =
          //   file_id_prefix + uniqueID + "_" + file_name_with_hyphen;

          console.log("name multiple:", nameWithUUID);
          console.log("id multiple:", file_unique_id);

          const data = file.path.lastIndexOf("/");
          const str_data = file.path.slice(1, data + 1);
          console.log(multipledata);

          const data_folder = multipledata.awsFolder.indexOf("/");
          const str_data_folder = multipledata.awsFolder.slice(data_folder + 1);
          const Final_aws_folder =
            multipledata.awsFolder === `${sessionStorage.getItem("userID")}/`
              ? `${sessionStorage.getItem("userID")}/`
              : str_data_folder;
          const final_folder_aws =
            handleuploadLocation === "ipfs"
              ? ""
              : `${Final_aws_folder}${str_data}`; //Folder name send on formdata AWS
          const final_folder_ipfs =
            handleuploadLocation === "aws"
              ? ""
              : `${multipledata.FolderNameIPFS}${str_data}`; //Folder name send on formdata IPFS
          // const File_Folder_aws =
          //   multipledata.awsFolder === final_folder_aws ? "file" : "folder";
          console.log(newFolderData, multiplefiledata);
          const File_Folder_aws =
            newFolderData.length === 0 ? "file" : "folder";
          const File_Folder_ipfs =
            newFolderData.length === 0 ? "file" : "folder";

          dispatch(
            setFile_id_multi((prev) => [
              ...prev,
              {
                fileid: file_unique_id,
                index: fileIndex,
                filename: nameWithUUID,
                foldernameaws:
                  handleuploadLocation === "ipfs"
                    ? final_folder_ipfs
                    : final_folder_aws,
              },
            ])
          );
          const chunkSize = 10 * 1024 * 1024; // 10 MB (adjust based on your requirements)
          const totalChunks = Math.ceil(file.size / chunkSize);

          let chunkNumber = 0;
          let start = 0;
          let end = chunkSize;

          // abortController.current = new AbortController();

          const uploadNextChunk = async () => {
            if (end <= file.size + chunkSize) {
              const chunk = file.slice(start, end);

              console.log("chunk***", chunk);

              if (chunk === 0) {
                return;
              }
              if (chunk.size === 0) {
                console.log(
                  "chunk size is 0. return from here... (multiple files)"
                );
                return;
              }
              dispatch(setInitialText(true));
              const formData = new FormData();

              formData.append("user_id", sessionStorage.getItem("userID"));
              formData.append("ipfsfile", chunk);
              formData.append("fileName", nameWithUUID);
              formData.append("chunkNumber", chunkNumber);
              formData.append("totalChunks", totalChunks);
              formData.append("file_id", file_unique_id);
              formData.append("parent_file_hash", "hashDataMulti[i]");
              formData.append("per_chunk_size", "10MB");
              formData.append("size_in_bytes", file.size);
              formData.append("remoteupload", handleuploadLocation);
              formData.append("bucketName", Bucket_name);
              formData.append("folderName", final_folder_aws);
              formData.append("bucketIdAWS", multipledata.bucketId);
              formData.append("ipfsFolderName", final_folder_ipfs);
              formData.append("bucketIdIPFS", multipledata.bucketIdIpfs);
              formData.append("bucketNameIPFS", "atom-storage");
              formData.append("companyId", sessionStorage.getItem("companyID"));

              fetch(GLOBAL_URL_DOMAIN_File_Upload_Live + "/file/upload", {
                // signal: abortController.signal,
                signal: getstate().cancel.fileData.abortController.signal,
                method: "POST",
                headers: {
                  authorization:
                    "Bearer " + sessionStorage.getItem("userToken"),
                },
                body: formData,
              })
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  console.log(data);
                  //if get error than cancel file
                  if (data?.success === false) {
                    // cancelUpload(fileIndex);
                    uploadFileChunks(fileIndex + 1);
                  } else {
                    if (data?.success === true && data?.upload === true) {
                      dispatch(
                        setCancelDataFunc(
                          index,
                          "completed",
                          nameWithUUID,
                          null
                        )
                      );
                      dispatch(
                        updateotherdata2(data?.awsFolderId, file_unique_id)
                      );
                      dispatch(
                        updateotherdata2ipfs(data?.ipfsFolderId, file_unique_id)
                      );
                      dispatch(
                        updateotherdataInvite(data?.awsFolderId, file_unique_id)
                      );
                      dispatch(
                        updateotherdataIpfsInvite(
                          data?.ipfsFolderId,
                          file_unique_id
                        )
                      );
                      dispatch(
                        updateMultiFileFol(data?.awsFolderId, file_unique_id)
                      );
                      dispatch(
                        updateMultiFileFolaws(data?.awsFolderId, file_unique_id)
                      );
                      // dispatch(changeCurrentUploadLoc(fileIndex, "completed"))

                      dispatch(
                        MultipleUploadMetaData(
                          uploadDataFrom,
                          handleuploadLocation,
                          multipledata,
                          multiplefiledata,
                          multi_pro,
                          newFolderData,
                          abortController,
                          index + 1
                        )
                      );
                      console.log(
                        index,
                        multi_pro.length,
                        fileIndex,
                        multiplefiledata[0].length
                      );
                      // if (handleuploadLocation === "aws") {
                      //   if (File_Folder_aws === "file") {
                      //     dispatch(setUploadFile(true));
                      //   }
                      //   if (File_Folder_aws === "folder") {
                      //     dispatch(setUploadFolder(true));
                      //   }
                      // } else if (handleuploadLocation === "ipfs") {
                      //   if (File_Folder_ipfs === "file") {
                      //     dispatch(setUploadFile(true));
                      //   }
                      //   if (File_Folder_ipfs === "folder") {
                      //     dispatch(setUploadFolder(true));
                      //   }
                      // }
                      if (
                        multiplefiledata[0].some((v) => v.path.startsWith("/"))
                      ) {
                        // folder
                        console.log("folder");
                        dispatch(setUploadFolder(true));

                        // setFolderDragged(true)
                      }
                      if (
                        multiplefiledata[0].some((v) => !v.path.startsWith("/"))
                      ) {
                        // file
                        console.log("file");
                        dispatch(setUploadFile(true));

                        // setFileDragged(true)
                      }

                      if (index + 1 === multi_pro.length) {
                        console.log("innn");
                        dispatch(hideProgressBox());
                        dispatch(setShowAccessPopup(true));
                      }
                    }

                    // setUploadProgress(
                    //   Math.floor(((chunkNumber + 1) / totalChunks) * 100)
                    // );
                    // const ind = multi_pro.findIndex(
                    //   (value) => value.index === fileIndex
                    // );
                    // if (ind !== -1) {
                    //   multi_pro[fileIndex].progress = Math.floor(
                    //     ((chunkNumber + 1) / totalChunks) * 100
                    //   );
                    //   console.log(multi_pro[fileIndex].progress);
                    // }
                    console.log(
                      multi_pro,
                      index,
                      Math.floor(((chunkNumber + 1) / totalChunks) * 100)
                    );
                    dispatch(
                      updatwMultiPro(
                        multi_pro,
                        index,
                        Math.floor(((chunkNumber + 1) / totalChunks) * 100)
                      )
                    );

                    if (data?.DataIpfs?.success === true) {
                      console.log("========== additional if block ==========");
                      multi_pro[fileIndex].progress = 100;
                    }
                    if (data?.DataIpfs?.success === false) {
                      multi_pro[fileIndex].progress = -1;
                    }
                    if (data?.DataAws) {
                      console.log("========== additional if block ==========");
                      multi_pro[fileIndex].progress = 100;
                    }
                    dispatch(setChunkNo(chunkNumber));
                    chunkNumber++;
                    start = end;
                    end = start + chunkSize;
                    uploadNextChunk();
                    // if (successfulUploadsAWS !== 0) {
                    //   sessionStorage.setItem(
                    //     "successfulUploadsAWS",
                    //     successfulUploadsAWS + 1
                    //   );
                    // }
                  }
                })
                .catch((error) => {
                  console.error("Error uploading chunk:", error);
                  if (error?.response?.status === 400) {
                    errorToast("Error While uploading. Something went wrong");
                    sessionStorage.setItem("ProgressBox", false);
                    window.location.reload();
                  }
                  if (error?.response?.status === 427) {
                    sessionStorage.clear();
                    localStorage.clear();
                    Cookie.remove("fName");
                    Cookie.remove("lName");
                    Cookie.remove("userID");
                    Cookie.remove("userToken");
                    Cookie.remove("token_start_time");
                    window.location.replace("/");
                  }
                });
            } else {
              setUploadProgress(100);
              dispatch(changeCurrentUploadLoc(fileIndex, "completed"));

              const ind = multiplefiledata.findIndex(
                (value) => value.index === fileIndex
              );
              if (ind !== -1) {
                multiplefiledata[fileIndex].progress = 100;
              }
            }
          };
          uploadNextChunk();
        }
      };
      var Index_file =
        index === "" || index === null || index === undefined ? 0 : index;
      uploadFileChunks(Index_file); //upload file func call start from here
    } catch (err) {
      console.log(err);
    }
  };
}

export function cancleMultipleUpload(
  uploadDataFrom,
  uploadLocation,
  multipledata,
  File_id_multi,
  multi_pro,
  newFolderData,
  file_id,
  fileUniqueName,
  allFileDataMulti,
  i,
  bucket_name,
  multipleabortController
) {
  return async function cancleSingleUploadThunk(dispatch, getstate) {
    try {
      const abortController = multipleabortController[i];
      abortController && abortController.abort();
      console.log(abortController, i, multipleabortController);
      console.log(File_id_multi);
      // calling the "cancel_upload" API
      const API_URL =
        GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      const data = {
        file_id:
          uploadDataFrom === "multiple" ? File_id_multi[i].fileid : file_id,
        file_name:
          uploadDataFrom === "multiple"
            ? File_id_multi[i].filename
            : fileUniqueName,
        location: uploadDataFrom,
        bucketName: bucket_name,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.post(API_URL, data, headerConfig);

      console.log("cancelUpload res***", res);

      if (res?.data?.success === true) {
        if (uploadDataFrom === "multiple") {
          if (i === allFileDataMulti[0].length) {
            // setCloseProgressBox(true);
            dispatch(resetUploadMetaData());
            dispatch(resetUploadData());
          } else {
            dispatch(hideProgressBox());
            // handleMultipleUpload(uploadLocation, i + 1);
            dispatch(
              MultipleUploadMetaData(
                uploadLocation,
                uploadDataFrom,
                multipledata,
                allFileDataMulti,
                multi_pro,
                newFolderData,
                abortController,
                i + 1
              )
            );
          }
        } else {
          // setCloseProgressBox(true);
          dispatch(hideProgressBox());
        }
        dispatch(setFileUploadCancel("Upload canceled"));
        let uploadcancelOneFile = allFileDataMulti[0].length;
        dispatch(
          setTotalFileCancled(
            (prevTotalFilesCancelled) =>
              prevTotalFilesCancelled + uploadcancelOneFile
          )
        );
        // sessionStorage.setItem("totalFilecancled", totalFilecancled);
      }
    } catch (err) {
      console.log("cancelUpload err***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      }
    }
  };
}

export function resetUploadInfo() {
  return async function resetUploadInfo(dispatch, getstate) {
    try {
      dispatch(setChunkNo(0));
      // dispatch(setInitialText(false));
    } catch (err) {
      console.log("resetUploadInfo err***", err);
    }
  };
}

export function cancel_multi_upload_chunks(
  uploadFrom,
  uploadLocation,
  multipledata,
  allFileDataMulti,
  multi_pro,
  newFolderData,
  abortController2,
  ind
) {
  return async function cancel_multi_upload_chunksThunk(dispatch, getState) {
    try {
      // aborting the request
      const allState = getState();
      const abortController = allState.cancel.fileData.abortController;
      abortController && abortController.abort();

      const file_unique_id = allState.metadata.file_unique_id;
      const file_name = file_unique_id.slice(4);

      // calling the cancel upload api
      const data = {
        file_id: file_unique_id,
        file_name: file_name,
        location: uploadLocation,
        bucketName:
          uploadLocation === "ipfs"
            ? "atom-storage"
            : multipledata.awsBucketName,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const url = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      const res = await axios.post(url, data, headerConfig);

      console.log("new cancel res chunks***", res);

      dispatch(setAbortedFunc(ind));

      dispatch(
        MultipleUploadMetaData(
          uploadFrom,
          uploadLocation,
          multipledata,
          allFileDataMulti,
          multi_pro,
          newFolderData,
          abortController2,
          ind + 1
        )
      );
    } catch (err) {
      console.log("cancel_multi_upload_chunks err:", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      } else {
        dispatch(setAbortErrFunc(ind));
        dispatch(
          MultipleUploadMetaData(
            uploadFrom,
            uploadLocation,
            multipledata,
            allFileDataMulti,
            multi_pro,
            newFolderData,
            abortController2,
            ind + 1
          )
        );
      }
    }
  };
}

export function cancel_single_upload_chunks(
  uploadLocation,
  bucketName,
  file_unique_id,
  unique_fileName
) {
  return async function cancel_single_upload_chunksThunk(dispatch, getState) {
    try {
      // aborting the request
      const allState = getState();
      const abortController = allState.cancel.fileData.abortController;
      abortController && abortController.abort();

      // calling the cancel upload api
      const data = {
        file_id: file_unique_id,
        file_name: unique_fileName,
        location: uploadLocation,
        bucketName: uploadLocation === "ipfs" ? "atom-storage" : bucketName,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const url = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      await axios.post(url, data, headerConfig);

      dispatch(setAbortedFunc(0));
    } catch (err) {
      console.log("cancel_single_upload_chunks err:", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      } else {
        dispatch(setAbortErrFunc(0));
      }
    }
  };
}
