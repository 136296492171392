import React, { useState } from "react";
import AtomImg from "../../utils/images/atom_1.png";
import AtomImgActive from "../../utils/images/atom_2.png";
import { Link } from "react-router-dom";
function SubHeader() {
  const [headerColorChange, setHeaderColorChange] = useState(false);

  return (
    <>
      <div className="">
        <div className={headerColorChange ? "header-main-active" : "header"}>
          <div className="subheader-1">
            <div className="header-groupimg-div">
              <Link to="/">
                {headerColorChange ? (
                  <img src={AtomImgActive} alt="" className="header-groupimg" />
                ) : (
                  <img src={AtomImg} alt="" className="header-groupimg" />
                )}
              </Link>
            </div>
            <div className="header-sub">Atom Storage</div>
          </div>
          <div className="subheader-2">
            <Link to="/" className="header-text">
              <div className="header-sub">Home</div>
            </Link>
            {/* <div className="header-sub">FAQ</div> */}
            <div className="header-sub">
              <Link to="/signin">
                <button className="subheader-login">Log in</button>
              </Link>
            </div>
            <div className="header-sub">
              <Link to="/signup">
                {" "}
                <button className="subheader-getstarted">Get Started</button>
              </Link>
            </div>
            <div className="header-sub"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubHeader;
