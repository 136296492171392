import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import PassImg from "../../utils/images/lock.png";
import CPassImg from "../../utils/images/lock (2).png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import atomImg from "../../utils/images/atom_1.png";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookie from "js-cookie";
import axios from "axios";
import {
  GLOBAL_URL_DOMAIN,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import { errorToast } from "../../utils/Files/toasterMsgs";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { PASSWORD_REGEX } from "../../utils/Files/validation";

const NewPassword = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disablebtn, setDisableBtn] = useState(false);
  const [userid, setUserid] = useState("");
  const [validEmailMessage, setValidEmailMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [passerror, setPasserror] = useState("");
  const [confpasserror, setConfPasserror] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [passVisibilityChang, setPassVisibilityChang] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
    navigate("/signin");
  };

  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };

  const changeVisibilityChang = (e) => {
    e.preventDefault();
    setPassVisibilityChang(!passVisibilityChang);
  };
  const resetPassValidation = () => {
    let resetPassWordvalid = true;
    if (!PASSWORD_REGEX.test(password) === true) {
      resetPassWordvalid = false;
      if (password === "") {
        resetPassWordvalid = false;
        setPasserror("*Please enter password!");
      } else {
        setPasserror("*Please Enter Strong Password!");
      }
    }
    if (confirmPassword !== password) {
      resetPassWordvalid = false;
      if (confirmPassword === "") {
        resetPassWordvalid = false;
        setConfPasserror("*Please enter confirm password!");
      } else {
        setConfPasserror("*Confirm Password Does Not Match With Password!");
      }
    }
    return resetPassWordvalid;
  };
  const handleResetPassword = () => {
    if (resetPassValidation()) {
      var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/new-password`;
      var data = {
        password: password,
        userId: userid,
      };
      axios
        .post(API_URL, data)
        .then((res) => {
          var val = res?.data?.data;
          console.log("userEmail:-", res);
          setOpen(true);
        })
        .catch((err) => {
          console.log("invitation_email_verified err***", err);
          if (err?.response?.data?.success === false) {
            console.log(err?.response?.data?.message);
            // errorToast(err.response.data.message, 5000);
            alert(err?.response?.data?.message); // T
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          }
        })
        .then(function () {});
    }
  };

  // ----- remember me starts -----

  const setDataToSessionFromCookie = async () => {
    var fName_InCookie = Cookie.get("fName");
    var lName_InCookie = Cookie.get("lName");
    var userID_InCookie = Cookie.get("userID");
    var userToken_InCookie = Cookie.get("userToken");

    sessionStorage.setItem("fName", fName_InCookie);
    sessionStorage.setItem("lName", lName_InCookie);
    sessionStorage.setItem("userID", userID_InCookie);
    sessionStorage.setItem("userToken", userToken_InCookie);
  };

  useEffect(() => {
    var tokenInCookie = Cookie.get("userToken");
    var token_start_timeInCookie = Cookie.get("token_start_time");

    if (tokenInCookie) {
      if (Date.now() - token_start_timeInCookie < 86400) {
        var fName_InCookie = Cookie.get("fName");
        var lName_InCookie = Cookie.get("lName");
        var userID_InCookie = Cookie.get("userID");

        setDataToSessionFromCookie().then(() => {
          navigate("/my-files", {
            state: {
              tokenInCookie,
              fName_InCookie,
              lName_InCookie,
              userID_InCookie,
            },
          });
        });
      } else {
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("user_id");
        Cookie.remove("token_start_time");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
    }
  }, []);

  // ----- remember me ends -----

  // ----- forgot password email starts -----
  useEffect(() => {
    const FPVerificationFunc = async (token, userid) => {
      console.log("token:", token);
      console.log("userid:", userid);
      setUserid(userid);
      try {
        const API_URL =
          GLOBAL_URL_DOMAIN_LIVE + "/user/verify-forgot-password-email";
        const data = {
          token,
          userid,
        };
        const res = await axios.post(API_URL, data);
        console.log("FPVerificationFunc res***", res);
        if (res?.data?.success === true) {
          console.log(res.data.message);
        }
      } catch (err) {
        console.log("FPVerificationFunc err***", err);
        if (err?.response?.data?.success === false) {
          console.log(err?.response?.data?.message);
          setValidEmailMessage(err?.response?.data?.message); // T
          if (err?.response?.data?.message === "This email is not valid!") {
            setOpen(true);
          }
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        }
      }
    };

    if (
      searchParams.get("key1") !== null &&
      searchParams.get("key2") !== null &&
      searchParams.get("source") !== null &&
      searchParams.get("source") === "reset-password-email"
    ) {
      FPVerificationFunc(
        searchParams.get("key1"), // token
        searchParams.get("key2") // userid
      );
    }
  }, [searchParams]);

  // ----- forgot password email ends -----

  const handleRedirect = () => {
    if (validEmailMessage === "This email is not valid!") {
      navigate("/forgot-password");
    } else {
      navigate("/signin");
    }
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (disablebtn) {
        return;
      }
      setDisableBtn(false);
      handleResetPassword();
    }
  };
  return (
    <div className="login-main" onKeyPress={keyHandler}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          {validEmailMessage === "This email is not valid!" ? (
            <>
              <DialogContentText>
                <h3 className="text-center">
                  Your verification link has expired
                </h3>
                <h5 className="text-center">
                  Click below to request a new verification link.
                </h5>
              </DialogContentText>
            </>
          ) : (
            <>
              <DialogContentText>
                <h3 className="text-center">
                  Your password has been successfully reseted
                </h3>
              </DialogContentText>
            </>
          )}

          <Divider />
        </DialogContent>
        <DialogActions>
          <button className="confirm-btn mx-4" onClick={() => handleRedirect()}>
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <div className="form login-form fp-form">
        <div className="login-title-main">
          <div className="login-title-logo">
            <img src={atomImg} alt="" className="login-atom-img" />
          </div>
          <div className="login-title-text">Reset Password</div>
        </div>
        <div className="login-subtitle">
          <p className="login-subtitle-1">
            Securely Reset Your Password and Regain Access to Your Account.
          </p>
        </div>
        <div className="login-content">
          <div className="flex-column">
            <label className="login-labels">New Password</label>
          </div>
          <div className="inputForm login-foreye">
            <img src={PassImg} alt="" className="login-emailphone" />
            <input
              type={passVisibility ? "text" : "password"}
              className="input login-input login-input-2 login-input-foreye"
              placeholder="Enter New Password"
              onChange={(e) => [setPassword(e.target.value), setPasserror("")]}
            />
            <button className="login-eye" onClick={(e) => changeVisibility(e)}>
              {passVisibility === true ? <BsEyeFill /> : <BsEyeSlashFill />}
            </button>
          </div>
          <span className="errMsg">{passerror}</span>
          <div className="flex-column">
            <label className="login-labels">Confirm Password</label>
          </div>
          <div className="inputForm login-foreye">
            <img src={CPassImg} alt="" className="login-emailphone" />
            <input
              type={passVisibilityChang ? "text" : "password"}
              className="input login-input login-input-2 login-input-foreye"
              placeholder="Confirm New Password"
              onChange={(e) => [
                setConfirmPassword(e.target.value),
                setConfPasserror(""),
              ]}
            />
            <button
              className="login-eye"
              onClick={(e) => changeVisibilityChang(e)}
            >
              {passVisibilityChang === true ? (
                <BsEyeFill />
              ) : (
                <BsEyeSlashFill />
              )}
            </button>
          </div>
          <span className="errMsg">{confpasserror}</span>
          <button className="button-submit mt-2" onClick={handleResetPassword}>
            Change Password
          </button>
          <p className="p login-last-text">
            <center>
              <div className="reset-fp-back">
                <Link to="/signin" className="login-fp-inner">
                  <span className="span fp-last">
                    <IoIosArrowBack /> Back to Sign In
                  </span>
                </Link>
              </div>
            </center>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
