import React, { useEffect, useState } from "react";
import "./Login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmailPhoneImg from "../../utils/images/EmailPhone.png";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Loader2 from "../../Components/Loader2/Loader2";
import atomImg from "../../utils/images/atom_1.png";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import { ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { resetSelectBucketData } from "../../redux/SelectBucketSlice";
import { resetShareWithMeData } from "../../redux/sharewithmeslice";
import { resetUserListData } from "../../redux/UserListSlice";
import { resetIPFSrootfileData } from "../../redux/IPFSRootFolderSlice";
import { resetAwsRootFolderData } from "../../redux/AwsRootFolderSlice";
import { resetGetBucketData } from "../../redux/GetBucketdataSlice";
import { resetBothfileData } from "../../redux/BothFilesSlice";
import { resetgetCompanyData } from "../../redux/CompanyProfileSlice";
import { resetgetRegisterdUser } from "../../redux/RegisteredUserSlice";
import { resetEditFileData } from "../../redux/EditFileDataSlice";
import { resetSharedByMeData } from "../../redux/SharedByMeSlice";
import { useDispatch } from "react-redux";


const Login = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  // validation errs
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSelectBucketData());
    dispatch(resetShareWithMeData());
    dispatch(resetUserListData());
    dispatch(resetIPFSrootfileData());
    dispatch(resetAwsRootFolderData());
    dispatch(resetGetBucketData());
    dispatch(resetBothfileData());
    dispatch(resetgetCompanyData());
    dispatch(resetgetRegisterdUser());
    dispatch(resetEditFileData());
    dispatch(resetSharedByMeData());
  }, []);

  // ----- remember me starts -----
  const setDataToSessionFromCookie = async () => {
    var fName_InCookie = Cookie.get("fName");
    var lName_InCookie = Cookie.get("lName");
    var userID_InCookie = Cookie.get("userID");
    var userToken_InCookie = Cookie.get("userToken");
    var token_start_time = Cookie.get("token_start_time");
    var email = Cookie.get("email");
    var mobile = Cookie.get("mobile");
    var companyID = Cookie.get("companyID");
    var companyName = Cookie.get("companyName");
    var isorg = Cookie.get("isorg");
    var role = Cookie.get("role");
    // add all variable in cookie

    // add these variables in session as well
    sessionStorage.setItem("fName", fName_InCookie);
    sessionStorage.setItem("lName", lName_InCookie);
    sessionStorage.setItem("userID", userID_InCookie);
    sessionStorage.setItem("userToken", userToken_InCookie);
    sessionStorage.setItem("token_start_time", token_start_time);
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("mobile", mobile);
    sessionStorage.setItem("companyID", companyID);
    sessionStorage.setItem("companyName", companyName);
    sessionStorage.setItem("isorg", isorg);
    sessionStorage.setItem("role", role);
  };
  useEffect(() => {
    var tokenInCookie = Cookie.get("userToken");
    var token_start_timeInCookie = Cookie.get("token_start_time");

    if (tokenInCookie) {
      if (Date.now() - token_start_timeInCookie < 86400000) {
        setDataToSessionFromCookie().then(() => {
          setTimeout(() => {
            navigate("/selectbucket");
          }, 1000);
        });
      } else {
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("user_id");
        Cookie.remove("token_start_time");
        Cookie.remove("email");
        Cookie.remove("mobile");
        Cookie.remove("companyID");
        Cookie.remove("companyName");
        Cookie.remove("isorg");
        Cookie.remove("role");
        // remove all variables from cookie or else clear all variables from cookie at once (check method in library)
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
    }
  }, []);
  // ----- remember me ends -----

  // ----- access email starts ----- (3)
  useEffect(() => {
    const accessMailVerificationFunc = async (userid, token) => {
      try {
        const API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/verify-access-mail";
        const data = {
          userid,
          token,
        };
        const res = await axios.post(API_URL, data);
        console.log("accessMailVerificationFunc res***", res);
        if (res?.data?.success === true) {
          console.log(res.data.message);
        }
      } catch (err) {
        console.log("accessMailVerificationFunc err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      }
    };

    if (
      searchParams.get("k1") !== null &&
      searchParams.get("k2") !== null &&
      searchParams.get("source") !== null &&
      searchParams.get("source") === "access-email"
    ) {
      accessMailVerificationFunc(
        searchParams.get("k1"), // userid
        searchParams.get("k2") // token
      );
    }
  }, [searchParams]);
  // ----- access email ends -----

  // ----- add user mail verification starts -----  (4)
  const invitation_email_verified = async (userid, companyid) => {
    try {
      const API_URL =
        GLOBAL_URL_DOMAIN_LIVE + "/user/invitation-email-verified";
      const data = {
        userid: userid,
        companyid: companyid,
      };
      const res = await axios.post(API_URL, data);
      console.log("invitation_email_verified res***", res);
      if (res?.data?.success === true) {
        console.log(res?.data?.message);
      }
    } catch (err) {
      console.log("invitation_email_verified err***", err);
      if (err?.response?.data?.success === false) {
        alert(err?.response?.data?.message);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      }
    }
  };
  useEffect(() => {
    const handleVerifyUserInvitation = async (userid, companyid, token) => {
      try {
        const API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/user/verify-add-user-email?userid=${userid}&companyid=${companyid}&token=${token}`;

        const res = await axios.post(API_URL);
        console.log("handleVerifyUserInvitation res***", res);
        if (res?.data?.success === true) {
          console.log("coming from an email. invitation is still valid");
          invitation_email_verified(userid, companyid);
        }
      } catch (err) {
        console.log("handleVerifyUserInvitation err***", err);
        if (err?.response?.data?.success === false) {
          console.log("coming from an email. invitation is invalid");

          alert(err?.response?.data?.message);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        }
      }
    };

    if (
      searchParams.get("key1") !== null &&
      searchParams.get("key2") !== null &&
      searchParams.get("key3") !== null &&
      searchParams.get("source") !== null &&
      searchParams.get("source") === "email"
    ) {
      handleVerifyUserInvitation(
        searchParams.get("key1"), // userid
        searchParams.get("key2"), // companyid
        searchParams.get("key3") // token
      );
    }
  }, [searchParams]);
  // ----- add user mail verification ends -----

  // ----- account verification mail starts -----  (2)
  useEffect(() => {
    const accountVerificationFunc = async (userid, token, isOrg, companyId) => {
      try {
        const API_URL =
          GLOBAL_URL_DOMAIN_LIVE + "/user/verify-account-with-link";
        const data = {
          userid,
          token,
          isOrg,
          companyId,
        };
        const res = await axios.post(API_URL, data);
        console.log("accountVerificationFunc res***", res);
        if (res?.data?.success === true) {
          console.log(res.data.message);
        }
      } catch (err) {
        console.log("accountVerificationFunc err***", err);
        if (err.response?.data?.success === false) {
          console.log(err?.response?.data?.message);
          alert(err?.response?.data?.message); // T
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        }
      }
    };

    if (
      searchParams.get("k1") !== null &&
      searchParams.get("k2") !== null &&
      searchParams.get("k3") !== null &&
      searchParams.get("source") !== null &&
      searchParams.get("source") === "account-verification-email"
    ) {
      accountVerificationFunc(
        searchParams.get("k1"), // userid
        searchParams.get("k2"), // token
        searchParams.get("k3"), // isOrg
        searchParams.get("k4") // companyId
      );
    }
  }, [searchParams]);
  // ----- account verification mail ends -----

  // ----- having trouble signing in mail starts ----- (1)
  useEffect(() => {
    const havingTroubleSigninginFunc = async (userid, companyId, token) => {
      try {
        const API_URL =
          GLOBAL_URL_DOMAIN_LIVE + "/user/verify-having-trouble-login";
        const data = {
          userid,
          companyId,
          token,
        };
        const res = await axios.post(API_URL, data);
        console.log("havingTroubleSigninginFunc res***", res);
        if (res?.data?.success === true) {
          console.log(res?.data?.message);
        }
      } catch (err) {
        console.log("havingTroubleSigninginFunc err***", err);
        if (err?.response?.data?.success === false) {
          console.log(err?.response?.data?.message);
          alert(err?.response?.data?.message);
        }
      }
    };

    if (
      searchParams.get("key1") !== null &&
      searchParams.get("key3") !== null &&
      searchParams.get("source") !== null &&
      searchParams.get("source") === "having-trouble-signing-in-email"
    ) {
      havingTroubleSigninginFunc(
        searchParams.get("key1"), // userid
        searchParams.get("key2"), // companyId
        searchParams.get("key3") // token
      );
    }
  }, [searchParams]);
  // ----- having trouble signing in mail ends -----

  const changeVisibility = () => {
    setPassVisibility(!passVisibility);
  };

  const handleChange = (e) => {
    var value = e.target.value;
    var NumberRegex = /^[0-9]+(\.[0-9]+)?$/;
    if (NumberRegex.test(value) === true) {
      setEmailOrPhone("+" + e.target.value);
    } else {
      setEmailOrPhone(e.target.value);
    }
  };

  const signInValidation = () => {
    let signInValidation = true;
    if (!emailOrPhone) {
      setEmailErr("*Please enter Email or Phone Number!");
      signInValidation = false;
    }
    if (!password) {
      setPassErr("*Please enter Password!");
      signInValidation = false;
    }
    return signInValidation;
  };

  const handleSignIn = () => {
    if (signInValidation()) {
      setIsLoading(true);

      var data = {
        EmailOrPhone: emailOrPhone.toLowerCase(),
        password: password,
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/signin";

      axios
        .post(API_URL, data)
        .then((res) => {
          console.log("login res***", res);
          if (res.data.success === true) {
            sessionStorage.setItem("fName", res?.data?.data?.firstName);
            sessionStorage.setItem("lName", res?.data?.data?.lastName);
            sessionStorage.setItem("email", res?.data?.data?.email);
            sessionStorage.setItem("mobile", res?.data?.data?.mobileNumber);

            sessionStorage.setItem("userID", res?.data?.data._id);
            sessionStorage.setItem("userToken", res?.data?.token);
            sessionStorage.setItem("companyID", res?.data?.companyId);
            sessionStorage.setItem("companyName", res?.data?.data?.companyName);
            sessionStorage.setItem("isorg", res?.data?.data?.isOrg);
            sessionStorage.setItem("role", res?.data?.role);

            // ----- for remembering the user starts -----
            if (rememberMe) {
              Cookie.set("fName", res?.data?.data?.firstName);
              Cookie.set("lName", res?.data?.data?.lastName);
              Cookie.set("userID", res?.data?.data._id);
              Cookie.set("userToken", res?.data?.token);
              Cookie.set("token_start_time", Date.now());
              Cookie.set("email", res?.data?.data?.email);
              Cookie.set("mobile", res?.data?.data?.mobileNumber);
              Cookie.set("companyID", res?.data?.companyId);
              Cookie.set("companyName", res?.data?.data?.companyName);
              Cookie.set("isorg", res?.data?.data?.isOrg);
              Cookie.set("role", res?.data?.role);
            }
            // ----- for remembering the user ends -----

            navigate("/selectbucket");

            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("login err***", err);

          setIsLoading(false);

          if (
            err?.response?.data?.message === "Please verify your account first!"
          ) {
            setOpen(true);
          } else {
            setErrmsg(err?.response?.data?.message);
          }
          console.log(err?.response?.data?.message);

          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (isLoading) {
        return;
      }
      setIsLoading(false);
      handleSignIn();
    }
  };
  return (
    <div className="login-main" onKeyPress={keyHandler}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Please Verify Your Account</h3>
            <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h5 className="text-center">
              Please check your Spam or Junk folder.
            </h5>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <DialogActions>
          <button className="confirm-btn mx-4" onClick={handleClose}>
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <div className="form login-form">
        <div className="login-title-main">
          <div className="login-title-logo">
            <img src={atomImg} alt="" className="login-atom-img" />
          </div>
          <div className="login-title-text">Sign In</div>
        </div>
        <div className="login-subtitle">
          <p className="login-subtitle-1">
            Experience Seamless and Secure File Upload and Retrieval.
          </p>
        </div>
        <div className="login-content">
          <div className="flex-column">
            <label className="login-labels">Email or Phone Number</label>
          </div>
          <div className="inputForm">
            <img src={EmailPhoneImg} alt="" className="login-emailphone" />
            <input
              type="text"
              className="input login-input login-placeholder"
              placeholder="Enter your Email or Phone Number (+1xxxxxxxxx)"
              onChange={(e) => [
                setEmailOrPhone(e.target.value),
                handleChange(e),
                setEmailErr(""),
                setErrmsg(""),
              ]}
              value={emailOrPhone.toLowerCase()}
            />
          </div>

          <span className="errMsg">{emailErr}</span>
          <div className="flex-column">
            <label className="login-labels">Password </label>
          </div>
          <div className="inputForm login-foreye">
            <svg
              height="20"
              viewBox="-64 0 512 512"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
              className="login-lock"
            >
              <path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"></path>
              <path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"></path>
            </svg>
            <input
              type={passVisibility ? "text" : "password"}
              className="input login-input login-input-2 login-input-foreye login-placeholder"
              placeholder="Enter your Password"
              onChange={(e) => [
                setPassword(e.target.value),
                setPassErr(""),
                setErrmsg(""),
              ]}
              value={password}
            />
            <button className="login-eye" onClick={(e) => changeVisibility(e)}>
              {passVisibility === true ? <BsEyeFill /> : <BsEyeSlashFill />}
            </button>
          </div>
          <span className="errMsg">{passErr}</span>
          <div className="flex-row mb-4">
            <div className="d-flex justify-content-center gap-2">
              <input
                type="checkbox"
                className="login-checkbox-icon"
                onClick={(e) => setRememberMe(e.target.checked)}
              />
              <label className="login-checkbox">Remember me</label>
            </div>
            <span className="span login-fp">
              <Link to="/forgot-password" className="login-fp-inner">
                Forgot password?
              </Link>
            </span>
          </div>
          <p className="loginerr-p">{errmsg}</p>
          {isLoading ? (
            <button className="button-submit login-btn-loader" disabled>
              Signing In... <Loader2 />
            </button>
          ) : (
            <button className="button-submit" onClick={(e) => handleSignIn(e)}>
              Sign In
            </button>
          )}

          <h5 className="p login_having_trouble mt-2 dontacc-login">
            Don't have an account?{" "}
            <span className="span">
              <Link to="/signup" className="login-fp-inner ">
                Sign Up
              </Link>
            </span>
          </h5>
          <p>
            {" "}
            <Link to="/support">
              <center>
                {" "}
                <u className="troubletext"> Having Trouble signing in?</u>
              </center>
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
