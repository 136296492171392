import React, { useEffect, useState } from "react";
import "./UploadedFile.css";
import Header from "../../Components/Header/Header";
import FileImg from "../../utils/images/file_1.png";
import FiletypeImg from "../../utils/images/abc (1).png";
import DescImg from "../../utils/images/desc_4.png";
import HashImg from "../../utils/images/hash.png";
import keywordImg from "../../utils/images/kw_10.png";
import OrgImg from "../../utils/images/organization (3).png";
import DatePicker from "react-datepicker";
import DOBImg from "../../utils/images/calendar (1).png";
import UserImg from "../../utils/images/user (5).png";
import AgeImg from "../../utils/images/age.png";
import GenderImg from "../../utils/images/gender.png";
import EmailImg from "../../utils/images/email.png";
import PhoneImg from "../../utils/images/telephone (3).png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import IconImg from "../../utils/images/check (3) 2.png";
import awsLinkImg from "../../utils/images/link (1).png";
import cIDImg from "../../utils/images/letter-a.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GlobalConstants } from "../../utils/globalconstant";
import { Tooltip } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import { countriesList } from "../../utils/Files/data";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const UploadedFile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log("location***", location);

  useEffect(() => {
    setAmazonLink(location.state?.amazonLink);
    setCid(location.state?.cid);
    setFileName(location.state?.filename);
    setFiletype(location.state?.filetype);
    setFilesize(location.state?.filesize);
    setDesc(location.state?.description);
    setTag(location.state?.filetags);
    setKeyword(location.state?.keywords);
    setOrg(location.state?.organization);
    setFName(location.state?.firstname);
    setLName(location.state?.lastname);
    setDateOfBirth(location.state?.dob);
    setAge(location.state?.age);
    setGender(location.state?.gender);
    setEmail(location.state?.email);
    setPhone(location.state?.phoneno);
    setenterId1(location.state?.h_id_1_value);
    setenterId2(location.state?.h_id_2_value);
    setenterId3(location.state?.h_id_3_value);
    setSelectedCard1(location.state?.h_id_1_name);
    setSelectedCard2(location.state?.h_id_2_name);
    setSelectedCard3(location.state?.h_id_3_name);
    setCountry(location.state?.country);
    setTitle(location.state?.title);
    setShowMedData(location.state?.showMedData);
    setIsAws(location.state?.isAmazon);
    setIsIPFS(location.state?.isIPFS);
    setBucketACL(location.state?.bucketACLOption);
    setAmazonKey(location.state?.amazonKey);
  });

  const [amazonLink, setAmazonLink] = useState("");
  const [cid, setCid] = useState("");
  const [fileName, setFileName] = useState("");
  const [filesize, setFilesize] = useState("");
  const [filetype, setFiletype] = useState("");
  const [desc, setDesc] = useState("");
  const [tag, setTag] = useState("");
  const [keyword, setKeyword] = useState("");
  const [org, setOrg] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [showMedData, setShowMedData] = useState(false);
  const [isAWS, setIsAws] = useState(false);
  const [isIPFS, setIsIPFS] = useState(false);
  const [selectedTab, setselectedTab] = useState(0);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [openTooltipcid, setOpenTooltipcid] = useState(false);
  const [showTooltipId, setShowTooltipId] = useState("");
  const [enterId1, setenterId1] = useState("");
  const [enterId2, setenterId2] = useState("");
  const [enterId3, setenterId3] = useState("");
  const [selectedCard1, setSelectedCard1] = useState("");
  const [selectedCard2, setSelectedCard2] = useState("");
  const [selectedCard3, setSelectedCard3] = useState("");
  const [country, setCountry] = useState("United States");
  const [CountryCode, setCountryCode] = useState("US");
  const [bucketACL, setBucketACL] = useState("");
  const [amazonKey, setAmazonKey] = useState("");
  // ---- language starts -----//
  const [text_amazons3link1, setText_amazons3link1] =
    useState("Amazon S3 Link");
  const [text_atomfileid, setText_atomfileid] = useState("ATOM FILE ID");
  const [text_filename1, setText_filename1] = useState("File Name");
  const [text_filetype1, setText_filetype1] = useState("File Type");
  const [text_filesize1, setText_filesize1] = useState("File Size");
  const [text_description1, setText_description1] = useState("Description");
  const [text_filetag, setText_filetag] = useState("File Tag(s)");
  const [text_keywords, setText_keywords] = useState("Keyword(s)");
  const [text_org1, setText_org1] = useState("Organization");
  const [text_orgname, setText_orgname] = useState("Organization Name");
  const [text_personalhealthdata, setText_personalhealthdata] = useState(
    "Personal Health Data"
  );
  const [text_firstname1, setText_firstname1] = useState("First Name");
  const [text_lastname1, setText_lastname1] = useState("Last Name");
  const [text_dob1, setText_dob1] = useState("Date Of Birth");
  const [text_age1, setText_age1] = useState("Age");
  const [text_gender1, setTextgender1] = useState("Gender");
  const [text_email1, setText_email1] = useState("Email");
  const [text_phoneno1, setText_phoneno1] = useState("Phone Number");
  const [text_continue, setText_continue] = useState("Continue");
  const [text_patient, setText_patient] = useState("Patient");
  const [text_country, setText_country] = useState("Country");
  const [text_id, setText_id] = useState("ID");
  const [text_amazons3Key1, setText_amazons3Key1] = useState("Amazon S3 Key");
  const [text_atomstore, setText_atomstore] = useState(
    "Atom Storage Network CID"
  );
  useEffect(() => {
    if (isAWS === true) {
      setselectedTab(0);
    }
    if (isIPFS === true) {
      setselectedTab(1);
    }
    if (isAWS === true && isIPFS === true) {
      setselectedTab(2);
    }
  });
  const setCountyData = (d) => {
    var countryName = countriesList[d];
    setCountry(countryName);
    setCountryCode(d);
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_amazons3link1(
        xpath.select1("/resources/string[@name='text_amazons3link1']", doc)
          .firstChild.data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_filetype1(
        xpath.select1("/resources/string[@name='text_filetype1']", doc)
          .firstChild.data
      );
      setText_filesize1(
        xpath.select1("/resources/string[@name='text_filesize1']", doc)
          .firstChild.data
      );
      setText_description1(
        xpath.select1("/resources/string[@name='text_description1']", doc)
          .firstChild.data
      );
      setText_filetag(
        xpath.select1("/resources/string[@name='text_filetag']", doc).firstChild
          .data
      );
      setText_keywords(
        xpath.select1("/resources/string[@name='text_keywords']", doc)
          .firstChild.data
      );
      setText_org1(
        xpath.select1("/resources/string[@name='text_org1']", doc).firstChild
          .data
      );
      setText_orgname(
        xpath.select1("/resources/string[@name='text_orgname']", doc).firstChild
          .data
      );
      setText_personalhealthdata(
        xpath.select1("/resources/string[@name='text_personalhealthdata']", doc)
          .firstChild.data
      );
      setText_firstname1(
        xpath.select1("/resources/string[@name='text_firstname1']", doc)
          .firstChild.data
      );
      setText_lastname1(
        xpath.select1("/resources/string[@name='text_lastname1']", doc)
          .firstChild.data
      );
      setText_dob1(
        xpath.select1("/resources/string[@name='text_dob1']", doc).firstChild
          .data
      );
      setText_age1(
        xpath.select1("/resources/string[@name='text_age1']", doc).firstChild
          .data
      );
      setTextgender1(
        xpath.select1("/resources/string[@name='text_gender1']", doc).firstChild
          .data
      );
      setText_email1(
        xpath.select1("/resources/string[@name='text_email1']", doc).firstChild
          .data
      );
      setText_phoneno1(
        xpath.select1("/resources/string[@name='text_phoneno1']", doc)
          .firstChild.data
      );
      setText_continue(
        xpath.select1("/resources/string[@name='text_continue']", doc)
          .firstChild.data
      );
      setText_patient(
        xpath.select1("/resources/string[@name='text_patient']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_id(
        xpath.select1("/resources/string[@name='text_id']", doc).firstChild.data
      );
      setText_amazons3Key1(
        xpath.select1("/resources/string[@name='text_amazons3Key1']", doc)
          .firstChild.data
      );
      setText_atomstore(
        xpath.select1("/resources/string[@name='text_atomstore']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  const onMouseOutamazon = () => {
    if (opentooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    }
  };
  const onMouseOutcid = () => {
    if (openTooltipcid) {
      setTimeout(() => {
        setOpenTooltipcid(false);
      }, 1000);
    }
  };
  return (
    <div className="upf-main">
      <Header />
      <div className="upf-main-container">
        <div className="upf-container">
          <div className="upf-1 login-title-text uf-title">
            {title}{" "}
            <span className="upf-1-icon">
              <img src={IconImg} alt="" className="upf-1-icon-in" />
            </span>
          </div>
          <hr className="upf-hr" />
          <div className="upf-2 mt-3">
            {isAWS && (
              <>
                {bucketACL === "public" ? (
                  <>
                    {" "}
                    <div className="uf-inputs-main upf-aws">
                      <div className="flex-column">
                        <label className="login-labels">
                          {text_amazons3link1}
                        </label>
                      </div>
                      <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100">
                        <img
                          src={awsLinkImg}
                          alt=""
                          className="login-emailphone"
                        />
                        <input
                          type="text"
                          className="input login-input upf-inp w-100"
                          placeholder={text_amazons3link1}
                          value={amazonLink}
                          disabled={true}
                        />
                        {amazonLink === "" || amazonLink === "NA" ? (
                          ""
                        ) : (
                          <ContentCopyIcon
                            className="text-black copysize copy-sty"
                            title="Copied!"
                            onClick={() => [
                              setShowTooltipId(amazonLink),
                              setOpenTooltip(true),
                              navigator.clipboard.writeText(amazonLink),
                            ]}
                            onMouseOut={onMouseOutamazon}
                          />
                        )}

                        {showTooltipId ? (
                          <>
                            {" "}
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              arrow
                              open={opentooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            ></Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="uf-inputs-main upf-aws">
                      <div className="flex-column">
                        <label className="login-labels">
                          {text_amazons3Key1}
                        </label>
                      </div>
                      <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100">
                        <img
                          src={awsLinkImg}
                          alt=""
                          className="login-emailphone"
                        />
                        <input
                          type="text"
                          className="input login-input upf-inp w-100"
                          placeholder={text_amazons3Key1}
                          value={amazonKey}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {isIPFS && (
              <div className="uf-inputs-main upf-aws">
                <div className="flex-column">
                  <label className="login-labels">{text_atomfileid}</label>
                </div>
                <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100">
                  <img src={cIDImg} alt="" className="login-emailphone" />
                  <input
                    type="text"
                    className="input login-input upf-inp  w-100"
                    placeholder={text_atomstore}
                    value={cid}
                    disabled={true}
                  />
                  {cid === "" || cid === "NA" ? (
                    ""
                  ) : (
                    <ContentCopyIcon
                      className="text-black copysize copy-sty"
                      onClick={() => [
                        navigator.clipboard.writeText(cid),
                        setOpenTooltipcid(true),
                      ]}
                      onMouseOut={onMouseOutcid}
                    />
                  )}

                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    open={openTooltipcid}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied!"
                  ></Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className="upf-3">
            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_filename1}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={FileImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_filename1}
                  value={fileName}
                  disabled={true}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_filetype1}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={FiletypeImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_filetype1}
                  value={filetype}
                  disabled={true}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_filesize1}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_filesize1}
                  value={filesize}
                  disabled={true}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_description1}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={DescImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_description1}
                  disabled={true}
                  value={desc === "" ? "NA" : desc}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_filetag}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={HashImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_filetag}
                  disabled={true}
                  value={tag === "" ? "NA" : tag}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_keywords}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={keywordImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_keywords}
                  disabled={true}
                  value={keyword === "" ? "NA" : keyword}
                />
              </div>
            </div>

            <div className="uf-inputs-main">
              <div className="flex-column">
                <label className="login-labels">{text_org1}</label>
              </div>
              <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                <img src={OrgImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  className="input login-input"
                  placeholder={text_orgname}
                  disabled={true}
                  value={org === "" ? "NA" : org}
                />
              </div>
            </div>
          </div>

          {showMedData && (
            <>
              <div className="upf-5 uf-phd-text mt-4">
                {text_personalhealthdata}
              </div>
              <div className="upf-6">
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">
                      {text_patient} {text_firstname1}
                    </label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={UserImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      className="input login-input"
                      placeholder={text_firstname1}
                      value={fName === "" ? "NA" : fName}
                      disabled
                    />
                  </div>
                </div>

                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">
                      {text_patient} {text_lastname1}
                    </label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={UserImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      className="input login-input"
                      placeholder={text_lastname1}
                      value={lName === "" ? "NA" : lName}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_country}</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <ReactFlagsSelect
                      className="input login-input login-input-2 reg-input-2"
                      selected={country}
                      // onSelect={(code) => [setCountyData(code)]}
                      searchable={true}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_id} 1</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile input-upload">
                    <input
                      className="id-input-uploaded"
                      value={selectedCard1 === undefined ? "NA" : selectedCard1}
                      disabled
                    />
                    <input
                      className="input-uploaded"
                      value={enterId1 === "" ? "NA" : enterId1}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_id} 2</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile input-upload">
                    <input
                      className="id-input-uploaded"
                      value={selectedCard2 === undefined ? "NA" : selectedCard2}
                      disabled
                    />
                    <input
                      className="input-uploaded"
                      value={enterId2 === "" ? "NA" : enterId2}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_id} 3</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile input-upload">
                    <input
                      className="id-input-uploaded"
                      value={selectedCard3 === undefined ? "NA" : selectedCard3}
                      disabled
                    />
                    <input
                      className="input-uploaded"
                      value={enterId3 === "" ? "NA" : enterId3}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_dob1}</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={DOBImg} alt="" className="login-emailphone" />
                    <DatePicker
                      selected={dateOfBirth}
                      value={dateOfBirth === null ? "NA" : dateOfBirth}
                      dateFormat="MMM dd, yyyy"
                      className="uploaded-datepicker-sty"
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_age1}</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={AgeImg} alt="" className="login-emailphone" />
                    <input
                      type="number"
                      className="input login-input"
                      placeholder={text_age1}
                      value={age}
                      min={0}
                      disabled
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_gender1}</label>
                  </div>

                  <div className="inputForm uf-inputs uf-inputs-test mt-1 select-dropdown-myfile">
                    <img src={GenderImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      className="input login-input"
                      placeholder={text_gender1}
                      disabled={true}
                      value={gender === "" ? "NA" : gender}
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_email1}</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={EmailImg} alt="" className="login-emailphone" />
                    <input
                      type="email"
                      className="input login-input"
                      placeholder={text_email1}
                      value={email === "" ? "NA" : email}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="uf-inputs-main">
                  <div className="flex-column">
                    <label className="login-labels">{text_phoneno1}</label>
                  </div>
                  <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                    <img src={PhoneImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      className="input login-input"
                      placeholder={text_phoneno1}
                      disabled={true}
                      value={phone === "" ? "NA" : "+" + phone}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <hr className="upf-hr-2" />
          <div className="upf-8 mt-1">
            <div className="btn-1">
              <button
                className="upf-btn-1"
                onClick={() =>
                  navigate("/my-files", {
                    state: { selectedTab: selectedTab },
                  })
                }
              >
                {text_continue}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadedFile;
