import React, { useState, useEffect } from "react";
import "./YourFiles.css";
import Header from "../../Components/Header/Header";
import { AppBar, Tab, Tabs } from "@mui/material";
import YourFilesIPFS from "./YourFilesIPFS";
import YourFilesBoth from "./YourFilesBoth";
import YourFilesAWS2 from "./YourFilesAWS2";
import { useLocation } from "react-router-dom";
import ShareWithMe from "./ShareWithMe";
import { GlobalConstants } from "../../utils/globalconstant";
import ShareByMe from "./ShareByMe";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const YourFiles = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState();
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  //language
  const [text_filesstoredons3, setText_filesstoredons3] = useState(
    "FILES STORED ON AMAZON S3"
  );
  const [text_filesstoredonatom, setText_filesstoredonatom] = useState(
    "FILES STORED ON ATOM STORAGE NETWORK"
  );
  const [text_filesstoredonboth, setText_filesstoredonboth] = useState(
    "FILES STORED ON BOTH AMAZON S3 AND ATOM STORAGE NETWORK"
  );
  const [text_sharedwithme, setText_sharedwithme] = useState("SHARED WITH ME");
  const [text_sharedbyme, setText_sharedbyme] = useState("Shared By Me");

  //***default selected tab *****/
  useEffect(() => {
    console.log(location.state);
    if (location.state && location.state.selectedTab !== undefined) {
      setSelectedTab(location.state.selectedTab);
    } else {
      setSelectedTab(0);
    }
  }, [location.state]);
  console.log(selectedTab);
  //Language
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_filesstoredons3(
        xpath.select1("/resources/string[@name='text_filesstoredons3']", doc)
          .firstChild.data
      );
      setText_filesstoredonatom(
        xpath.select1("/resources/string[@name='text_filesstoredonatom']", doc)
          .firstChild.data
      );
      setText_filesstoredonboth(
        xpath.select1("/resources/string[@name='text_filesstoredonboth']", doc)
          .firstChild.data
      );
      setText_sharedwithme(
        xpath.select1("/resources/string[@name='text_sharedwithme']", doc)
          .firstChild.data
      );
      setText_sharedbyme(
        xpath.select1("/resources/string[@name='text_sharedbyme']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div>
        <Header />
      </div>
      <AppBar position="static" className="yf-appbar" id="">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className="yf-tabs"
          variant="scrollable"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#009087",
            },
          }}
        >
          <Tab
            label={text_filesstoredonatom}
            className="yf-tabtext"
            sx={{ maxWidth: "80% !important" }}
          />
          <Tab
            label={text_filesstoredons3}
            className="yf-tabtext"
            sx={{ minWidth: "20%" }}
          />
          <Tab
            label={text_filesstoredonboth}
            className="yf-tabtext"
            sx={{ minWidth: "35% !important" }}
          />
          <Tab
            label={text_sharedwithme}
            className="yf-tabtext"
            sx={{ minWidth: "12% !important" }}
          />
          <Tab
            label={text_sharedbyme}
            className="yf-tabtext"
            sx={{ minWidth: "12% !important" }}
          />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <YourFilesIPFS />}
      {selectedTab === 1 && <YourFilesAWS2 />}
      {selectedTab === 2 && <YourFilesBoth />}
      {selectedTab === 3 && <ShareWithMe />}
      {selectedTab === 4 && <ShareByMe />}
    </>
  );
};

export default YourFiles;
