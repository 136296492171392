import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import { partial } from "filesize";

const SelectBucketSlice = createSlice({
  name: "Select Bucket",
  initialState: {
    totalSizeAWSPublicData: "",
    usedSizeAWSPublicData: "",
    totalSizeAWSPrivateData: "",
    usedSizeAWSPrivateData: "",
    totalSizeIPFSData: "",
    usedSizeIPFSData: "",
    awsPublicFolderCountData: 0,
    awsPublicFileCountData: 0,
    awsPrivateFolderCountData: 0,
    awsPrivateFileCountData: 0,
    ipfsFolderCountData: 0,
    ipfsFileCountData: 0,
    selectbucketLoder: false,
  },
  reducers: {
    setTotalSizeAWSPublic(state, action) {
      return { ...state, totalSizeAWSPublicData: action.payload };
    },
    setUsedSizeAWSPublic(state, action) {
      return { ...state, usedSizeAWSPublicData: action.payload };
    },
    setTotalSizeIPFS(state, action) {
      return { ...state, totalSizeIPFSData: action.payload };
    },
    setUsedSizeIPFS(state, action) {
      return { ...state, usedSizeIPFSData: action.payload };
    },
    setAWSpublicFoldersCount(state, action) {
      return { ...state, awsPublicFolderCountData: action.payload };
    },
    setAWSpublicFilesCount(state, action) {
      return { ...state, awsPublicFileCountData: action.payload };
    },
    setFolderCountIPFS(state, action) {
      return { ...state, ipfsFolderCountData: action.payload };
    },
    setFileCountIPFS(state, action) {
      return { ...state, ipfsFileCountData: action.payload };
    },
    setTotalSizeAWSPrivate(state, action) {
      return { ...state, totalSizeAWSPrivateData: action.payload };
    },
    setUsedSizeAWSPrivate(state, action) {
      return { ...state, usedSizeAWSPrivateData: action.payload };
    },
    setAWSprivateFilesCount(state, action) {
      return { ...state, awsPrivateFileCountData: action.payload };
    },
    setAWSprivateFoldersCount(state, action) {
      return { ...state, awsPrivateFolderCountData: action.payload };
    },
    SelectBucketLoader(state, action) {
      return { ...state, selectbucketLoder: action.payload };
    },
  },
});
const {
  setTotalSizeAWSPublic,
  setUsedSizeAWSPublic,
  setTotalSizeAWSPrivate,
  setUsedSizeAWSPrivate,
  setTotalSizeIPFS,
  setUsedSizeIPFS,
  setAWSpublicFoldersCount,
  setAWSpublicFilesCount,
  setFolderCountIPFS,
  setFileCountIPFS,
  setAWSprivateFilesCount,
  setAWSprivateFoldersCount,
  SelectBucketLoader,
} = SelectBucketSlice.actions;
export default SelectBucketSlice.reducer;

//*** Folder or file storage AWS and Folder Count For AWS and File Count For AWS*/
export function getAwsPublicPrivateData() {
  return async function getAwsPublicPrivateDataThunk(dispatch, getstate) {
    try {
      dispatch(SelectBucketLoader(true));
      var size = partial({ base: 2, standard: "jedec" });
      // var userID = sessionStorage.getItem("userID");
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/storage-aws-public?userId=${sessionStorage.getItem("userID")}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res.data);

          dispatch(setTotalSizeAWSPublic(size(res.data?.publicTotalSize)));
          dispatch(setUsedSizeAWSPublic(size(res.data?.publicUploadedSize)));
          dispatch(setAWSpublicFilesCount(res.data?.publicFilesCount));
          dispatch(setAWSpublicFoldersCount(res.data?.publicFoldersCount));
          dispatch(SelectBucketLoader(false));
        })
        .catch((err) => {
          dispatch(SelectBucketLoader(false));
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            // navigate("/");
          }
        })
        .then(function () {});

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/storage-aws-private?userId=${sessionStorage.getItem("userID")}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res.data);

          dispatch(setTotalSizeAWSPrivate(size(res.data?.privateTotalSize)));
          dispatch(setUsedSizeAWSPrivate(size(res.data?.privateUploadedSize)));
          dispatch(setAWSprivateFilesCount(res.data?.privateFilesCount));
          dispatch(setAWSprivateFoldersCount(res.data?.privateFoldersCount));
          dispatch(SelectBucketLoader(false));
        })
        .catch((err) => {
          dispatch(SelectBucketLoader(false));
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            // navigate("/");
          }
        })
        .then(function () {});

      //   let headerConfig = {
      //     headers: {
      //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
      //     },
      //   };

      //   var API_URL =
      //     GLOBAL_URL_DOMAIN_LIVE + `/file/storage-aws?userId=${userID}`;

      //   axios
      //     .get(API_URL, headerConfig)
      //     .then((res) => {
      //       console.log("read file res***", res.data);
      //       var FinalData = res?.data;
      //       var totalSize = size(FinalData?.aws_overall_total);
      //       var usedSize = size(FinalData?.aws_overall_total_used);
      //       dispatch(setTotalSizeaws(totalSize));
      //       dispatch(setUsedSizeaws(usedSize));
      //       console.log(size(FinalData?.aws_overall_total));
      //     })
      //     .catch((err) => {
      //       console.log("read file err***", err);
      //       if (err?.response?.status === 427) {
      //         sessionStorage.clear();
      //         localStorage.clear();
      //         Cookie.remove("fName");
      //         Cookie.remove("lName");
      //         Cookie.remove("userID");
      //         Cookie.remove("userToken");
      //         Cookie.remove("token_start_time");
      //         window.location.replace("/");
      //       }
      //     })
      //     .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
//*** Folder or file storage IPFS*/
export function getStorageIPFS() {
  return async function getStorageIPFSThunk(dispatch, getstate) {
    try {
      var size = partial({ base: 2, standard: "jedec" });
      // var userID = sessionStorage.getItem("userID");
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/storage-ipfs?userId=${sessionStorage.getItem("userID")}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res.data);
          var FinalData = res?.data?.data;
          var totalSize = size(FinalData?.total_storage_size_ipfs);
          var usedSize = size(FinalData?.used_storage_size_ipfs);
          dispatch(setTotalSizeIPFS(totalSize));
          dispatch(setUsedSizeIPFS(usedSize));
          console.log(size(FinalData?.used_storage_size_ipfs));
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
//*** Folder Count For AWS*/
export function getFolderCountAWS() {
  return async function getFolderCountAWSThunk(dispatch, getstate) {
    try {
      // var userID = sessionStorage.getItem("userID");
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/user-folder-count-aws?userId=${sessionStorage.getItem(
          "userID"
        )}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res?.data?.data);
          var Val = res?.data?.data;
          // dispatch(setAWSFolderCount(Val.aws_folder_count));
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
//*** File Count For AWS*/
// export function getFileCountAWS() {
//   return async function getFileCountAWSThunk(dispatch, getstate) {
//     try {
//       var userID = sessionStorage.getItem("userID");
//       let headerConfig = {
//         headers: {
//           authorization: "Bearer " + sessionStorage.getItem("userToken"),
//         },
//       };

//       var API_URL =
//         GLOBAL_URL_DOMAIN_LIVE +
//         `/file/user-file-count-aws?userId=${userID}&isAmazon=true`;

//       axios
//         .get(API_URL, headerConfig)
//         .then((res) => {
//           console.log("read file res***", res.data.data);
//           var Val = res.data.data;
//           // dispatch(setFileCountAWS(Val.aws_file_count));
//         })
//         .catch((err) => {
//           console.log("read file err***", err);
//           if (err?.response?.status === 427) {
//             sessionStorage.clear();
//             localStorage.clear();
//             Cookie.remove("fName");
//             Cookie.remove("lName");
//             Cookie.remove("userID");
//             Cookie.remove("userToken");
//             Cookie.remove("token_start_time");
//             window.location.replace("/");
//           }
//         })
//         .then(function () {});
//     } catch (err) {
//       console.log(err);
//     }
//   };
// }
//*** Folder Count For IPFS*/
export function getFolderCountIPFS() {
  return async function getFolderCountIPFSThunk(dispatch, getstate) {
    try {
      // var userID = sessionStorage.getItem("userID");
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/user-folder-count-ipfs?userId=${sessionStorage.getItem(
          "userID"
        )}&rootFolderName=atom-storage/${sessionStorage.getItem("userID")}/`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res.data.data);
          var Val = res?.data?.data;
          dispatch(setFolderCountIPFS(Val.ipfs_folder_count));
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
//*** File Count For IPFS*/
export function getFileCountIPFS() {
  return async function getFileCountIPFSThunk(dispatch, getstate) {
    try {
      // var userID = sessionStorage.getItem("userID");

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/user-file-count-ipfs?userId=${sessionStorage.getItem(
          "userID"
        )}&isIPFS=true`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("read file res***", res.data.data);
          var Val = res?.data?.data;
          dispatch(setFileCountIPFS(Val.ipfs_file_count));
        })
        .catch((err) => {
          console.log("read file err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}

export function resetSelectBucketData() {
  return async function resetSelectBucketDataThunk(dispatch, getState) {
    try {
      dispatch(setTotalSizeAWSPublic(""));
      dispatch(setUsedSizeAWSPublic(""));
      dispatch(setTotalSizeAWSPrivate(""));
      dispatch(setUsedSizeAWSPrivate(""));
      dispatch(setTotalSizeIPFS(""));
      dispatch(setUsedSizeIPFS(""));
      dispatch(setAWSpublicFoldersCount(0));
      dispatch(setAWSpublicFilesCount(0));
      dispatch(setAWSprivateFoldersCount(0));
      dispatch(setAWSprivateFilesCount(0));
      dispatch(setFolderCountIPFS(0));
      dispatch(setFileCountIPFS(0));
    } catch (err) {
      console.log(err);
    }
  };
}
